import { Component } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdminService } from 'src/app/services/admin.service';
import { BusinessEntityService } from 'src/app/services/business-entity.service';
import { LookupService } from 'src/app/services/lookup.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-fiscal-year',
  templateUrl: './fiscal-year.component.html',
  styleUrl: './fiscal-year.component.css',
})
export class FiscalYearComponent {
  params: any;
  fiscalYearStatusMsg;
  createNewFiscalYearBtn = true;
  populateSuppFiscalYearBtn = true;
  updateLeadBuyerFiscalYearBtn = true;
  executeFiscalYearBtn = true;
  currentFiscalYear;
  newFiscalYear;
  fiscalyearCreatedby;
  fiscalyearCreatedon;
  fiscalyearStatus;
  selectedCopyIETs: FormGroup;
  inProgress = 'In-Progress';
  checkboxes = [
    { label: 'Open', value: 'Open' },
    { label: 'Closed', value: 'Closed' },
    { label: 'Pending', value: 'Pending' },
    { label: this.inProgress, value: this.inProgress },
  ];
  fiscalYear = Number(localStorage.getItem("fiscalId"));
  enableCheckStatus: boolean;
  copyIETselectedValues: string[] = [];
  appRefDocLink = "";
  currentVACostTarget:any;
  showModel = false;

  constructor(
    private readonly ngxSpinner: NgxSpinnerService,
    private readonly lookup: LookupService,
    private readonly admin: AdminService,
    private readonly bservice: BusinessEntityService,
    private readonly formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.ngxSpinner.show();
    this.showFiscalYearTab();
    setTimeout(() => {
      this.ngxSpinner.hide();
    }, 2000);
    this.selectedCopyIETs = this.formBuilder.group({
      checkboxes: this.formBuilder.array(this.checkboxes.map(() => false)),
    });
  }

  showFiscalYearTab() {
    this.ngxSpinner.show();
    //** */
    const fiscalyearBody = {
      employeeId: this.bservice.loggedUserEmployeeId,
    };
    this.lookup.getSupplierList(fiscalyearBody).subscribe((Fiscaldata) => {
      this.currentFiscalYear = Number(
        Fiscaldata['body']['fiscalYear'][0]['year']
      );
      this.newFiscalYear = this.currentFiscalYear + 1;
      this.fiscalYear = Number(
        Fiscaldata['body']['fiscalYear'][0]['fiscalyearid']
      );
    });
    this.admin.fiscalYearStatus().subscribe((data) => {
      if (data['statusCode'] === 200) {
        this.enableCheckStatus = true;
        const runningFiscalYear = data['body'][0];
        this.fiscalyearCreatedby = runningFiscalYear['triggered_by'];
        this.fiscalyearCreatedon = runningFiscalYear['triggered_datetime'];
        this.fiscalyearStatus = runningFiscalYear['status'];
        this.fiscalYearStatusMsg = `NOTE: New fiscal year created by ${runningFiscalYear['triggered_by']} on ${runningFiscalYear['triggered_datetime']}. Current Status: ${runningFiscalYear['status']}`;
        //Disable all
        this.createNewFiscalYearBtn = true;
        this.populateSuppFiscalYearBtn = true;
        this.updateLeadBuyerFiscalYearBtn = true;
        this.executeFiscalYearBtn = true;
        if (runningFiscalYear['status'] === 'Completed') {
          this.createNewFiscalYearBtn = false;
        } else if (runningFiscalYear['stage_status'] === 'Completed') {
          if (runningFiscalYear['next_stage'] === 'POPULATE') {
            this.populateSuppFiscalYearBtn = false;
          } else if (runningFiscalYear['next_stage'] === 'UPDATELEADBUYER') {
            this.updateLeadBuyerFiscalYearBtn = false;
          } else if (runningFiscalYear['next_stage'] === 'EXECUTE') {
            this.executeFiscalYearBtn = false;
          }
        }
        this.showFiscalYearStatusModel();
      } else if (data['statusCode'] === 204) {
        this.enableCheckStatus = false;
        this.createNewFiscalYearBtn = false;
      }
      setTimeout(() => {
        this.ngxSpinner.hide();
      }, 2000);
    });
    this.getAppReferenceDoc();
  }

  /** */
  changeFiscalYear() {
    this.ngxSpinner.show();
    const fiscalYearParams = {
      currentFiscalYear: this.currentFiscalYear,
      employeeName: this.bservice.loggedUserName,
    };

    this.admin.changeFiscalYear(fiscalYearParams).subscribe((_data) => {
      /** Set fiscal year after year change */
      this.getFiscalyr();
      this.showFiscalYearTab();
      this.ngxSpinner.hide();
    });
  }

  async getFiscalyr() {
    this.lookup.getFiscalyr().subscribe((data) => {
      localStorage.setItem('fY', data.body.response[0].year.toString());
      localStorage.setItem('fiscalId', data.body.response[0].fiscalyearid);
    });
  }

  /** */
  async fiscalYearCopyData(stage) {
    this.ngxSpinner.show();
    let SPParam = null;
    if (stage === 'EXECUTE') {
      SPParam = ['Open', 'Closed', 'Pending', this.inProgress];
    }
    const copyDataFiscalYear = {
      stage: stage,
      currentFiscalYear: this.currentFiscalYear,
      newFiscalYear: this.newFiscalYear,
      params: SPParam,
    };

    try {
      const data = await lastValueFrom(this.admin.updateFiscalYear(copyDataFiscalYear))
      if((data['statusCode']=== 200 || data['statusCode'] === 201) && !data?.body?.errorResponse) {
        this.showFiscalYearTab();
      } else {
        this.ngxSpinner.hide()
      }
    } catch {
      this.ngxSpinner.hide()
    } finally {
      this.ngxSpinner.hide()
    }
  }

  getAppReferenceDoc() {
    this.lookup.getAppReferenceDoc().subscribe((_data) => {
      const responseStatus = _data['body']['success'];
      const docLink = _data['body']['rows'][0];
      if (responseStatus === true) {
        this.appRefDocLink = docLink['appreference_document'];
        this.currentVACostTarget = docLink['va_cost_reduction_target'];
      }
    });
  }

  closeFiscalYearStatusModel(): void {
    this.showModel = false;
  }

  showFiscalYearStatusModel(): void {
    this.showModel = true;
  }

  updateAppRefLink(value) {
    this.ngxSpinner.show();
    try {
      this.lookup
        .updateAppReferenceDoc(this.fiscalYear, value)
        .subscribe((_data) => {
          this.getAppReferenceDoc();
          this.ngxSpinner.hide();
        });
    } catch (error) {
      this.ngxSpinner.hide();
    }
  }

  updateVACostTarget(value) {
    if (value !== '') {
      this.ngxSpinner.show();
      try {
        const updateVACost = {
          targetValue: parseFloat(value),
          fiscalYearId: localStorage.getItem('fiscalId'),
        };
        this.admin.updateVACostTarget(updateVACost).subscribe((_data) => {
          this.ngxSpinner.hide();
        });
      } catch (error) {
        this.ngxSpinner.hide();
      }
    }
  }
}
