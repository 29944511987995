<div>
  <app-navigation-bar *ngIf="!showSupplierAck"></app-navigation-bar>
  <app-message *ngIf="!showSupplierAck"></app-message>
  <ngx-spinner  id="spinnerUpload" bdColor="rgba(51, 51, 51, 0.3)" size="medium" color="#fff" type="ball-scale-multiple" ></ngx-spinner>

<router-outlet>
    
</router-outlet>

<app-footer *ngIf="!showSupplierAck"></app-footer>
</div>