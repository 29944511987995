import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AgGridSortingService {

  constructor() { }

  //case-insensitive string sorting
  stringComparator(valueA: string, valueB: string): number {
    if(!valueA) { return -1 };
    if(!valueB) { return 1 };
    const cleanA = valueA.trim()
    const cleanB = valueB.trim()
    return cleanA.toLowerCase().localeCompare(cleanB.toLowerCase())
  }

  //mixedvalue sorting
  mixedValueComparator(valueA: string, valueB: string): number {
    if(!valueA) { return -1 }
    if(!valueB) { return 1 }

    const numA = parseFloat(valueA.replace('%', ''))
    const numB = parseFloat(valueB.replace('%', ''))
    const isNumA = !isNaN(numA)
    const isNumB = !isNaN(numB)

    if(isNumA &&isNumB) {
      return numA - numB
    }

    if(isNumA) { return -1 }
    if(isNumB) { return 1 }

    return valueA.localeCompare(valueB)
  }
}
