<div class="alert-failure" *ngIf="showReportingSummaryErrMsg">
    <img style="margin-right: 1em;" src="../assets/images/errCircle.png" alt="failureMsg">
    
    <span>{{supplierReportingErrorMessage}}</span>
</div>
<div class="alert-failure" *ngIf="showIETSummaryErrMsg">
    <img style="margin-right: 1em;" src="../assets/images/errCircle.png" alt="failureMsg">
    
    <span>{{supplierIETSummaryErrorMessage}}</span>
</div>
<div class="container-fluid">
    <div class="col-md-12 m-3">
     <mat-tab-group mat-stretch-tabs animationDuration="0ms" (selectedIndex)="selectedTabIndex"
     (selectedTabChange)="onTabChanged($event)">
         <mat-tab label="Supplier Reporting Summary">
             <div class="row">
                 <div class="d-flex justify-content-between">
                     <div class="mt-2"><span class="commonFont">This tab is to export a list of Supplier Status.</span>
                     </div>
                     <div class="mt-2 ">
                         <button class="closeButton me-4" style="width: fit-content;" (click)="exportFile('grid1')" [ngClass]="{'disableBtn': disableExportReportingSummary}">
                             <img src="\assets\images\report.png" alt="Export Supplier List" height="16" width="16">
                             EXPORT SUPPLIER LIST
                         </button>
                     </div>
                 </div>
                 <div class="">
                     <div class="d-flex">
                         <input type="checkbox" [(ngModel)]="showPrevYearsHistory" (change)="toggleColumnVisibility()"
                         style="margin-right: 10px;box-shadow: none;"> <span class="label-tmna">Show history for previous years</span>
                     </div>
                 </div>
             </div>
             <div class="row mt-2" style="margin-bottom: 50px">
                <div class="flex-row mt-2" id="dataForSupplierReportingSummary">
                    <ag-grid-angular #grid1 class="ag-theme-balham me-4"[rowData]="rowDataforSupplierReportingSummary" [columnDefs]="columnDefSupplierReportingSummary"
                        (gridReady)="onGridReady($event, 'grid1')" [defaultColDef]="defaultColDef" [groupDisplayType]="groupDisplayType"
                        [domLayout]="domLayout" [rowGroupPanelShow]="'always'"
                        [floatingFiltersHeight]="50" [icons]="icons" [suppressDragLeaveHidesColumns]="true" [animateRows]="true">
                    </ag-grid-angular>
                </div>
             </div>
         </mat-tab>
         <mat-tab label="Supplier IET Summary">
             <div class="row">
                 <div class="d-flex justify-content-between">
                     <div class="mt-2"><span class="commonFont">This tab is to export a list of Suppliers and APR Cost Reduction Targets.</span>
                     </div>
                     <div class="mt-2">
                         <button class="closeButton me-4" style="width: fit-content;" (click)="exportFile('grid2')" [ngClass]="{'disableBtn': disableExportIETSummary}">
                             <img src="\assets\images\report.png" alt="Export Supplier List" height="16" width="16">
                             EXPORT SUPPLIER LIST
                         </button>
                     </div>
                 </div>
             </div>
             <div class="row mt-2" style="margin-bottom: 50px">
                 <div class="flex-row mt-2">
                     <ag-grid-angular #grid2 class="ag-theme-balham me-4" [rowData]="rowDataforSupplierIETSummary"  [columnDefs]="columnDefSupplierIETSummary"
                         (gridReady)="onGridReady($event, 'grid2')" headerHeight="50" [defaultColDef]="defaultColDef"
                         [domLayout]="domLayout" [rowGroupPanelShow]="'always'" [groupDisplayType]="groupDisplayType"
                         [floatingFiltersHeight]="50" [icons]="icons" [tooltipShowDelay]="tooltipShowDelay" [suppressDragLeaveHidesColumns]="true" [animateRows]="true"
                         (cellEditingStopped)="onCellEditingStopped($event)">
                     </ag-grid-angular>
                 </div>
             </div>
         </mat-tab>
     </mat-tab-group>
    </div>
 </div>
 
 <div class="modal" tabindex="-1" *ngIf="showModel" [ngStyle]="{'display': showModel ? 'block':'none'}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <button type="button" class="close" data-bs-dismiss="modal" (click)="closeApprovedIETModel()"> 
                <img src="\assets\images\close-icon.png" alt="Close Modal" height="15" width="15" >
            </button>
      
            <div class="modal-header" id="addModalHeader"
                style=" display: flex; justify-content: space-between;align-items:end  ">
                
                <h4 class="modal-title inl-modal-padding modalHeading">{{selectedIETName}}
                </h4>
    
            </div>
            <div class="modal-body">
                <div class="bulletlist">
                    <span class="ietTitle">{{supplierIETHeading}}</span>
                    <br /><span class="">{{parseHTMLContent(supplierIETBody)}}</span>
                    <ul>
                        <li *ngFor="let item of bullets;">
                            <span class="">{{parseHTMLContent(item.bullet)}}</span>
                            <ul *ngIf="item.subbullets && item.subbullets.length">
                                <li *ngFor="let subbullet of item.subbullets">
                                    <span class="">{{parseHTMLContent(subbullet)}}</span>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>