import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './admin/admin.component';
import { HomeComponent } from './home/home.component';
import { PacketSetComponent } from './packet-set/packet-set.component';
import { ReportsComponent } from './reports/reports.component';
import { IseComponent } from './ise/ise.component';
import { IetComponent } from './ise/iet/iet.component';
import { SupplierDetailsComponent } from './supplier-details/supplier-details.component';
import { MsalGuard } from 'src/msal';
import { GuardService } from './guard-service';
import { SupplierAcknowledgementComponent } from './supplier-acknowledgement/supplier-acknowledgement.component';
import { NewietComponent } from './ise/newiet/newiet.component';
import { ReorderComponent } from './ise/reorder/reorder.component';

const routes: Routes = [
  {path: '', redirectTo: '/home', pathMatch: 'full'},
  {path:'admin',component: AdminComponent,canActivate: [MsalGuard]},
  {path:'home',component: HomeComponent,canActivate: [MsalGuard]},
  {path:'packetSent',component: PacketSetComponent,canActivate: [MsalGuard]},
  {path:'reports',component: ReportsComponent,canActivate: [MsalGuard]},
  {path:'ise',component:IseComponent,canActivate: [MsalGuard]},
  {path:'supplierDetails', component: SupplierDetailsComponent,canActivate: [MsalGuard]},
  {path:'iet', component: IetComponent,canActivate: [MsalGuard]},
  {path:'newiet',component: NewietComponent,canActivate: [MsalGuard]},
  {path:'reorderiet',component: ReorderComponent,canActivate: [MsalGuard]},
  {path:'supplierAcknowledgement',component: SupplierAcknowledgementComponent},
  // wild card routing must be always at the end
  { path: '**', redirectTo: '/dashboard',pathMatch: 'full' }
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [GuardService]
})
export class AppRoutingModule { }
