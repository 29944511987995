import { Component, HostListener, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { NgxSpinnerService } from 'ngx-spinner';
import { AgGridSortingService } from 'src/app/services/ag-grid-sorting.service';
import { GridApi, GridOptions, RowGroupingDisplayType } from 'ag-grid-community';
import { LookupService } from 'src/app/services/lookup.service';
import { ImprovementTargetEditComponent } from './improvement-target-edit/improvement-target-edit.component';
import { ImprovementTargetTitleComponent } from './improvement-target-title/improvement-target-title.component';
import { ImprovementTargetDescriptionComponent } from './improvement-target-description/improvement-target-description.component';
import { ImprovementTargetOrderComponent } from './improvement-target-order/improvement-target-order.component';
import { ImprovementTargetInactiveComponent } from './improvement-target-inactive/improvement-target-inactive.component';
import { DropdownFloatingFilterComponent } from 'src/app/core/dropdown-floating-filter/dropdown-floating-filter.component';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-supplier-category',
  templateUrl: './supplier-category.component.html',
  styleUrl: './supplier-category.component.css'
})
export class SupplierCategoryComponent {
  @ViewChild('agGrid') agGrid: AgGridAngular;
  postITbody:any;
  putITbody:any; 
  successMssg:any;
  successMessageAlert:any;
  failureMssg:any;
  failureMessageAlert:any;
  columnDefForImprovementTarget = [];
  rowDataForImprovementTarget = []
  defaultColDef={ 
      suppressNavigable: true,
      suppressMovable: false,
      cellClass: 'no-border',
      enableRowGroup: true,
      unSortIcon: true,
      floatingFilter: true,
      filter: 'agTextColumnFilter',
      wrapText: true,
      suppressHeaderMenuButton: true,
      minWidth: 140,
      suppressFloatingFilterButton: true,
      autoHeight: true,
      sortable: true
  };
  icons = {
  menu: ' ',
  filter: ' '
  }
  groupDisplayType: RowGroupingDisplayType = "groupRows";
  paginationPageSize=4;
  paginationPageSizeSelector = [4, 10, 20, 50, 100];
  domLayout='autoHeight';
  gridApi: GridApi;
  gridColumnApi: any;
  gridOptions: GridOptions = {
    onGridReady: (params) => this.onGridReady(params),
    onColumnResized: () => this.autoSizeAllColumns(),
    onColumnMoved: () => this.autoSizeAllColumns()
  };

  constructor(private readonly ngxSpinner:NgxSpinnerService, private readonly lookup:LookupService, 
    private readonly sortService:AgGridSortingService
    ){
      this.columnDefForImprovementTarget=[{
        headerName: '#',
        field: '',
        cellRenderer: ImprovementTargetEditComponent,
        cellRendererParams: {
          update: this.updateIT.bind(this),
          cancel: this.cancelIT.bind(this),
        },
        flex: 1,
        filter: false,
        enableRowGroup: false,
        lockPosition: true,
        sortable: false
        },
        {
          headerName: 'Title',
          field: 'title',
          cellRenderer:ImprovementTargetTitleComponent,
          flex: 1,
          comparator: this.sortService.stringComparator
        },
        {
          headerName: 'Target Description',
          field: 'targetdescription',
          cellRenderer:ImprovementTargetDescriptionComponent,
          minWidth: 400,
          flex: 4,
          comparator: this.sortService.stringComparator
        },
        {
          headerName: 'Display Order',
          field: 'displayorder',
          cellRenderer:ImprovementTargetOrderComponent,
          flex: 1,
          comparator: false
        },
        {
          headerName: 'Inactive?',
          field: 'inactive',
          cellRenderer:ImprovementTargetInactiveComponent,
          flex: 1,
          floatingFilterComponent: DropdownFloatingFilterComponent,
          floatingFilter: true,
          floatingFilterComponentParams: {
            associatedList: ['All','Yes','No']     
          },
          valueGetter: (params) => {
            return params.data?.inactive === true ? 'Yes' : 'No';
          },
          comparator: false
        }
      ];
    }


  ngOnInit() {
    this.ngxSpinner.show()
    this.showImprovement()
  }

  async showImprovement(){
    this.ngxSpinner.show()
    try {
      const data = await lastValueFrom(this.lookup.getImprovementTarget())
      if(data['statusCode'] === 200 && !data?.body?.errorResponse) {
        this.rowDataForImprovementTarget=data.body.response;
      }
    } catch {
      this.ngxSpinner.hide()
    } finally {
      this.ngxSpinner.hide()
    }
   }

  addIT(){
    this.clearITFilter();
    const newRow=  this.agGrid?.api.applyTransaction({
      add: [{editable:true, title: '', targetdescription: '', displayorder: '', inactive: false }]
    });
    const rowdata=[]
    rowdata[0]=newRow;
    for(let i=1;i<=this.rowDataForImprovementTarget.length;i++){
      rowdata[i]=this.rowDataForImprovementTarget[i-1];
    }
    this.rowDataForImprovementTarget=rowdata;
    this.rowDataForImprovementTarget[0].editable=true;
    this.agGrid?.api.paginationGoToPage(0);
   }

   updateIT(params){  //NOSONAR
    if(params.data.title!==null && params.data.title!==undefined && params.data.title!=="" &&
      params.data.targetdescription!==null && params.data.targetdescription!==undefined  && params.data.targetdescription!=="" &&
      params.data.displayorder!==null && params.data.displayorder!==undefined && params.data.displayorder!=="" ){
    if(params.data?.apptemavalueimprovementtargetid){
      const assumedUserPresent=JSON.parse(localStorage.getItem('userAssumed'));
      const employeeid=(assumedUserPresent!=="undefined"&&assumedUserPresent!==null)?assumedUserPresent.employeeids:sessionStorage.getItem("loginAssumeEmployeeid");
      this.putITbody={
        "title": params.data?.title,
        "targetdescription": params.data?.targetdescription,
        "displayorder": params.data?.displayorder,
        "Inactive": params.data?.inactive,
        "employeeId": employeeid,
        "improvementTargetId": params.data?.apptemavalueimprovementtargetid
      }
      this.lookup.updateImprovementTarget(this.putITbody).subscribe(_data=>{
        this.showImprovement();
      })
    }
    else{
      let inactive=false;
      if(params.data?.inactive===true){
        inactive=true;
      }
      const assumedUserPresent=JSON.parse(localStorage.getItem('userAssumed'));
      const employeeid=(assumedUserPresent!=="undefined"&&assumedUserPresent!==null)?assumedUserPresent.employeeids:sessionStorage.getItem("loginAssumeEmployeeid");
      
      this.postITbody={
        "target": params.data?.title,
        "targetdescription": params.data?.targetdescription,
        "displayorder": params.data?.displayorder,
        "inactive": inactive,
        "createdbyemployeeid": employeeid
      }
      this.lookup.addNewImprovementTarget(this.postITbody).subscribe(_data=>{
        this.showImprovement();
      })
    }}
    else{
      this.failureMssg="Title , Description and Order are required"
      this.failureMessageAlert=true;
      setTimeout(() => {
        this.failureMessageAlert=false;
      }, 3000);
    }
   }

  cancelIT(_params){
  this.showImprovement();
  }

  clearITFilter() {
    this.agGrid?.api.setFilterModel(null);
    this.agGrid?.api.getColumns().forEach((col) => {
      this.agGrid?.api.applyColumnState({
        state: [{ colId: col.getId(), sort: null }]
      });
    });
    this.agGrid?.api.setRowGroupColumns(null);
  }

  tabToNextCell(){ //tab to next cell in ag-grid table
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.autoSizeAllColumns();
  }

  @HostListener('window:resize', ['$event'])
  onResize(_event: any) {
    if (this.gridApi) {
      this.debounce(() => {
        this.autoSizeAllColumns();
      }, 100);
    }
  }

  autoSizeAllColumns() {
    const allColumnIds: string[] = [];
    this.gridColumnApi.getAllColumns().forEach((column: any) => {
      allColumnIds.push(column.getColId());
    });
    this.gridColumnApi.autoSizeColumns(allColumnIds);
  }

  debounce(func: Function, wait: number) {
    let timeout: any;
    return (...args: any[]) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }
}
