import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { GridApi } from 'ag-grid-community';
import { LookupService } from '../services/lookup.service';
import { SupplierCodeRendererComponent } from './supplier-code-renderer/supplier-code-renderer.component';
import { ManagementService } from '../services/management.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BusinessEntityService } from '../services/business-entity.service';
import { lastValueFrom } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ErrorMessagesService } from '../services/error-messages.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.css',
})
export class HomeComponent implements OnInit {
  @ViewChild('listBox') listBox !: ElementRef<HTMLDivElement>;
  currentYear = new Date().getFullYear();
  appRefDocLink = "";
  isOpen1 = true;
  isOpen2 = true;
  isOpen3 = true;
  isOpen4 = true;
  gridApi: GridApi;
  gridColumnApi: any;
  paginationPageSize = 6;
  paginationPageSizeSelector = [6, 10, 20, 50, 100];
  domLayout = 'autoHeight';
  totalPacket = 0;
  approvedpacket = 0;
  packetNotSent = 0;
  openPacket = 0;
  approvedpacketPercentage = 0;
  packetNotSentPercentage = 0;
  openPacketPercentage = 0;
  isStyled = false;
  tab1 = true;
  tab2 = false;
  tab3 = false;
  tab4 = false;
  tab5 = false;
  tab6 = false;
  supplierListReqestBody: { employeeId: number }; //Setting the default parameter until authentication is done
  updateImprvCostTrgtRequestBody: {
    appParentId: number;
    fiscalId: number;
    targetId: number;
    imprvTrgt: string;
    aprCost: string;
    empId: number;
  };
  approvalStatusRequestBody: {
    appParentId: number;
    fiscalId: number;
    saveFlag: boolean;
    readyForReviewFlag: boolean;
    backToBuyerFlag: boolean;
    comment: string;
    empId: number;
  }
  supplierList = [];
  supplierListResponse: any;
  selectedIndex: number | null;
  selectedFilterIndex: number | null;
  appParentId: any;
  fiscalyear: number;
  fiscalyearid: number;
  currentFiscalYear: number;
  previousFiscalYear: number;
  empId: number;
  selectedSupplierBuyerInfo: any;
  selectedAppParentId: any;
  combinedData = [];
  commodityCodeDetails: any;
  commodityDetails: any;
  prevMBEachievement = '';
  currentMBEachievement = '';
  prevWBEachievement = '';
  currentWBEachievement = '';
  improvementTargetData = [];
  supplierTier2Data = [];
  currentAPRtarget = '';
  currentAPRtargetValue = 0;
  prevAPRtarget = '';
  minoritytarget = '';
  wbetarget = '';
  selectedImprTargetTitle = '';
  selectedImprTargetDesc = '';
  searchSupplier = '';
  totalPacketPercentage = 0;
  selectedTargetId: any;
  suppInfo: any;
  dateApproved = '';
  doNotSendRequestBody: {
    appParentId: number,
    fiscalId: number,
    empId: number,
    isApproved: boolean
    doNotSendFlag: boolean
  };
  approveDisapproveRequestBody: {
    appParentId: number,
    fiscalId: number,
    empId: number,
    isApproved: boolean
    doNotSendFlag: boolean
  };
  approved: any;
  donotsend: any;
  supplierListFlag = false;
  fallLabelYear: any;
  springLabelYear: any;
  tmnaYearLabel: any;
  isDisplay: boolean;
  loginUserName: any;
  loginUserRole: any;
  validation: any;
  displayUnauthorised:boolean;
  gridApiMap = new Map<string, any>();
  isManager = false;
  isAdmin = false;
  isBuyer = false;
  rowDataSafetyRisk = [];

  defaultColDef = {
    suppressNavigable: true,
    cellClass: 'no-border',
    suppressMovable: true,
    unSortIcon: true,
    resizable: true,
    minWidth: 60,
    autoHeight: true,
    wrapText: true,
    suppressHeaderMenuButton: true,
    suppressFloatingFilterButton: true,
    autoHeaderHeight: true,
    wrapHeaderText: true,
    sortable: true
  };

  columnDefsSafetyRisk = [
    {
      headerName: 'Supplier Code',
      field: 'suppliercode',
      flex: 1,
    },
    {
      headerName: 'Target',
      field: 'target',
      flex: 1,
    },
    {
      headerName: 'Risk Rating',
      field: 'risk_rating',
      flex: 1,
    },
  ];

  rowDataCommodityDetails = [];

  columDefCommodityDetails = [
    {
      headerName: 'Supplier Code',
      field: 'suppliercode',
      cellRenderer: SupplierCodeRendererComponent,
      flex: 1,
      minWidth: 80,
    },
    {
      headerName: 'Commodity',
      field: 'description',
      flex: 4,
      minWidth: 220,
    },
    {
      headerName: 'Quality',
      children: [
        {
          headerName: 'OE',
          field: 'oedefecttarget',
          flex: 1,
        },
        {
          headerName: 'Service',
          field: 'servicedefecttarget',
          flex: 1,
        },
        {
          headerName: 'Prototype',
          field: 'prototypedefecttarget',
          flex: 1,
          minWidth:90
        },
        {
          headerName: 'S-Rank QPR',
          field: 'srankqpr',
          flex: 1,
        },
      ],
    },
    {
      headerName: 'Delivery KPM',
      field: 'deliverykpm',
      flex: 1,
    },
    {
      headerName: 'Warranty PPM',
      field: 'warrantytarget',
      flex: 1,
    },
  ];

  employeeId: any;
  disableISEReportsLink = false;
  disableButtons = false;
  selectedSupplierIndex: number;
  selectedTabValue: number;
  inprogressPacket = 0;
  readyforreviewPacket = 0;
  inprogressPacketPercentage = 0;
  readyforreviewPacketPercentage = 0;
  resultPercentageData = [];
  showModal = false;
  prevAssumedEmployeeId: any;
  newAssumedEmployeeId: any;
  commentsFromManager = '';
  inProgress: any;
  readyForReview: any;
  cancelAssumedUser=false;
  isSupplierInOpenStatus: boolean;
  fiscalyearlabel: number;
  totalSupplierListResponse: any;
  approvedSupplierListResponse: any;
  noSendSupplierListResponse: any;
  openSupplierListResponse: any;
  inProgressSupplierListResponse: any;
  readyForReviewSupplierListResponse: any;
  showNoSupplierErrMsg: boolean;
  pageErrorMsg: any;

  constructor(
    private readonly lookup: LookupService,
    private readonly management: ManagementService,
    private readonly ngxSpinner: NgxSpinnerService,
    private readonly bService:BusinessEntityService,
    private readonly cdr: ChangeDetectorRef,
    private readonly router: Router,
    private readonly errorService: ErrorMessagesService
  ) {}
  
  ngOnInit() {
    this.validation=this.lookup.localStorageData.pipe(distinctUntilChanged()).subscribe(data=>{
      if(data?.includes('success')){
        this.isDisplay=true;
        this.loginUserName = this.bService.loggedUserName;
        this.loginUserRole = this.bService.loggedUserRole;
        const localStorageAssumeUser= JSON.parse(localStorage.getItem('userAssumed'));
        if(localStorageAssumeUser){
          this.assumeRoleByLocalStorage(localStorageAssumeUser)
        }else{
          this.assumeRoleByBService()
        }
        this.employeeId = this.bService.loggedUserEmployeeId;
        this.newAssumedEmployeeId = this.employeeId;
        this.navigateToCorrectPage(localStorageAssumeUser)  //Navigating to correct screen while assuming identity
        //Checking if Assume Identity changed to different employeeId
        this.setSelectedTabAndSupplier()
        //Selecting the first supplier on load of Home screen or selecting previously selected supplier, when loading home screen from other page
        this.setSelectedSupplierIndex()
        this.getTotalSupplierDetails()  //Get Total response of supplier details
        this.getAppReferenceDoc();  //APP Reference Document Link
        this.disableLinkButton() //Disable link and button if Supplier List is empty
      }
      else if(data?.includes('failure')) {
        this.isDisplay=false;
        this.displayUnauthorised=true;
        const localStorageAssumeUser = localStorage.getItem('userAssumedData');
        if (localStorageAssumeUser==='noData') {
          this.cancelAssumedUser = true;
        }
        this.ngxSpinner.hide();
      }
    })
    this.fiscalyearid = Number(localStorage.getItem("fiscalId"));
  }

  navigateToCorrectPage(localStorageAssumeUser) {
    if(localStorageAssumeUser){
      const redirectPath = localStorage.getItem("assumedPath");
      const redirectFlag = localStorage.getItem("redirectedAssume");
      if(redirectFlag === "false" && redirectPath !== "/home"){
        localStorage.setItem("redirectedAssume", "true");
        if(this.isAdmin || (!this.isAdmin && redirectPath !== "/admin")){
          this.router.navigate([redirectPath])
        }
      }
    }
  }

  setSelectedTabAndSupplier() {
    if(localStorage.getItem('assumeClicked')==="true" || localStorage.getItem("fiscalId") === null) {
      sessionStorage.setItem("indexBeforeLeaving", '0');
      sessionStorage.setItem("selectedTab", '1');
      localStorage.setItem("assumeClicked", "false");  //Making the flag false, as default supplier should be selected on Assume or cancel assume
    }
  }

  setSelectedSupplierIndex() {
    this.selectedSupplierIndex = Number(sessionStorage.getItem("indexBeforeLeaving"));
    this.selectedTabValue = Number(sessionStorage.getItem("selectedTab"));
    if(this.selectedSupplierIndex !== 0) {
      this.selectedFilterIndex = this.selectedIndex = this.selectedSupplierIndex;
    } else {
      this.selectedFilterIndex = this.selectedIndex = 0;
    }
  }

  async getTotalSupplierDetails() {
    this.ngxSpinner.show()
    this.searchSupplier=""
    this.supplierListReqestBody = {employeeId: this.employeeId};
    try {
      const data = await lastValueFrom(this.lookup.getSupplierList(this.supplierListReqestBody))      
      if(data['statusCode'] === 201 &&  !data?.body?.errorResponse) {
        //Setting supplier details
        if(data?.body?.supplierList && data?.body?.supplierList.length > 0) {
          this.totalSupplierListResponse = data;
          this.approvedSupplierListResponse = data.body.supplierList.filter(supplier=>supplier.description.includes("(Aprvd)"))
          this.noSendSupplierListResponse = data.body.supplierList.filter(supplier=>supplier.description.includes("(No Send)"))
          this.openSupplierListResponse = data.body.supplierList.filter(supplier => !supplier.description.includes("(Aprvd)") && !supplier.description.includes("(In Progress)") && !supplier.description.includes("(Ready For Review)") && !supplier.description.includes("(No Send)"));
          this.inProgressSupplierListResponse = data.body.supplierList.filter(supplier=>supplier.description.includes("(In Progress)"))
          this.readyForReviewSupplierListResponse = data.body.supplierList.filter(supplier=>supplier.description.includes("(Ready For Review)"))
        } else {
          this.showNoSupplierErrMsg = true
          this.pageErrorMsg = this.errorService.noPacketDetails
          setTimeout(()=>{
            this.showNoSupplierErrMsg = false
          }, 5000)
        }
        //setting fiscal year and label details
        if(data?.body?.fiscalYear && data?.body?.fiscalYear.length > 0) {
          this.getFiscalYearDetails(data?.body?.fiscalYear[0])
        } 
        //setting packet info details
        if(data?.body?.packetApprovalInfo && data?.body?.packetApprovalInfo.length > 0) {
          this.getPacketDetails(data?.body?.packetApprovalInfo[0]);
          this.calculatePercentage()
        }
      } else {
        this.ngxSpinner.hide()
        this.showNoSupplierErrMsg = true
        setTimeout(()=>{
          this.pageErrorMsg = this.errorService.serverErrMsg
          this.showNoSupplierErrMsg = false
        }, 5000)
      }
    } catch {
      this.ngxSpinner.hide()
      this.showNoSupplierErrMsg = true
      setTimeout(()=>{
        this.pageErrorMsg = this.errorService.serverErrMsg
        this.showNoSupplierErrMsg = false
      }, 5000)
    } finally {
      this.ngxSpinner.hide()
    }
    this.tabOnloadScreen()  //Getting the details of previously selected supplier value, when returning from other screen
  }

  tabOnloadScreen() {
    if(this.selectedTabValue === 1){
      this.getSupplierlistDetail(this.selectedIndex);
      this.tab1 = true;
      this.tab2 = this.tab3 = this.tab4 = this.tab5 = this.tab6 = false;
    } else if(this.selectedTabValue === 2){
      this.getApprovedSupplierlistDetail(this.selectedIndex);
      this.tab2 = true;
      this.tab1 = this.tab3 = this.tab4 = this.tab5 = this.tab6 = false;
    } else if(this.selectedTabValue === 3){
      this.getNotSentSupplierlistDetail(this.selectedIndex);
      this.tab3 = true;
      this.tab1 = this.tab2 = this.tab4 = this.tab5 = this.tab6 = false;
    } else if(this.selectedTabValue === 4){
      this.getOpenSupplierlistDetail(this.selectedIndex, this.selectedTabValue);
      this.tab4 = true;
      this.tab1 = this.tab2 = this.tab3 = this.tab5 = this.tab6 = false;
    } else if(this.selectedTabValue === 5){
      this.getOpenSupplierlistDetail(this.selectedIndex, this.selectedTabValue);
      this.tab5 = true;
      this.tab1 = this.tab2 = this.tab3 = this.tab4 = this.tab6 = false;
    } else if(this.selectedTabValue === 6){
      this.getOpenSupplierlistDetail(this.selectedIndex, this.selectedTabValue);
      this.tab6 = true;
      this.tab1 = this.tab2 = this.tab3 = this.tab4 = this.tab5 = false;
    } else {
      this.getSupplierlistDetail(this.selectedIndex);
    }
  }

  getFiscalYearDetails(value) {
    this.fiscalyearid = value.fiscalyearid;
    localStorage.setItem("fiscalId", this.fiscalyearid.toString())
    this.fiscalyear = value.year;
    localStorage.setItem("fY", this.fiscalyear.toString());
    this.fiscalYearLabelRender()
  }

  getPacketDetails(value) {
    this.totalPacket = value?.activepacketcount ?? 0;
    this.approvedpacket = value?.approvedpacketcount ?? 0;
    this.openPacket = value?.openpacketcount ?? 0;
    this.packetNotSent = value?.notsendingpacketcount ?? 0;
    this.inprogressPacket =value?.inprogresspacketcount ?? 0;
    this.readyforreviewPacket = value?.readyforreviewpacketcount ?? 0;
  }

  assumeRoleByLocalStorage(localStorageAssumeUser) {
    if(localStorageAssumeUser.role==='PurappsAPPAdmin'){
      this.loginUserRole='Admin';this.isAdmin=true
    }else if(localStorageAssumeUser.role==='PurappsBuyer'){
      this.loginUserRole='Buyer';this.isBuyer=true
    }else if(localStorageAssumeUser.role==='PurappsManager'){
      this.loginUserRole='Manager';this.isManager=true
    }
  }

  assumeRoleByBService() {
    if(this.bService.loggedUserRole==='PurappsAPPAdmin'){
      this.loginUserRole='Admin';this.isAdmin=true
    }else if(this.bService.loggedUserRole==='PurappsBuyer'){
      this.loginUserRole='Buyer';this.isBuyer=true
    }else if(this.bService.loggedUserRole==='PurappsManager'){
      this.loginUserRole='Manager';this.isManager=true
    }
  }

  getAppReferenceDoc() {
    this.lookup.getAppReferenceDoc().subscribe((_data)=>{

      const responseStatus = _data['body']['success']
      const docLink = _data['body']['rows'][0]
      if(responseStatus === true){
        this.appRefDocLink = docLink['appreference_document'];
      }
    })
  }

  selectedSupplierFocus() {
    if(this.listBox && this.selectedIndex !== null) {
      const listItem = this.listBox.nativeElement.querySelectorAll('.list-item');
      const selectedItem = listItem[this.selectedIndex];
      if(selectedItem) {
        selectedItem.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'nearest'
        })
      }
    }
  }

  toggleClass() {
    this.isStyled = !this.isStyled;
  }

  applyActiveClass(p, event: Event) {
    this.searchSupplier="";
    sessionStorage.setItem("selectedTab",p);  //Storing the manually selected tab
    this.selectedTabValue = p;
    if (event.type === 'keyup' || event.type === 'click') {
      if (p === 1) {
        this.tab1 = true;
        this.tab2 = this.tab3 = this.tab4 = this.tab5 = this.tab6 = false;
        this.getSupplierlistDetail(0);
      } else if (p === 2) {
        this.tab2 = true;
        this.tab1 = this.tab3 = this.tab4 = this.tab5 = this.tab6 = false;
        this.getApprovedSupplierlistDetail(0); //On change of Tab, setting the index to first element as default
      } else if (p === 3) {
        this.tab3 = true;
        this.tab1 = this.tab2 = this.tab4 = this.tab5 = this.tab6 = false;
        this.getNotSentSupplierlistDetail(0);
      } else {
        this.tab1 = this.tab2 = this.tab3 = false;
        if(p===4){
          this.tab4 = true;
          this.tab5 = this.tab6 = false;
        }else if(p===5){
          this.tab5 = true;
          this.tab4 = this.tab6 = false;
        }else if(p===6){
          this.tab6 = true;
          this.tab4 = this.tab5 = false;
        }
        this.getOpenSupplierlistDetail(0, p);
      }
    }
  }
  
  onGridReady(params:any, gridKey: string): void{
    this.gridApiMap.set(gridKey, params.api);
    params.api?.sizeColumnsToFit();
  }
  @HostListener("window:resize", ['$event'])
  onResize(): void {
    this.resizeAllGrids();
  }

  resizeAllGrids(): void {
    this.gridApiMap.forEach((gridApi) => {
      if(gridApi) {
        gridApi.sizeColumnsToFit();
        gridApi.refreshCells();
      }
    })
  }

  getSupplierlistDetail(originalIndex?: number) {
    this.ngxSpinner.show();
    if(this.totalSupplierListResponse && this.totalSupplierListResponse?.body?.supplierList?.length > 0) {
      sessionStorage.setItem("indexBeforeLeaving", originalIndex?.toString());
      this.supplierListResponse = {};
      this.supplierList.length = 0;
      this.supplierListResponse = this.totalSupplierListResponse;
      this.supplierList = this.totalSupplierListResponse.body.supplierList?.map((value) => value.description);
      this.appParentId = this.totalSupplierListResponse.body.supplierList?.map((value) => value.appparentid);
      this.disableLinkButton()  //disable link and button based on supplierList items
      this.empId = this.supplierListReqestBody.employeeId;
      if (this.checkSupplierListForIndex(originalIndex)) {  //Setting the search filter index on click of Save/ Approve/ Do not send buttons
        this.supplierListFlag = true;
        this.selectedFilterIndex = originalIndex;
      }
      if(originalIndex === 0) {
        this.selectedIndex = this.selectedFilterIndex = 0;
      }
      this.getSelectedSupplierDetails(this.selectedIndex);
    } else {
      this.supplierListResponse = {};
      this.supplierList.length = 0;
      this.getSelectedSupplierDetails(this.selectedIndex);
    }
      
  }
  
  fiscalYearLabelRender() {
    this.fiscalyearlabel = Number(localStorage.getItem("fY"));
    this.currentFiscalYear = this.fiscalyearlabel % 100;
    this.previousFiscalYear = (this.fiscalyearlabel - 1) % 100;
    this.springLabelYear = this.fiscalyearlabel;
    this.fallLabelYear = this.fiscalyearlabel - 1 ;
    this.tmnaYearLabel = this.currentFiscalYear + 1;
  }
  
  getApprovedSupplierlistDetail(originalIndex?: number) {
    this.ngxSpinner.show();
    sessionStorage.setItem("indexBeforeLeaving", originalIndex?.toString());
    if(this.approvedSupplierListResponse && this.approvedSupplierListResponse.length > 0) {
      this.supplierListResponse = {};
      this.supplierList.length = 0;
      this.supplierListResponse = this.approvedSupplierListResponse;
      this.supplierList = this.supplierListResponse?.map((value) => value.description);
      this.appParentId = this.supplierListResponse?.map((value) => value.appparentid);
      this.disableLinkButton()
      if (this.checkSupplierListForIndex(originalIndex)) {  //Setting the search filter index on click of Save/ Approve/ Do not send buttons
        this.supplierListFlag = true;
        this.selectedFilterIndex = originalIndex;
      }
      if(originalIndex === 0) {
        this.selectedIndex = this.selectedFilterIndex = 0;
      }
      this.getSelectedSupplierDetails(this.selectedIndex);
    }else{
      this.supplierListResponse = {};
      this.supplierList.length = 0;
      this.getSelectedSupplierDetails(this.selectedIndex);
    }
  }

  getNotSentSupplierlistDetail(originalIndex?: number) {
    this.ngxSpinner.show();
    sessionStorage.setItem("indexBeforeLeaving", originalIndex?.toString());
    if(this.noSendSupplierListResponse && this.noSendSupplierListResponse.length > 0) {
      this.supplierListResponse = {};
      this.supplierList.length = 0;
      this.supplierListResponse = this.noSendSupplierListResponse;
      this.supplierList = this.supplierListResponse?.map((value) => value.description);
      this.appParentId = this.supplierListResponse?.map((value) => value.appparentid);
      this.disableLinkButton()
      if (this.checkSupplierListForIndex(originalIndex)) {  //Setting the search filter index on click of Save/ Approve/ Do not send buttons
        this.supplierListFlag = true;
        this.selectedFilterIndex = originalIndex;
      }
      if(originalIndex === 0) {
        this.selectedIndex = this.selectedFilterIndex = 0;
      }
      this.getSelectedSupplierDetails(this.selectedIndex);
    } else{
      this.supplierListResponse = {};
      this.supplierList.length = 0;
      this.getSelectedSupplierDetails(this.selectedIndex);
    }
  }

  getOpenSupplierlistDetail(originalIndex?: number, tab?: number) {
    this.ngxSpinner.show();
    sessionStorage.setItem("indexBeforeLeaving", originalIndex?.toString());
    if((this.openSupplierListResponse && this.openSupplierListResponse.length > 0) ||
      (this.inProgressSupplierListResponse && this.inProgressSupplierListResponse.length > 0) ||
      (this.readyForReviewSupplierListResponse && this.readyForReviewSupplierListResponse.length > 0)) {
      if(tab === 4) {
        this.supplierListResponse = {};
        this.supplierList.length = 0;
        this.supplierListResponse = this.openSupplierListResponse;
        this.supplierList = this.supplierListResponse?.map((value) => value.description);
        this.appParentId = this.supplierListResponse?.map((value) => value.appparentid);
      } else if (tab === 5) {
        this.supplierListResponse = {};
        this.supplierList.length = 0;
        this.supplierListResponse = this.inProgressSupplierListResponse;
        this.supplierList = this.supplierListResponse?.map((value) => value.description);
        this.appParentId = this.supplierListResponse?.map((value) => value.appparentid);
      } else if (tab === 6) {
        this.supplierListResponse = {};
        this.supplierList.length = 0;
        this.supplierListResponse = this.readyForReviewSupplierListResponse;
        this.supplierList = this.supplierListResponse?.map((value) => value.description);
        this.appParentId = this.supplierListResponse?.map((value) => value.appparentid);
      }
      this.disableLinkButton()
      
      if (this.checkSupplierListForIndex(originalIndex)) {  //Setting the search filter index on click of Save/ Approve/ Do not send buttons
        this.supplierListFlag = true;
        this.selectedFilterIndex = originalIndex;
      }
      if(originalIndex === 0) {
        this.selectedIndex = this.selectedFilterIndex = 0;
      }
      this.getSelectedSupplierDetails(this.selectedIndex);
    } else {
      this.supplierListResponse = {};
      this.supplierList.length = 0;
      this.getSelectedSupplierDetails(this.selectedIndex);
    }
  }

  checkSupplierListForIndex(originalIndex) {
    return this.supplierList && this.supplierList.length > 0 && (originalIndex > 0)  //Setting the search filter index on click of Save/ Approve/ Do not send buttons
  }

  disableLinkButton() {
    if(this.supplierList && this.supplierList.length > 0) {
      this.disableISEReportsLink = this.disableButtons = false;
    }else {
      this.disableISEReportsLink = this.disableButtons =  true; //disabling view/edit ISE, view reports link and Footer Buttons, if supplierList is empty
    }
  }
  
  async getSelectedSupplierDetails(originalIndex?:number) {
    this.ngxSpinner.show();
    try {
      if (this.supplierList && this.supplierList.length > 0) {
        //clearing the previously selected data before updating for new selected supplier
        this.rowDataSafetyRisk = [];
        this.commodityCodeDetails = [];
        this.commodityDetails = [];
        this.currentAPRtarget = this.prevAPRtarget = this.minoritytarget = this.wbetarget = '';
        this.improvementTargetData = [];
        this.suppInfo = [];
        this.selectedImprTargetDesc = this.selectedImprTargetTitle = '';
        this.rowDataCommodityDetails = [];
        this.prevMBEachievement = this.currentMBEachievement = this.prevWBEachievement = this.currentWBEachievement = '';
        this.selectedSupplierBuyerInfo = '';

        if(originalIndex >= this.supplierList.length) {
          this.selectedAppParentId = this.appParentId[this.supplierList.length - 1];
          this.selectedFilterIndex = this.selectedIndex = this.supplierList.length - 1;
        } else if(originalIndex < this.supplierList.length) { 
          this.selectedAppParentId = this.appParentId[originalIndex];
        } else {
          this.selectedAppParentId = this.appParentId[this.selectedIndex];
        }
        this.bService.appParentId=this.selectedAppParentId;
        sessionStorage.setItem("appParentId",this.selectedAppParentId)
        this.lookup.getSftyRiskCommodity(this.selectedAppParentId,this.fiscalyearid,this.employeeId).subscribe((data)=>{
          this.rowDataSafetyRisk = data.body.safetyRisk;
          this.commodityCodeDetails = data.body.commodityCode;
          this.commodityDetails = data.body.commodities;
          this.commodityData();
        });
        //result data
        this.lookup.getTierIIResults(this.selectedAppParentId,this.fiscalyearid).subscribe((data2)=>{
          this.resultPercentageData = data2.body.response;
          this.resultPercentageValueMethod();
        })
        const data1 = await lastValueFrom(this.lookup.getSupplierTierResult(this.selectedAppParentId,this.fiscalyearid,this.employeeId)) //load spinner until we get response
        //tierII data
        this.supplierTier2Data = data1.body.supplierTier2;
        this.supplierTier2ValueMethod();
        // Selected Supplier Info Details
        this.suppInfo = data1.body.suppInfo;
        this.supplierInfoMethod();
        //improvement target data
        this.improvementTargetData = data1.body.imprvtTarget;
        const selected = this.improvementTargetData.find((item) => item.apptemavalueimprovementtargetid === this.selectedTargetId);
        this.selectedImprTargetDesc = selected ? selected.targetdescription : '';
        this.selectedImprTargetTitle = selected ? selected.title : '';
      } else {
        this.disableLinkButton()
        this.rowDataSafetyRisk = [];
        this.commodityCodeDetails = [];
        this.commodityDetails = [];
        this.currentAPRtarget = this.prevAPRtarget = this.minoritytarget = this.wbetarget = '';
        this.improvementTargetData = [];
        this.suppInfo = [];
        this.selectedImprTargetDesc = this.selectedImprTargetTitle = '';
        this.rowDataCommodityDetails = [];
        this.prevMBEachievement = this.currentMBEachievement = this.prevWBEachievement = this.currentWBEachievement = '';
        this.selectedSupplierBuyerInfo = '';
      }
    } catch(e) {
      setTimeout(()=>{
        this.ngxSpinner.hide()
      },1000)
    }
    finally {
      setTimeout(()=>{
        this.ngxSpinner.hide()
        this.selectedSupplierFocus();
        this.cdr.detectChanges();
      },500)
    }
  }

  supplierTier2ValueMethod() {
    if (this.supplierTier2Data && this.supplierTier2Data.length > 0) {
      this.currentAPRtarget = this.supplierTier2Data[0].vipmanuimpraprtarget ?? '0';
      this.prevAPRtarget = this.supplierTier2Data[0].previoustarget ?? '0';
      this.minoritytarget =  this.supplierTier2Data[0].tieriiminoritysourcingtarget ?? '0';
      this.wbetarget = this.supplierTier2Data[0].tieriiwbetarget ?? '0';
      this.selectedTargetId = this.supplierTier2Data[0].apptemavalueimprovementtargetid;
    } else {
      this.currentAPRtarget = this.prevAPRtarget = this.minoritytarget = this.wbetarget = '0';
    }
  }

  resultPercentageValueMethod() {
    if (this.resultPercentageData && this.resultPercentageData.length > 0) {
      this.prevMBEachievement = this.resultPercentageData[0].pastmbe ?? '';
      this.currentMBEachievement = this.resultPercentageData[0].mberesult ?? '';
      this.prevWBEachievement = this.resultPercentageData[0].pastwbe ?? '';
      this.currentWBEachievement = this.resultPercentageData[0].wberesult ?? '';
    } else {
      this.prevMBEachievement = this.currentMBEachievement = this.prevWBEachievement = this.currentWBEachievement = '';
    }
  }
  
  async saveSupplierDetails() {
    this.ngxSpinner.show();
    try {
      this.updateImprvCostTrgtRequestBody = {
        appParentId: this.selectedAppParentId,
        fiscalId: this.fiscalyearid,
        targetId: this.selectedTargetId,
        imprvTrgt: this.selectedImprTargetDesc,
        aprCost: this.currentAPRtarget,
        empId: this.employeeId
      };

      await lastValueFrom(this.management.getUpdateImprvCostTrgt(this.updateImprvCostTrgtRequestBody));
      if(!this.approved && !this.donotsend && !this.readyForReview && !this.inProgress) {
        this.approvalStatusRequestBody = {
          appParentId: this.selectedAppParentId,
          fiscalId: this.fiscalyearid,
          saveFlag: true,
          readyForReviewFlag: false,
          backToBuyerFlag: false,
          comment: this.commentsFromManager,
          empId: this.employeeId
        }
        await lastValueFrom(this.management.approvalStatus(this.approvalStatusRequestBody));
      }
      await this.getTotalSupplierDetails()
    } catch(e) {
      this.ngxSpinner.hide();
    }
  }

  async approvalStatusUpdate(){
    this.ngxSpinner.show();
    if(this.isBuyer) {
      this.approvalStatusRequestBody = {
        appParentId: this.selectedAppParentId,
        fiscalId: this.fiscalyearid,
        saveFlag: false,
        readyForReviewFlag: true,
        backToBuyerFlag: false,
        comment: this.commentsFromManager,
        empId: this.employeeId
      }
      await lastValueFrom(this.management.approvalStatus(this.approvalStatusRequestBody));
    } else if(this.isManager) {
      this.approvalStatusRequestBody = {
        appParentId: this.selectedAppParentId,
        fiscalId: this.fiscalyearid,
        saveFlag: false,
        readyForReviewFlag: false,
        backToBuyerFlag: true,
        comment: this.commentsFromManager,
        empId: this.employeeId
      }
      await lastValueFrom(this.management.approvalStatus(this.approvalStatusRequestBody));
    }
    await this.getTotalSupplierDetails()
    this.showModal = false;
  }

  async doNotSendSupplierDetails() {
    this.ngxSpinner.show();
    this.doNotSendRequestBody = {
      appParentId: this.selectedAppParentId,
      fiscalId: this.fiscalyearid,
      empId: this.employeeId,
      isApproved: this.approved,
      doNotSendFlag: true //setting doNotSendFlag as true
    };
    await lastValueFrom(this.management.getApproveDoNotSend(this.doNotSendRequestBody));
    await this.getTotalSupplierDetails()
  }

  async approveDisapproveSupplierDetails() {
    this.ngxSpinner.show();
    if (this.approved) {
      this.approveDisapproveRequestBody = {
        appParentId: this.selectedAppParentId,
        fiscalId: this.fiscalyearid,
        empId: this.employeeId,
        isApproved: false, //setting isApproved as false //Disapprove button
        doNotSendFlag: false
      };
      await lastValueFrom(this.management.getApproveDoNotSend(this.approveDisapproveRequestBody));
    } else {
      this.approveDisapproveRequestBody = {
        appParentId: this.selectedAppParentId,
        fiscalId: this.fiscalyearid,
        empId: this.employeeId,
        isApproved: true, //setting isApproved as true //Approve button
        doNotSendFlag: false
      };
      await lastValueFrom(this.management.getApproveDoNotSend(this.approveDisapproveRequestBody));
    }
    await this.getTotalSupplierDetails()
  }

  calculatePercentage() {
    this.approvedpacketPercentage = (this.approvedpacket === 0 || this.totalPacket === 0) ? 0 : Number(((this.approvedpacket / this.totalPacket) * 100).toFixed(2));
    this.openPacketPercentage = (this.openPacket === 0 || this.totalPacket === 0) ? 0 : Number(((this.openPacket / this.totalPacket) * 100).toFixed(2));
    this.packetNotSentPercentage = (this.packetNotSent === 0 || this.totalPacket === 0) ? 0 : Number(((this.packetNotSent / this.totalPacket) * 100).toFixed(2));
    this.inprogressPacketPercentage = (this.inprogressPacket === 0 || this.totalPacket === 0) ? 0 : Number(((this.inprogressPacket / this.totalPacket) * 100).toFixed(2));
    this.readyforreviewPacketPercentage = (this.readyforreviewPacket === 0 || this.totalPacket === 0) ? 0 : Number(((this.readyforreviewPacket / this.totalPacket) * 100).toFixed(2));
    this.totalPacketPercentage = this.approvedpacketPercentage + this.openPacketPercentage + this.packetNotSentPercentage + this.inprogressPacketPercentage + this.readyforreviewPacketPercentage;
  }

  get filteredSupplier(): string[] {
    return this.supplierList?.filter((supplier) =>
      supplier.toLowerCase().includes(this.searchSupplier.toLocaleLowerCase())
    );
  }

  selectSupplier(index: number) {   //On selecting a supplier from the list we have to fetch all details in the page
    this.selectedFilterIndex = index;
    this.selectedIndex = this.mapFilterIndexToOriginal(index);
    sessionStorage.setItem("indexBeforeLeaving",this.selectedIndex.toString());
    this.getSelectedSupplierDetails(this.selectedIndex);
  }

  mapFilterIndexToOriginal(filteredIndex) {
    return this.supplierList.findIndex((supplier) => supplier === this.filteredSupplier[filteredIndex]);
  }

  resetSelection() {
    if(this.selectedFilterIndex !== null && (!this.filteredSupplier[this.selectedFilterIndex] || this.filteredSupplier[this.selectedFilterIndex] !== this.supplierList[this.selectedIndex])) {
      this.clearSelection();
    }
  }

  clearSelection() {
    this.selectedIndex = null;
    this.selectedFilterIndex = null;
  }

  onSearchChange() {
    if(this.searchSupplier !== '') {
      this.resetSelection();
    } else if(sessionStorage.getItem("indexBeforeLeaving") !== null) {  //when searched supplier and not selected focus previous supplier
      this.selectedIndex = this.selectedFilterIndex = Number(sessionStorage.getItem("indexBeforeLeaving"))
      setTimeout(()=>{ this.selectedSupplierFocus() },1000)
    }
  }

  supplierInfoMethod() {
    if(this.suppInfo && this.suppInfo.length > 0) {
      this.selectedSupplierBuyerInfo = this.suppInfo[0].supplier;
      this.approved = this.suppInfo[0].approved;
      sessionStorage.setItem("supplierapproved",this.approved);
      this.donotsend = this.suppInfo[0].donotsend;
      this.dateApproved = this.suppInfo[0].dateapproved ?? '';
      this.inProgress = this.suppInfo[0].inprogress ?? '';
      this.readyForReview = this.suppInfo[0].readyforreview ?? '';
      this.isSupplierInOpenStatus = (!this.approved && !this.donotsend && !this.readyForReview && !this.inProgress) === true;
      localStorage.setItem('isSupplierInOpenStatus', this.isSupplierInOpenStatus.toString())
      if(this.dateApproved !== '') {   //Approved date and time
        const isoformattedDate = this.dateApproved.replace(' ', 'T');
        const parsedDate = new Date(isoformattedDate);
        //From backend we are receiving timestamp in MST format ending with Z, so we are using UTC to display as same in all timestamp displaying places
        const formattedDate = new Intl.DateTimeFormat('en-US', {
          timeZone: 'UTC',year: 'numeric',month: 'long',day: '2-digit',hour: '2-digit',minute: '2-digit',second: '2-digit',
        }).format(parsedDate);
        this.dateApproved = formattedDate;
      }
    } else {
      this.selectedSupplierBuyerInfo = '';
      this.dateApproved = '';
      this.approved = false;
      this.donotsend = false;
      sessionStorage.setItem("supplierapproved",this.approved);
    }
    
  }

  onInputChange(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const currentAPRInput = inputElement?.value;
    const regex = /^\d+(\.\d{0,2})?$/;
    if(regex.test(currentAPRInput)) {
      this.currentAPRtargetValue = Number(currentAPRInput);
    }
  }

  allowOnlyValidChar(event: KeyboardEvent): void {
    const regex = /^[0-9.]$/;
    if(!regex.test(event.key) || (event.key === '.' && this.currentAPRtargetValue.toString().includes('.'))) {
      event.preventDefault();
    }
  }

  onFocusOut() {
    const currentAPRValue = this.currentAPRtargetValue || 0;
    if(currentAPRValue > 999) {
      this.currentAPRtarget = '999';
    }else{
      this.currentAPRtarget = this.currentAPRtargetValue.toString();
    }
  }

  commodityData() {
    if (this.commodityCodeDetails && this.commodityCodeDetails.length > 0) {
      this.rowDataCommodityDetails = this.commodityCodeDetails.map(
        (commodity) => {     //matching values based on appid
          const description = this.commodityDetails.find((val) => val.appid === commodity.appid)?.description || '';
          return {
            suppliercode: commodity.suppliercode,
            description: description,
            oedefecttarget: commodity.oedefecttarget,
            servicedefecttarget: commodity.servicedefecttarget,
            prototypedefecttarget: commodity.prototypedefecttarget,
            srankqpr: commodity.srankqpr,
            deliverykpm: commodity.deliverykpm,
            warrantytarget: this.formatWarrantyValue(commodity.warrantytarget),
            appId: commodity.appid,
            appParentId: commodity.appparentid,
            fiscalId: this.fiscalyearid,
          };
        }
      );
    }
  }

  formatWarrantyValue(value): string | number {
    if(!isNaN(Number(value))) {
      return Math.round(Number(value)).toString()
    }
    return value
  }

  onSelectingImprvtTargetTitle(): void {
    const selected = this.improvementTargetData.find((item) => item.title === this.selectedImprTargetTitle);
    this.selectedImprTargetDesc = selected ? selected.targetdescription : '';
    this.selectedTargetId = selected?.apptemavalueimprovementtargetid;
    this.cdr.detectChanges();
  }

  trackByFn(item: any): string { return item.title; }

  openModal(){
    this.showModal = true;
    this.commentsFromManager = '';
  }

  closeModal(){
    this.showModal = false;
    this.commentsFromManager = '';
  }

  generatePDF() {
    this.ngxSpinner.show()
    this.lookup.getGeneratedPDF(this.selectedAppParentId).subscribe({
    next: data=>{
      if(data['statusCode'] === 200){
        const a = document.createElement('a');
        a.href = data["body"]["fileurl"];
        a.target = '_blank';
        a.rel = 'noreferrer noopener'
        if(a)
        {
          window.open(a.href, '_blank');
        }
        this.ngxSpinner.hide()
      }else{
        this.ngxSpinner.hide()
      }
    },
    error: _error=>{ this.ngxSpinner.hide(); }});
  }
  
  cancelNullGuidAssume() {
    this.isDisplay = true;
    localStorage.clear();
    location.reload();
  }
  
  ngOnDestroy() { sessionStorage.removeItem('page'); }
}
