<div class="container-fluid m-2 pb-5">
    <div class="row"></div>
    <div class="heading mt-1">
        TOYOTA Motor Engineering & Manufacturing North America, Inc.
    </div>

    <div class="subHeading mt-3" *ngIf="!noResponseKeyInUrl">
        Annual Purchansing Policy FY{{fiscalYear}}
    </div>

    <div *ngIf="!noResponseKeyInUrl">
        <div class="d-flex" style="flex-direction: column; width: 75%; align-items: center;">
            <span class="label-tmna nameResponseDetails" style="font-size: medium;">{{this.supplierName}}</span>
            <span *ngIf="this.alreadyAcknowledged" class="label-tmna nameResponseDetails mt-1" style="font-size: medium;">Response submitted on {{this.updated}}</span>
            <span *ngIf="!this.alreadyAcknowledged" class="label-tmna nameResponseDetails mt-1" style="font-size: medium;">Responses Due By {{this.dueDate}}</span>
        </div>
    
        <div class="layout mb-3 mt-3">
            <span class="mt-1 ms-1" style="font-size: small;">Please enter your contact information below:</span>
            <div class="fieldSection mt-1 ms-1 me-1 mb-1">
                <div class="header ps-1 pb-2">
                    Acknowledgement Of Receipt
                </div>
                <form [formGroup]="supplierAckForm" (ngSubmit)="onSubmit()">
                    <div class="d-flex m-1 pb-1">
                        <div class="col-md-3 ms-1">
                            <label for="greeting" class="label-tmna"> Greeting:</label>
                        </div>
                        <div class="col-md-9 ms-1 d-flex"> 
                            <select id="greetings" formControlName="greetings" class="dropdown-tmna" style="width: 50%">
                                <option class="displayTextcss" *ngFor="let item of salutations" [value]="item">
                                    {{item}}
                                </option>
                            </select>
                            <div class="ms-2" *ngIf="form['greetings'].touched && form['greetings'].invalid">
                                <small *ngIf="form['greetings'].errors?.['required']">Select a salutation</small>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex m-1 pb-1">
                        <div class="col-md-3 ms-1">
                            <label for="firstName" class="label-tmna"> First Name:</label>
                        </div>
                        <div class="col-md-9 ms-1 d-flex"> 
                            <input class="input-tmna me-2" id="firstName" formControlName="firstName" type="text" style="width: 50%" placeholder="Enter First Name">
                            <div *ngIf="form['firstName'].touched && form['firstName'].invalid">
                                <small *ngIf="form['firstName'].errors?.['required']"> First Name is required </small>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex m-1 pb-1">
                        <div class="col-md-3 ms-1">
                            <label for="lastName" class="label-tmna"> Last Name:</label>
                        </div>
                        <div class="col-md-9 ms-1 d-flex"> 
                            <input class="input-tmna me-2" id="lastName" formControlName="lastName" type="text" style="width: 50%" placeholder="Enter Last Name">
                            <div *ngIf="form['lastName'].touched && form['lastName'].invalid">
                                <small *ngIf="form['lastName'].errors?.['required']"> Last Name is required </small>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex m-1 pb-1">
                        <div class="col-md-3 ms-1">
                            <label for="title" class="label-tmna"> Title:</label>
                        </div>
                        <div class="col-md-9 ms-1 d-flex"> 
                            <input class="input-tmna me-2" id="title" formControlName="title" type="text" style="width: 50%" placeholder="Enter Title">
                            <div *ngIf="form['title'].touched && form['title'].invalid">
                                <small *ngIf="form['title'].errors?.['required']"> Title is required </small>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex m-1 pb-1">
                        <div class="col-md-3 ms-1">
                            <label for="email" class="label-tmna"> Email:</label>
                        </div>
                        <div class="col-md-9 ms-1 d-flex"> 
                            <input class="input-tmna me-2" id="email" formControlName="email" style="width: 50%" placeholder="Enter Email">
                            <div *ngIf="form['email'].touched && form['email'].invalid">
                                <small *ngIf="form['email'].errors?.['required']"> Email is required </small>
                                <small *ngIf="form['email'].errors?.['email']"> Email is required </small>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex m-1 pb-1">
                        <div class="col-md-3 ms-1">
                            <input class="chkboxStyle ms-1" type="checkbox" formControlName="checkboxAcknowledge" style="margin-right: 10px;box-shadow: none;">
                        </div>
                        <div class="col-md-9 ms-1 d-flex" style="flex-direction: column;">
                            <label for="checkboxAcknowledge" class="label-tmna">
                                {{this.acknowledgementText}}
                            </label>
                            <div class="ms-1" *ngIf="form['checkboxAcknowledge'].touched && form['checkboxAcknowledge'].invalid">
                                <small>Acknowledge the checkbox</small>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex m-1 pb-1 justify-content-center">
                        <button [ngClass] = "{'disabled': this.alreadyAcknowledged}"  type="submit" class="submitButton" >Submit</button>
                    </div>
                </form>
            </div>
        </div>
    
        <div class="hrline1 mb-3" style="height: 5px;"></div>
    
        <div class="mb-3">
            <span class="label-tmna">If you are experiencing problems with the survey, please contact your <span class="bold">TMNA Buyer</span>.</span>
        </div>
    
        <div class="hrline2 mb-3" style="height: 5px;"></div>
    
        <div class="footerLink flex-row d-flex" style="gap: 20px;">
            <div>
                <a href="https://www.toyota.com/support/privacy-notice/" target="_blank" rel="noopener">Privacy Notice</a>
            </div>
            <div>
                <a href="https://privacy.toyota.com/#/" target="_blank" rel="noopener">Your Privacy Choices
                <img class="ms-1 privacyoptionImg" src="../assets/images/privacyoptions.svg" alt="img"
                    style="height: 20px; width: 40px;"></a>
            </div>
        </div>
    </div>

    <div *ngIf="noResponseKeyInUrl" style="width: 75%;">
        <div class="d-flex mb-3 mt-3" style="flex-direction: column; align-items: center;">
            <span class="label-tmna mb-3 justify-content-center">
                Supplier Not Found
            </span>
            <span class="label-tmna mb-3 justify-content-center">
                Part or all of the key may be missing. Please refer again to the email request you received from TMNA Purchasing containing a link that is unique to your company and this response period. Use the link in the email to access and complete your response. If you cannot click on the link in the email, try copying and pasting it into your browser's address field.
            </span>
            <span class="label-tmna justify-content-center">
                If you are experiencing problems with the survey, please contact your <span class="bold">TMNA Buyer</span>.
            </span>
        </div>
    </div>
    
</div>