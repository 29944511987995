<div class="alert-success" *ngIf="successMessageAlert">
    <img style="margin-right: 1em;" src="../assets/images/checkCircle.svg" alt="successMsg">
    <span class="alert-close" >&times;</span>
    <span>{{successMssg}}</span>

</div>
<div class="alert-failure" *ngIf="failureMessageAlert">
    <img style="margin-right: 1em;" src="../assets/images/errCircle.png" alt="failureMsg">
    
    <span>{{failureMssg}}</span>
</div>
<div class="row subHeading m-2">
    <div class="d-flex justify-content-start">
        <div class="me-5">
            <span class="row subHeading mt-2" style="font-style: italic;">This tab is to add, modify/update APP/BRM flag options and descriptions</span>
        </div>
        <div class="d-flex addBtnPosition">
            <button class="addButton ms-2"
            (click)="addSupplierFlag()">ADD+</button>
        </div>
    </div>
    <div class="me-5">
        <span class="row subHeading mt-1" style="font-weight: 600;color: #212529">NOTE: Do not duplicate with same data while adding.</span>
    </div>
</div>
<div class="flex-row p-1">
    <ag-grid-angular #agGrid1 style="margin-bottom:50px;" class="ag-theme-balham " [rowData]="rowDataForSupplierFlag"
        [columnDefs]="columnDefForSupplierFlag" [pagination]="true"
        headerHeight="50" [defaultColDef]="defaultColDef" [paginationPageSize]="paginationSupplierFlagScreen" [groupDisplayType]="groupDisplayType"
        [paginationPageSizeSelector]="paginationSupplierFlagPageSizeSelector" [domLayout]="domLayout" [icons]="icons" [tabToNextCell]="tabToNextCell" [rowGroupPanelShow]="'always'" [suppressDragLeaveHidesColumns]="true">
    </ag-grid-angular>
</div>