import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-improvement-target-edit',
  templateUrl: './improvement-target-edit.component.html',
  styleUrl: './improvement-target-edit.component.css'
})
export class ImprovementTargetEditComponent implements ICellRendererAngularComp {

  constructor(private readonly ngxSpinner: NgxSpinnerService) {}

  refresh(_params: ICellRendererParams): boolean {
    throw new Error('Method not implemented.');
  }

  params;

  agInit(params): void {
    this.params = params;
  }

  edit($event) { 
    this.params.data.editable=true;
    if (this.params.edit instanceof Function) {
      const params = { //NOSONAR
        event: $event,
        rowData: this.params.node.data,
        api:this.params.gridApi
      }
      this.params.edit(this.params);

    }
  }

  update($event) {
    this.ngxSpinner.show();
    if(this.params.data.title!==null && this.params.data.title!==undefined && this.params.data.title!=="" &&
      this.params.data.targetdescription!==null && this.params.data.targetdescription!==undefined  && this.params.data.targetdescription!=="" &&
      this.params.data.displayorder!==null && this.params.data.displayorder!==undefined && this.params.data.displayorder!==""){
    this.params.data.editable=false;
    setTimeout(()=>{
      this.ngxSpinner.hide();
    },1000);}
    if (this.params.update instanceof Function) {
      const params = { //NOSONAR
        event: $event,
        rowData: this.params.node.data,
        api:this.params.gridApi
      }
      this.params.update(this.params);

    }
    setTimeout(()=>{
      this.ngxSpinner.hide();
    },1000);
  }

  cancel($event) { 
    this.params.data.editable=false;
    if (this.params.cancel instanceof Function) {
      const params = { //NOSONAR
        event: $event,
        rowData: this.params.node.data,
        api:this.params.gridApi
      }
      this.params.cancel(this.params);

    }
  }
}
