import { Injectable } from '@angular/core';
import { BehaviorSubject, interval, Subscription, switchMap } from 'rxjs';
import { LookupService } from './lookup.service';

@Injectable({
  providedIn: 'root'
})
export class DisplayNotificationService {

  constructor(private readonly lookup: LookupService) { }
  private intervalSubscription!: Subscription; 
  private readonly messageSubject = new BehaviorSubject<string>('')
  message$ = this.messageSubject.asObservable()

  private readonly inprogressSubject = new BehaviorSubject<string>('')
  inprogress$ = this.inprogressSubject.asObservable()
  
  showMessage(message: string, duration: number) {
    this.messageSubject.next(message);
    setTimeout(()=>{
      this.messageSubject.next('');
    }, duration)
  }

  startTracking(uuid: string) {
    localStorage.setItem("uuid", uuid);
    this.stopTracking();
    
    this.inprogressSubject.next('inprogress')
    this.intervalSubscription = interval(5000).pipe(   //calling the api after 5 seconds interval
      switchMap(() => this.lookup.getOEUploadStatus(uuid))
    ).subscribe({
      next: (data) => {
        if (data?.body?.response?.status.toLowerCase() === "completed") {
          this.showMessage('OE Report Upload Completed.', 10000);
          this.stopTracking();
          this.inprogressSubject.next('completed')
          localStorage.removeItem("uuid");
        } else if (data?.body?.response?.status.toLowerCase() === "in-progress") {
          this.showMessage('', 0);
          this.inprogressSubject.next('inprogress')
        } else if (data?.body?.response?.message.toLowerCase() === "plant codes not found. please review and upload again") {
          this.inprogressSubject.next('failure')
          localStorage.removeItem("uuid");
          this.showMessage('Plant codes not found. Please review and upload again', 10000);
          this.stopTracking();
        } else {
          this.inprogressSubject.next('failure')
          localStorage.removeItem("uuid");
          this.showMessage('OE Report Upload Failed. Please review and upload the file again.', 10000);
          this.stopTracking();
        }
      }, error: (_error) => {
        // Handle the error here
        this.stopTracking();
        this.inprogressSubject.next('failure')
        localStorage.removeItem("uuid");
        this.showMessage('An error occurred while tracking the upload status.', 10000);
      }
    }
      
    );
  }

  stopTracking() {
    if(this.intervalSubscription) {
      this.intervalSubscription.unsubscribe()
    }
  }

  restoreTracking() {
    const uuid = localStorage.getItem("uuid")
    if(uuid) {
      this.startTracking(uuid)
    }
  }
}
