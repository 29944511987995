import { Component, OnInit, HostListener } from '@angular/core';
import { GridApi, GridOptions } from 'ag-grid-community';

import { PacketfileComponent } from './packetfile/packetfile.component';
import { LookupService } from '../services/lookup.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AgGridSortingService } from '../services/ag-grid-sorting.service';
import { lastValueFrom , distinctUntilChanged } from 'rxjs';
import { Router } from '@angular/router';
import { ErrorMessagesService } from '../services/error-messages.service';

@Component({
  selector: 'app-packet-set',
  templateUrl: './packet-set.component.html',
  styleUrl: './packet-set.component.css'
})
export class PacketSetComponent implements OnInit{
  columnDef: any[];
  limit = 1000;
  totalPackets: any;
  packetDetailsResponse: any;
  showPacketErrorMsg: boolean;
  
  validation: any;
  showErrorMessage: string;
  ngOnInit(): void {
    this.validation=this.lookup.localStorageData.pipe(distinctUntilChanged()).subscribe((data)=> {
      const localStorageAssumeUser = JSON.parse(localStorage.getItem('userAssumed'));
      const redirectFlag = localStorage.getItem("redirectedAssume");
      if(data?.includes('success')){
        this.getfy();
      }else if((data?.includes('failure') || data === null) && localStorageAssumeUser){
        if(redirectFlag === "false"){
          this.router.navigate(['home'])
        }
      }
    })
  }

  constructor(private readonly ngxSpinner:NgxSpinnerService,private readonly router: Router,
    private readonly lookup:LookupService, private readonly sortService:AgGridSortingService,
    private readonly errorService:ErrorMessagesService
    ){
      this.columnDef=[
        {
          headerName: 'Strategy',
          field: 'description',
          minWidth: 40,
          flex: 1,
          autoHeight: true,
          enableRowGroup: true,
          comparator: this.sortService.stringComparator
        },
        {
          headerName: 'Supplier Category',
          field: 'temavalueimprovementtarget',
          minWidth: 40,
          flex: 1,
          autoHeight: true,
          enableRowGroup: true,
          comparator: this.sortService.stringComparator
        },
        {
          headerName: 'Supplier Flag',
          field: 'appparentflag',
          minWidth: 40,
          flex: 1,
          autoHeight: true,
          enableRowGroup: true,
          comparator: this.sortService.stringComparator
        },
        {
          headerName: 'Year',
          field: 'year',
          minWidth: 30,
          width:100,
          flex: 1,
          autoHeight: true,
          enableRowGroup: true,
        },
        {
          headerName: 'Purchasing Group',
          field: 'purchasinggroupname',
          minWidth: 40,
          flex: 1,
          autoHeight: true,
          enableRowGroup: true,
          comparator: this.sortService.stringComparator
        },
        {
          headerName: 'Date Sent',
          field: 'datesent',
          minWidth: 20,
          width:150,
          flex: 1,
          autoHeight: true,
          enableRowGroup: true,
          cellRenderer: (params) => {
            return params.value? this.formatDate(params.value) : ''
          }
        },
        {
          headerName: 'Supplier Confirmed',
          field: 'supplierconfirmed',
          minWidth: 20,
          width:150,
          flex: 1,
          autoHeight: true,
          enableRowGroup: true,
          cellRenderer: (params) => {
            return params.value? this.formatDate(params.value) : ''
          }
        },
        {
          headerName: 'Recipients',
          field: 'recipientemailaddress',
          minWidth: 60,
          flex: 1,
          autoHeight: true,
          enableRowGroup: true,
          comparator: this.sortService.stringComparator
        },
        {
          headerName: 'File',
          field: 'filename',
          minWidth: 40,
          flex: 1,
          autoHeight: true,
          enableRowGroup: true,
          cellRenderer: PacketfileComponent,
          cellRendererParams: {
            downloadpacket: this.downloadpacket.bind(this)
          },
          comparator: this.sortService.stringComparator
        }
       ];
    }

  fiscalYear:any=[];
  domLayout='autoHeight';
  gridApi: GridApi;
  gridOptions: GridOptions;
  gridColumnApi: any;
  rowData=[];
  defaultColDef={ 
    suppressNavigable: true,
    suppressMovable: false,
    cellClass: 'no-border',
    enableRowGroup: true,
    unSortIcon: true,
    floatingFilter: true,
    filter: 'agTextColumnFilter',
    wrapText: true,
    suppressHeaderMenuButton: true,
    suppressFloatingFilterButton: true,
    sortable: true
   };

   icons = {
    menu: ' ',
    filter: ' '
   }

   formatDate(value) {
    //From backend we are receiving timestamp in MST format ending with Z, so we are using UTC to display as same in all timestamp displaying places
    const isoformattedDate = value.replace(' ', 'T');
    const parsedDate = new Date(isoformattedDate);
    return new Intl.DateTimeFormat('en-US', {   //NOSONAR
      timeZone: 'UTC',
      year: 'numeric',
      month: 'numeric',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    }).format(parsedDate);
   }

   getfy(){
    this.ngxSpinner.show();
    this.lookup.getFiscalYearListPacketSent().subscribe(data=>{
      this.fiscalYear[0]=data.body.response[0];
      this.fiscalYear[1]=data.body.response[1];
      this.fiscalYear[2]=data.body.response[2];
      this.changefy(this.fiscalYear[0].fiscalyearid, 1);
    })
    setTimeout(()=> {
      this.ngxSpinner.hide();
    }, 2000);
   }

   downloadpacket(_params){
    const appparentId = _params['data']['appparentid']
    this.ngxSpinner.show()
    this.lookup.getGeneratedPDF(appparentId).subscribe({
      next: (data)=>{
      if(data['statusCode'] === 200){
        const a = document.createElement('a');
        a.href = data["body"]["fileurl"];
        a.target = '_blank';
        a.rel = 'noreferrer noopener'
        if(a)
        {
          window.open(a.href, '_blank');
        }
        this.ngxSpinner.hide()
      }else{
        this.ngxSpinner.hide()
      }
      },
      error: _error=>{
        this.ngxSpinner.hide();
      }});
   }

   async changefy(fiscalyearid, page){
    this.ngxSpinner.show();
    try { 
      const data = await lastValueFrom(this.lookup.apphistoryget(fiscalyearid,page,this.limit))
      if(data['statusCode']===200 && data?.body?.response && data?.body?.response?.data.length>0 && data?.body?.response?.totalrecords &&  !data?.body?.errorResponse) {
        //setting total number of packets
        if(page === 1) {
          this.rowData = []
          this.totalPackets = data.body.response.totalrecords;
        }
        //If page is greater than 1, then push data to the rowData
        if(page>1) {
          this.ngxSpinner.hide();
            this.rowData = [...this.rowData, ...data.body.response.data]
        } else {
            this.rowData = data?.body?.response?.data;
        }
        //If totalPackets is more than limit, call the api again to fetch date from next page
        if((this.limit - this.totalPackets)<0) {
            page += 1;
            this.changefy(fiscalyearid, page)
            this.totalPackets -= this.limit;
        }
      } else {
        this.showErrorMessage = this.errorService.noPacketSentDataErrMsg
        this.showPacketErrorMsg = true;
        setTimeout(()=>{
          this.showPacketErrorMsg = false;
        },5000)
        this.ngxSpinner.hide()
      }
    } catch {
      this.showErrorMessage = this.errorService.serverErrMsg
      this.showPacketErrorMsg = true;
      setTimeout(()=>{
        this.showPacketErrorMsg = false;
      },5000)
      this.ngxSpinner.hide()
    } finally {
        this.ngxSpinner.hide()
    }
  }

   onGridReady(params:any){
    this.gridApi = params?.api;
    this.gridColumnApi = params?.columnApi;
    this.gridApi?.sizeColumnsToFit(); 
  }
  @HostListener("window:resize", ['$event'])
  onResize(_event) {

    if (this.gridApi != null) {

      this.gridApi?.sizeColumnsToFit();
      this.gridApi.refreshCells();
    }

  }
}
