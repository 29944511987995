// import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { LicenseManager } from 'ag-grid-enterprise';
// import { environment } from './environments/environment';

// if (environment.production) {
//   enableProdMode();
// }

platformBrowserDynamic().bootstrapModule(AppModule, { ngZoneEventCoalescing: true })
  .catch(err => console.error(err)); //NOSONAR

LicenseManager.setLicenseKey('Using_this_{AG_Grid}_Enterprise_key_{AG-076586}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Toyota_Motor_North_America,_Inc.}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Annual_Purchasing_Policy}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Annual_Purchasing_Policy}_need_to_be_licensed___{Annual_Purchasing_Policy}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{8_March_2026}____[v3]_[01]_MTc3MjkyODAwMDAwMA==5ed23fcdd860cbc86c4cc9fbc970d6f1')
