<div class="container-fluid">
    <div class="row mt-1">
        <app-breadcrumb [breadcrumbitems]="breadcrumbItems"></app-breadcrumb>
    </div>
    <div class="alert-failure" *ngIf="failureMessageAlert" style="margin-bottom:20px;">
        <img style="margin-right: 1em;" src="../assets/images/errCircle.png" alt="failureMsg">
        <span class="alert-close">&times;</span>
        <span>{{failureMessage}}</span>
    </div>
    <form [formGroup]="ietForm">
    <div class="row">
        <div class="col-md-12">
            <div class="accordion-wrapper" style="margin-bottom: 30px;">
                <div class="accordion mb-3" id="homeAccordianOne">
                  <div class="card">
                    <div class="card-header">
                      <div class="d-flex flex-row justify-content-between">
                        <span class="commonFont pt-2">TASK DETAILS</span>
                        <button class="btn headButton" type="button" data-bs-toggle="collapse" id="accordion3Btn"
                          accordianActive="true" data-bs-target="#collapsesearchfilter1" aria-expanded="true"
                          aria-controls="collapsesearchfilter1" (click)="isOpen1=!isOpen1">
              
                          <img class="clpseBtn collapsesearchfilterBtn" [ngClass]="{'active':isOpen1}" alt="img">
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="collapsesearchfilter1" class="collapse show" aria-labelledby="headingOne" data-bs-parent="#proposalHist">
                    <div class="cardBody">
                      <div class="accordion-main p-2">
                        <div class="m-3">
                            <div class="col-md-6">
                                <select class="standiet" id="standardIET" 
                                (change)="titleIET($event.target.value)" >
                                    <option  value="" selected  >Please select an IET</option>
                                    <option *ngFor="let IET of standardIET;" [value]="IET.standardietid">{{IET.title}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="d-flex mt-3">
                            <div class="col-md-2"><span class="label-tmna ms-4 required">Heading</span></div>
                            <div class="col-md-6"> <input formControlName="heading" class="input-tmna" type="text" placeholder="Enter Heading"></div>
                        </div>
                        <div class="d-flex mt-3">
                            <div class="col-md-2"><span class="label-tmna ms-4 required">Body</span></div>
                            <div class="col-md-6"> <textarea formControlName="body" class="label-tmna-big" type="text" placeholder="Enter Body"></textarea></div>
                        </div>
                        <div class="d-flex mt-3">
                            <div class="col-md-2"><span class="label-tmna ms-4">Bullet(s)</span></div>
                            <div class="col-md-6">
                                <div class="bullets-listbox" style="overflow-y: scroll;">
                                    <ul>
                                        <li *ngFor="let item of bullets; let i = index" (click)="selectBullet(i, $event)" (keyup)="selectBullet(i, $event)" [class.selected] = "i === selectedIndex">
                                        {{ parseHTMLContent(item.bullet) }}
                                        </li>
                                    </ul>
                                </div>
                                <div class="button-container flex-row" style="margin-top: 10px;">
                                    <button (click)="openAddBulletModal()" class="closeButton me-2" style="width: 140px;">
                                        New Bullet +
                                    </button>
                                    <button *ngIf="bullets.length>=1" (click)="openEditBulletModal()" [disabled]="selectedIndex === null" class="closeButton me-2" style="width: 140px;">
                                        <img src="\assets\images\pencil.png" alt="Edit Bullet" height="18px" width="18px">
                                        Edit Bullet</button>
                                    <button *ngIf="bullets.length>=1" (click)="toggleBulletReorder()" class="closeButton me-2" style="width: 140px;">
                                        ReOrder Bullet
                                    </button>
                                    <div class="flex-row" style="display: flex;">
                                        <button *ngIf="isBulletReorder" (click)="moveUp()" [disabled]="selectedIndex == null || selectedIndex === 0" class="addButton me-2" style="height: 38px; width: 38px;">
                                            <img src="\assets\images\upArrow.png" alt="Move Bullet Up" height="18px" width="20px">
                                        </button>
                                        <button *ngIf="isBulletReorder" (click)="moveDown()" [disabled]="selectedIndex == null || selectedIndex === bullets.length - 1" class="addButton me-2" style="height: 38px; width: 38px;">
                                            <img src="\assets\images\downArrow.png" alt="Move Bullet Down" height="18px" width="20px">    
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="me-2">
                            <div class="removeDetailsBox p-3" >
                              <div class="label-tmna" style="position:relative;left:40%;">
                                IET must be saved before sub bullets can be added </div>
                             </div>
                          </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</form>
<nav class="navbar navbar-expand-lg justify-content-end footer-tab mt-2">
    <div class="saveFooter mb-3">
        <button class="addButton ms-3 me-2" (click)="submit()">SAVE</button>
        <button class="closeButton ms-3" (click)="cancel()">CANCEL</button>
    </div>
</nav>


<div class="modal" tabindex="-1" [ngClass]="{'show': showBulletModal}" [ngStyle]="{'display': showBulletModal ? 'block':'none'}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <span class="modalHeading"> Bullet </span>
                <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="closeBulletModal()">
                    <img src="\assets\images\close-icon.png" alt="Close Modal" height="15" width="15" style="margin-bottom: 4px;">
                </button>
            </div>
            <div class="modal-body">
                <textarea [(ngModel)]="bulletText" class="form-control label-tmna-big" type="text" (input)="onInputChange($event)"></textarea>
            </div>
            <div class="modal-footer">
                <button class="closeButton me-2" (click)="closeBulletModal()"> CANCEL </button>
                <button class="closeButton me-2" (click)="isdeleteBullet ? deleteBullet() : ''" [ngClass]="{'disableButton': !allowAddBullet}"> DELETE </button>
                <button class="addButton" (click)="isEditMode ? editBullet() : addBullet()" [ngClass]="{'disableButton': !allowAddBullet}"> SAVE </button>
            </div>
        </div>
    </div>
</div>

<div class="modal" tabindex="-1" [ngClass]="{'show': showSubBulletModal}" [ngStyle]="{'display': showSubBulletModal ? 'block':'none'}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <span class="modalHeading"> Sub-Bullet </span>
                <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="closeSubBulletModal()">
                    <img src="\assets\images\close-icon.png" alt="Close Modal" height="15" width="15" style="margin-bottom: 4px;">
                </button>
            </div>
            <div class="modal-body">
                <textarea [(ngModel)]="subBulletText" class="form-control label-tmna-big" type="text"></textarea>
            </div>
            <div class="modal-footer">
                <button class="closeButton me-2" (click)="closeSubBulletModal()"> CANCEL </button>                
                <button class="closeButton me-2" (click)="isdeleteSubBullet ? deleteSubBullet() : ''"> DELETE </button>
                <button class="addButton" (click)="isEditSubBulletMode ? editSubBullet() : addSubBullet()"> SAVE </button>                
            </div>
        </div>
    </div>
</div>
