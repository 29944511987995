import { Component, HostListener } from '@angular/core';
import { BusinessEntityService } from './services/business-entity.service';
import { LookupService } from './services/lookup.service';
import { NavigationEnd, Router } from '@angular/router';
import { AuthenticationService } from './services/authentication/authentication.service';
import { Subject } from 'rxjs';
import { DisplayNotificationService } from './services/display-notification.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
 
  title = 'app-ui';
  isCloseClicked=false;
  newClickLink=false;
  selectedAllfunctionComp=false;
  activeLink ='active-link';
  isDisplay=false;
  isAdmin = false;
  loggedinUserName ='';
  loggedinUserRole ='';
  assumeIdentityList=[];
  userActivity: any;
  userInactive: Subject<any> = new Subject();
  showSupplierAck: boolean;
  fiscalYear: string;
  
  constructor(public bService :BusinessEntityService, private readonly router: Router, private readonly displayNotification: DisplayNotificationService,
    public lookupService: LookupService,private readonly authenticationService: AuthenticationService,){
      clearTimeout(this.userActivity);
        this.setTimeout();
        this.userInactive.subscribe(() => {
          localStorage.clear();
          sessionStorage.clear();
          this.authenticationService.logout()
        });

      this.router.events.subscribe((event) => {
        if(event instanceof NavigationEnd) {
          this.showSupplierAck = event.url === '/supplierAcknowledgement'
        }
      })
  }

  ngOnInit() {
    if(window.location.pathname !== '/supplierAcknowledgement') {
      this.displayNotification.restoreTracking();
      this.fiscalYear = localStorage.getItem("fiscalId");
      if(this.fiscalYear === null) {  //If fiscalyear value is null, navigating to home screen
        this.router.navigate(['/home']);
      }
    }
  }

  setTimeout() {
    this.userActivity = setTimeout(() => this.userInactive.next(undefined), 2700000) //2700000 = 45 min
  }
  @HostListener('document:keypress') refreshUserState2() {
    clearTimeout(this.userActivity);
    this.userActivity = 0;
    this.setTimeout();
  }
  
  @HostListener('document:click') refreshUserState3() { //NOSONAR
    clearTimeout(this.userActivity);
    this.userActivity = 0;
    this.setTimeout();
  }
  
  @HostListener('mousewheel') refreshUserState4() { //NOSONAR
    clearTimeout(this.userActivity);
    this.userActivity = 0;
    this.setTimeout();
  }


 
}


