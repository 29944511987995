<div class="alert-failure" *ngIf="showNoStandardIETErrMsg">
    <img style="margin-right: 1em;" src="../assets/images/errCircle.png" alt="failureMsg">
    
    <span>{{pageErrorMsg}}</span>
  </div>

<div class="row m-3">
    <div class="commonFont" style="font-size: 15px;"> STANDARD IETs</div>
    <div class="subHeading">This tab is to Apply Standard IETs to Suppliers.</div>
</div>
<div class="m-3">
    <div class="col-md-6">
        <select class="standiet" id="standardIET" 
        (change)="titleIET($event.target.value)" >
            <option *ngFor="let IET of standardIET;" [value]="IET.title">{{IET.title}}</option>
        </select>
    </div>
    <div class="col-md-6 p-2">
        <div class="boxHeader commonFont" style="height:100px;">
            <div class="d-flex justify-content-between ">
                <span class="ms-2 mt-1 ">SUPPLIER LIST </span>
                <button class="closeButton mt-1 me-2" style="width: fit-content;" (click)="allnoneIET()" [ngClass]="{'noStandardIET': disableButtons}">ALL/NONE</button>


            </div>
            <div class="p-2">
                <input type="text" class="form-control addsearch search-tmna "
                    placeholder="Search here" (keyup)="filteredSuppliersList()" [(ngModel)]="inputSearchSuppliersList" [ngModelOptions]="{standalone: true}">
                <img class="ms-1 searchimg" src="../assets/images/search.svg" alt="img"
                    style="position: absolute;right: 17px;top: 60px;">
            </div>

        </div>
        <div class="boxISE me-1 p-2  ">

            <div class="displayTextcss" *ngFor="let suppList of suppliersList">
                <input type="checkbox" (click)="checkIET(suppList.description,!suppList.checked)"
                (keyup)="checkIET(suppList.description,!suppList.checked)"
                [checked]="suppList.checked" style="margin-right:10px;">{{suppList.description}}</div>

        </div>
        <div class="boxHeader justify-content-end  ">
            <button class="closeButton ms-3 me-2 mt-2" style="width: fit-content;" (click)="titleIET(titleStandardIET)" [ngClass]="{'noStandardIET': disableButtons}">Refresh</button>
            <button class="addButton ms-3 ms-2 mt-2" style="width: fit-content;" (click)="applyStandardIET()" [ngClass]="{'noStandardIET': disableButtons}">APPLY</button>

        </div>

    </div>
</div>