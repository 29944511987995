<div class="alert-failure" *ngIf="showPageErrorMsg">
    <img style="margin-right: 1em;" src="../assets/images/errCircle.png" alt="failureMsg">
    <span>{{pageFailureMsg}}</span>
</div>
<div clas="container-fluid">
    <div class="alert-success" *ngIf="false">
        <img style="margin-right: 1em;" src="../assets/images/checkCircle.svg" alt="successMsg">
        <span class="alert-close" >&times;</span>
        <span>{{successMessage}}</span>

    </div>
    <div class="alert-failure" *ngIf="false">
        <img style="margin-right: 1em;" src="../assets/images/errCircle.png" alt="failureMsg">
        <span class="alert-close">&times;</span>
        <span>{{failureMessage}}</span>
    </div>
    <div class="mt-3 m-4" >
        <mat-tab-group animationDuration="0ms" (selectedIndex)="selectedTabIndex"
            (selectedTabChange)="onTabChanged($event)">

            <form [formGroup]="adminForm">
                <mat-tab label="ISE" *ngIf="isAuthenticUser">
                    <div class="row subHeading m-3" style="font-style: italic;">
                        This tab is to mark a supplier as active/inactive
                    </div>
                    <div class="row pb-5 ">
                        <div class="col-md-6 ms-3 pl-3">
                            <div class="boxHeader">
                                <div class="d-flex justify-content-between p-1">
                                    <span class=" cursorptr ms-2 commonFont ">ISE </span>
                                    <span class="cursorptr me-2  commonFont" style="color:#2688DA" [ngClass]="{'noSuppliers': disableISEReportsLink}" (keyup)="toggle()" (click)="toggle()">Toggle ISE </span>
                                </div>
                                <div class="cursorptr ms-2 d-flex" >
                                    <input type="text" class="form-control addsearch search-tmna "
                                    placeholder="Search here" (keyup)="filteredISE()" [(ngModel)]="inputSearchISE" [ngModelOptions]="{standalone: true}">
                                    <img class="ms-1 searchimg" src="../assets/images/search.svg" alt="img"
                                    style="position: absolute;right: 10px;top: 36px;">

                                </div>

                            </div>

                            <div class="boxISE me-1" >
                                <option class="displayTextcss" *ngFor="let suppList of supplierList"
                                (click)="onItemSelection(suppList.description,suppList.appparentid,suppList.inactive)"
                                (keyup)="onItemSelection(suppList.description,suppList.appparentid,suppList.inactive)" 
                                [class.current-selection]="suppList.appparentid===apparentid">
                                {{suppList.description}} - <span *ngIf="suppList.inactive===false">Active</span> <span *ngIf="suppList.inactive!==false">Inactive</span>
                                
                                </option>
                            </div>
                        </div>

                    </div>
                    <div>

                    </div>

                </mat-tab>
                <mat-tab label="Standard IET" *ngIf="themeOwnerEligible">
                    <div class="row subHeading m-3" style="font-style: italic;">This tab is to add, modify, or delete standard IETs, and to copy
                        IETs from the previous year
                    </div>
                    <div class="d-flex flex-row m-3 pb-5">
                        <div class="col-md-6">
                            <div class="boxHeader commonFont">
                                <div class="d-flex ">
                                    <span class="ms-2 mt-2">STANDARD IETs </span>


                                </div>
                                <div class="p-2 cursorptr">
                                    <input type="text" class="form-control addsearch search-tmna "
                                        placeholder="Search here" [(ngModel)]="inputSearchStandardIET" [ngModelOptions]="{standalone: true}">
                                        <img class="ms-1" src="../assets/images/search.svg" alt="img" 
                                        style="position: absolute;right: 18px;top: 44px;">
                                </div>

                            </div>
                            <div class="boxISE me-1 p-2 mb-7 ">
                                <div class="accordion leftHeadBtn" id="accordionExample"
                                    *ngFor="let category of standardIETsAccordianValue;let i = index">
                                    <div class="tree" *ngIf="filteredStandardIETTitle.includes(category.title)">
                                        <div class="d-flex" [id]="'heading'+i">
                                            <button class="btn headButton" type="button" data-bs-toggle="collapse"
                                                (click)="category.toggle =!category.toggle ;
                                                showBulletValue(category.standardietid,category);"
                                                [attr.data-target]="'#collapse'+i" [attr.aria-controls]="'collapse'+i"
                                                aria-expanded="true" aria-controls="collapseOne">

                                                <img class="clpseBtn collapsesearchfilterBtn"
                                                    [ngClass]="{'active':category.toggle }" alt="img">
                                            </button>
                                            <span class="accordianTitle mt-1" (click)="selectedSubnode(category.title,'second');
                                                showBullet(category.standardietid,category);" (keyup)="selectedSubnode(category.title,'second');
                                                showBullet(category.standardietid,category);">
                                                 {{category.title}}</span>
                                        </div>
                                        <div [id]="'collapse'+i" class="collapse subBulletList show cursorptr"
                                            *ngFor="let item of category.content" data-bs-parent="#accordionExample"
                                            [ngClass]="{ 'show': category.toggle }"
                                            [attr.aria-labelledby]="'heading'+i" style="padding-left: 25px;">
                                            <div style="padding: 0; margin: 0" class="accordianTitle ms-5" style="font-size: 12px;color:grey; margin-top:10px;margin-bottom:10px;word-wrap: break-word;"
                                                (click)="selectedSubnode(item.bullet,'third');showIETBullet(item);" (keyup)="selectedSubnode(item.bullet,'third');
                                                showIETBullet(item);">
                                                {{parseHTMLContent(item.bullet)}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="accordion-wrapper  ">
                                <div class="accordion mb-3" id="homeAccordianOne">
                                    <div class="card">
                                        <div class="card-header">
                                            <div class="d-flex flex-row justify-content-between">
                                                <span class="commonFont mt-1">EDITING STANDARD IETs</span>
                                                <button class="btn headButton" type="button" data-bs-toggle="collapse"
                                                    id="accordion3Btn" accordianActive="true"
                                                    data-bs-target="#collapsesearchfilter" aria-expanded="true"
                                                    aria-controls="collapsesearchfilter" (click)="isOpen=!isOpen">

                                                    <img class="clpseBtn collapsesearchfilterBtn1"
                                                        [ngClass]="{'active':isOpen}" alt="img">
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="collapsesearchfilter" class="collapse show" aria-labelledby="headingOne"
                                    data-bs-parent="#proposalHist">
                                    <div class="cardBody">
                                        <div class="accordion-main p-2 mb-5">
                                            <mat-tab-group mat-stretch-tabs animationDuration="0ms"
                                                (selectedIndex)="selectedTabIndex"
                                                (selectedTabChange)="onTabChanged($event)">
                                                <mat-tab label="IET Properties">
                                                    <div class="row mt-3" *ngIf="showTab=='first'">
                                                        <div *ngIf="showTab =='first'" class="d-flex">
                                                            <label class="label-tmna mt-2 ms-2">Add IETs :
                                                                <input class="input-tmna ms-3" type="text" 
                                                                formControlName="iseTabNodeValueName"
                                                                    placeholder="Enter Name">
                                                            </label>

                                                            <button class="closeButton ms-3 mt-2"
                                                                style="width: fit-content; height: 35px;" (click)="addIET('standardIET')">ADD+</button>

                                                        </div>
                                                    </div>

                                                    <div class="row mt-3 ms-2" *ngIf="showTab=='second'">
                                                        <div class="d-flex flex-row">
                                                            <label for="title" class="label-tmna required">Title :</label>
                                                            <input class="input-tmna ms-2 "
                                                                type="text" style="width: 40%;"
                                                                formControlName="iseTabNodeValue">
                                                            <span class="errorMessage ms-2 me-2" *ngIf="this.showErrorMsg" style="min-height: 35px;">&nbsp;{{errorMsg}}</span>
                                                        </div>
                                                        <div class="label-tmna mt-2 pb-2 required">Body</div>
                                                        <div class=""> 
                                                            <quill-editor class="quillEditorField" [modules]="{toolbar: ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block', {'header': []}, {'align': []}, {'color': []}, {'background': []}, 'link']}" 
                                                                [styles]="{height: '100px'}" formControlName="isetabNodeExplanation"></quill-editor>
                                                        </div>
                                                        <div class="d-flex  mt-2"
                                                            style="text-decoration: underline;">
                                                            <a class="ms-2 cursorptr accordianTitle" (click)="saveIET()">Save</a>
                                                            <a class="ms-2 cursorptr accordianTitle" (click)="addBullet('standardIET')">Add Bullet</a>
                                                            <a class="ms-2 cursorptr accordianTitle" (click)="deleteIET()">Delete IET</a>
                                                            <a class="ms-2 cursorptr accordianTitle" (click)="cancelIET()">Cancel</a>
                                                        </div>

                                                    </div>
                                                    <div class="row mt-3" *ngIf="showTab=='third'">
                                                        <div class="label-tmna ms-3 mb-1 d-flex flex-row">
                                                            <span class="required mt-1">Bullet :</span>
                                                            <span class="errorMessage ms-2 me-2" *ngIf="this.showErrorMsg">&nbsp;{{errorMsg}}</span>
                                                        </div>
                                                        <div class="row p-2 mt-1 ms-1">                                                             
                                                            <quill-editor class="quillEditorField"  [modules]="{toolbar: ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block', {'header': []}, {'align': []}, {'color': []}, {'background': []}, 'link']}" 
                                                                [styles]="{height: '100px'}" formControlName="iseTabSubNodeValue"></quill-editor>
                                                        </div>
                                                        <div class="d-flex  mt-2"
                                                            style="text-decoration: underline;">
                                                            <a class="ms-2 accordianTitle" (click)="saveIETBullet()">Save</a>
                                                            <a class="ms-2 accordianTitle" (click)="deleteIETBullet()">Delete Bullet</a>
                                                            <a class="ms-2 accordianTitle" (click)="cancelIETBullet()">Cancel</a>
                                                        </div>
                                                    
                                                    </div>


                                                </mat-tab>

                                            </mat-tab-group>


                                        </div>

                                    </div>
                                </div>
                            </div>


                        </div>

                    </div>
                </mat-tab>
                <mat-tab label="General Priorities" *ngIf="themeOwnerEligible">
                   <div class="row subHeading m-3" style="font-style: italic;">
                    This tab is to add, modify, or delete standard IETs, and to copy IETs from the previous year
                </div>
                <div class="d-flex flex-row m-3 pb-5">
                    <div class="col-md-6">
                        <div class="boxHeader commonFont">
                            <div class="d-flex ">
                                <span class="ms-2 mt-2">GENERAL PRIORITIES</span>


                            </div>
                            <div class="p-2">
                                <input type="text" class="form-control addsearch search-tmna " 
                                    placeholder="Search here" [(ngModel)]="inputSearchGeneralPriorities" [ngModelOptions]="{standalone: true}">
                                    <img class="ms-1" src="../assets/images/search.svg" alt="img" 
                                    style="position: absolute;right: 18px;top: 44px;">
                            </div>

                        </div>
                        <div class="boxISE me-1 p-2 mb-7 ">
                            <div class="accordion leftHeadBtn" id="accordionExample"
                                *ngFor="let category of generalPrioritiesAccordianValue; let i = index">
                                <div class="tree" *ngIf="filteredGeneralPrioritiesTitle.includes(category.title)">
                                    <div class="d-flex" [id]="'heading'+i">

                                        <button class="btn headButton" type="button" data-bs-toggle="collapse"
                                        (click)="category.toggle =!category.toggle;showGeneralIETBulletValue(category.generalpriorityietid,category);"
                                            [attr.data-target]="'#collapse'+i" [attr.aria-controls]="'collapse'+i"
                                            aria-expanded="true" aria-controls="collapseOne">

                                            <img class="clpseBtn collapsesearchfilterBtn"
                                                [ngClass]="{'active':category.toggle }" alt="img">
                                        </button>
                                        <span class="accordianTitle mt-1" (click)="selectedSubnode(category.title,'second');
                                        showGeneralIETBullet(category.generalpriorityietid,category);" (keyup)="selectedSubnode(category.title,'second');
                                        showGeneralIETBullet(category.generalpriorityietid,category);"> {{category.title}}</span>
                                    </div>
                                    <div *ngFor="let item of category.content">
                                        <div [id]="'collapse'+i" class="collapse subBulletList show cursorptr"
                                        data-bs-parent="#accordionExample" *ngIf="item.title && item.title !== null"
                                        [ngClass]="{ 'show': category.toggle }"
                                        [attr.aria-labelledby]="'heading'+i" style="padding-left: 25px;">
                                            <div style="padding: 0; margin: 0" class="tree2 ms-5" style="font-size: 12px;color:grey; margin-top:10px;margin-bottom:10px;word-wrap: break-word;">
                                                <div class="bulletTitle" (click)="item.toggle=true;selectedSubnode(item.bullet,'third');showGeneralIETBulletList(item);showGeneralIETSubbullet(item.generalpriorityietbulletid);" (keyup)="item.toggle=true;selectedSubnode(item.bullet,'third');
                                                    showGeneralIETBulletList(item);showGeneralIETSubbullet(item.generalpriorityietbulletid);">
                                                    {{parseHTMLContent(item.title)}}        <!--Title is the bullet-->
                                                </div>
                                                <div *ngFor="let subbullet of generalPrioritiesSubbulletValue; let i = index;" class="collapse subbullets show" [ngClass]="{'show': item.toggle, 'collapse': !category.toggle}">
                                                    <div *ngIf="item.generalpriorityietbulletid === subbullet.bulletid">
                                                        <div class="bulletItem list" style="margin-left: 20px;"
                                                            (click)="selectedSubnode('subbullet.bullet','fourth');showGeneralIETSubbulletValue(subbullet);" (keyup)="selectedSubnode('subbullet.bullet','fourth');showGeneralIETSubbulletValue(subbullet);">
                                                            {{parseHTMLContent(subbullet.bullet)}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="accordion-wrapper  ">
                            <div class="accordion mb-3" id="homeAccordianOne">
                                <div class="card">
                                    <div class="card-header">
                                        <div class="d-flex flex-row justify-content-between">
                                            <span class="commonFont mt-1">EDITING GENERAL PRIORITIES</span>
                                            <button class="btn headButton" type="button" data-bs-toggle="collapse"
                                                id="accordion3Btn" accordianActive="true"
                                                data-bs-target="#collapsesearchfilter1" aria-expanded="true"
                                                aria-controls="collapsesearchfilter1" (click)="isOpen1=!isOpen1">

                                                <img class="clpseBtn collapsesearchfilterBtn1"
                                                    [ngClass]="{'active':isOpen1}" alt="img">
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="collapsesearchfilter1" class="collapse show" aria-labelledby="headingOne"
                                data-bs-parent="#proposalHist">
                                <div class="cardBody">
                                    <div class="accordion-main p-2 mb-5">
                                        <mat-tab-group mat-stretch-tabs animationDuration="0ms"
                                            (selectedIndex)="selectedTabIndex"
                                            (selectedTabChange)="onTabChanged($event, 'themeOwnerTabToggled')">
                                            <mat-tab label="General Priorities Properties">
                                                <div class="row mt-3" *ngIf="showTab=='first'">
                                                    <div *ngIf="showTab =='first'" class="d-flex">
                                                        <label class="label-tmna mt-2 ms-2">Add General IETs :
                                                            <input class="input-tmna ms-3" type="text" 
                                                            formControlName="iseTabNodeGeneralPrtyValueName"
                                                                placeholder="Enter Name">
                                                        </label>

                                                        <button class="closeButton ms-3 mt-2 cursorptr"
                                                            style="width: fit-content; height: 35px;" (click)="addIET('generalPriority')">ADD+</button>

                                                    </div>
                                                </div>

                                                <div class="row mt-3 ms-2" *ngIf="showTab=='second'">
                                                    <div class="d-flex flex-row" *ngIf="isAuthenticUser">
                                                        <label for="order" class="label-tmna" style="min-width: 100px;">Display Order :</label>
                                                        <input class="input-tmna ms-2" type="number" onkeydown="return event.keyCode !== 190" style="width: 20%;" formControlName="iseTabNodeGeneralPrtyOrderValue">
                                                        <span class="errorMessage ms-2 me-2" *ngIf="this.showErrorMsg" style="min-height: 35px;">&nbsp;{{errorMsg}}</span>
                                                    </div>
                                                    <div class="d-flex flex-row mt-2">
                                                        <label for="title" class="label-tmna required" style="min-width: 100px;">Title :</label>
                                                        <input class="input-tmna ms-2"
                                                            type="text" style="width: 40%;"
                                                            formControlName="iseTabNodeGeneralPrtyValue">
                                                       </div>
                                                    <div class="label-tmna mt-2 pb-2 required">Body</div>
                                                    <div class=""> 
                                                        <quill-editor class="quillEditorField"  [modules]="{toolbar: ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block', {'header': []}, {'align': []}, {'color': []}, {'background': []}, 'link']}" 
                                                            [styles]="{height: '100px'}" formControlName="isetabNodeGeneralPrtyExplanation"></quill-editor>
                                                    </div>
                                                    <div class="d-flex  mt-2"
                                                        style="text-decoration: underline;">
                                                        <a class="ms-2 cursorptr accordianTitle" (click)="saveGeneralIET()">Save</a>
                                                        <a class="ms-2 cursorptr accordianTitle" (click)="addBullet('generalPriority')">Add Bullet</a>
                                                        <a class="ms-2 cursorptr accordianTitle" (click)="deleteGeneralIET()">Delete IET</a>
                                                        <a class="ms-2 cursorptr accordianTitle" (click)="cancelGeneralIET()">Cancel</a>
                                                    </div>

                                                </div>

                                                <div class="row mt-3 ms-2" *ngIf="showTab=='third'">
                                                    <div class="label-tmna mb-1 d-flex flex-row">
                                                        <span class="required mt-1">Bullet :</span>
                                                        <span class="errorMessage ms-2 me-2" *ngIf="this.showErrorMsg">&nbsp;{{errorMsg}}</span>
                                                    </div>
                                                    <div class="mt-1">
                                                        <quill-editor class="quillEditorField"  [modules]="{toolbar: ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block', {'header': []}, {'align': []}, {'color': []}, {'background': []}, 'link']}" 
                                                        [styles]="{height: '100px'}"formControlName="iseTabNodeGeneralPrtyBulletTitle"></quill-editor>  
                                                    </div>
                                                    <div class="d-flex  mt-2"
                                                        style="text-decoration: underline;">
                                                        <a class="ms-2 cursorptr accordianTitle" (click)="saveGeneralIETBullet()">Save</a>
                                                        <a class="ms-2 cursorptr accordianTitle" (click)="deleteGeneralIETBullet()">Delete Bullet</a>
                                                        <a class="ms-2 cursorptr accordianTitle" (click)="addGeneralPrioritySubBullet()">Add Sub-bullet</a>
                                                        <a class="ms-2 cursorptr accordianTitle" (click)="cancelGeneralIETBullet()">Cancel</a>
                                                    </div>
                                                
                                                </div>

                                                <div class="row mt-3 ms-2" *ngIf="showTab=='fourth'">
                                                    <div class="label-tmna mb-1 d-flex flex-row">
                                                        <span class="mt-1">Sub-bullet :</span>
                                                        <span class="errorMessage ms-2 me-2" *ngIf="this.showErrorMsg">&nbsp;{{errorMsg}}</span>
                                                    </div>    
                                                    <div>                                                    
                                                        <quill-editor class="quillEditorField"  [modules]="{toolbar: ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block', {'header': []}, {'align': []}, {'color': []}, {'background': []}, 'link']}" 
                                                            [styles]="{height: '100px'}" formControlName="iseTabSubNodeGeneralPrtyValue"></quill-editor>
                                                    </div>
                                                    <div class="d-flex  mt-2"
                                                        style="text-decoration: underline;">
                                                        <a class="ms-2 cursorptr accordianTitle" (click)="saveSubBullet()">Save</a>
                                                        <a class="ms-2 cursorptr accordianTitle" (click)="deleteGeneralIETSubbullet()">Delete Sub-bullet</a>
                                                        <a class="ms-2 cursorptr accordianTitle" (click)="cancelGeneralIETSubbullet()">Cancel</a>
                                                    </div>
                                                
                                                </div>


                                            </mat-tab>

                                            <mat-tab label="User Permission" *ngIf="isAuthenticUser">
                                                <div class="row mt-2 mb-3">
                                                    <mat-tab-group mat-stretch-tabs animationDuration="0ms"
                                                        (selectedIndex)="selectedTabIndex"
                                                        (selectedTabChange)="onTabChanged($event)">
                                                        <mat-tab label="Theme Owners" >
                                                            <div class="ms-3 mt-2 me-4">
                                                                <button class="closeButton allNoneBtn mb-2"  style="float: inline-start" (click)="allNoneThemeOwners()" (keyup)="allNoneThemeOwners()" [ngClass]="{'noSuppliers': disableThemeOwnerButtons}">ALL/NONE</button>
                                                                <button class="closeButton refreshBtn" style="float: inline-end" (click)="this.showThemeOwners()" (keyup)="this.showThemeOwners()" [ngClass]="{'noSuppliers': disableThemeOwnerButtons}">REFRESH</button>
                                                            </div>
                                                            <div class="ms-3">
                                                                <div class="boxHeader" style="height: 56px;">
                                                                    <div class="cursorptr m-2 d-flex" >
                                                                        <input type="text" class="form-control addsearch search-tmna "
                                                                        placeholder="Search here" [(ngModel)]="searchName" [ngModelOptions]="{standalone: true}">
                                                                        <img class="ms-1 searchimg" src="../assets/images/search.svg" alt="img"
                                                                        style="position: absolute;right: 17px;top: 11px;">
                                    
                                                                    </div>
                                    
                                                                </div>
                                                                <div class="boxISE col">
                                                                    <div class="m-3" style="height: 200px; min-height: auto;">
                                                                        <div *ngIf="themeOwnersList.length > 0">
                                                                            <ul style="padding-left: 1rem;">
                                                                                <li class="label-tmna" *ngFor="let item of themeOwnersList; let i = index" style="list-style: none;">
                                                                                    <div class="d-flex" style="gap: 20px; padding-bottom: 20px;" 
                                                                                     *ngIf="filteredFullName.includes(themeOwnerfullname[i])">
                                                                                     <input type="checkbox" [checked]="item.is_theme_owner" style="box-shadow: none;"
                                                                                      (click)="onCheckboxUpdate(item.email, !item.is_theme_owner)" (keyup)="onCheckboxUpdate(item.email, !item.is_theme_owner)"/>
                                                                                     <span class="label-tmna" style="color: #161718;">
                                                                                         {{themeOwnerfullname[i]}}
                                                                                     </span>
                                                                                    </div>
                                                                                 </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="mt-2">
                                                                    <button class="closeButton applyPermissionBtn" style="float: inline-start;" (click)="applyPermissions()" (keyup)="applyPermissions()" [ngClass]="{'noSuppliers': disableThemeOwnerButtons}">APPLY ROLE</button>
                                                                </div>
                                                            </div>
                                                        </mat-tab>
                                                    </mat-tab-group>
                                                </div>
                                            </mat-tab>
                                        </mat-tab-group>


                                    </div>

                                </div>
                            </div>
                        </div>


                    </div>

                </div>
                </mat-tab>
                <mat-tab label="Supplier Category" *ngIf="isAuthenticUser">
                    <app-supplier-category *ngIf="callSupplierCategoryComponent"></app-supplier-category>
                </mat-tab>
                <mat-tab label="Supplier Flag" *ngIf="isAuthenticUser">
                    <app-supplier-flag *ngIf="callSupplierFlagComponent"></app-supplier-flag>
                </mat-tab>
                <mat-tab label="Apply Standard IET to Supplier" *ngIf="isAuthenticUser">
                    <app-apply-standardiet-supplier *ngIf="callApplyStandardIetSupplierComponent"></app-apply-standardiet-supplier>
                </mat-tab>
                <mat-tab label="Emailer" *ngIf="isAuthenticUser">
                    <app-emailer *ngIf="callEmailerComponent"></app-emailer>
                </mat-tab>
                <mat-tab label="Fiscal Year" *ngIf="isAuthenticUser">
                    <app-fiscal-year *ngIf="callFiscalYearComponent"></app-fiscal-year>
                </mat-tab>
                <mat-tab label="Uploader" *ngIf="isAuthenticUser">
                    <app-uploader *ngIf="callUploaderComponent"></app-uploader>
                </mat-tab>
            </form>

        </mat-tab-group>

    </div>
    <div *ngIf="false">
        <div class=" container unauthorized"  >
          <p></p>
          <p style="color: red;">You do not have a role assigned to access this Application.</p>
          <p>Please raise a request using 
            <a style="color: #2688Da; text-decoration:underline" href="https://ep.idm.toyota.com/identityiq/home.jsf" >SailPoint</a>.
            Refer to the <span style="color: #2688Da; text-decoration:underline; cursor:pointer">User Guide</span>
            for more details.
          </p>
        </div>
      </div>


</div>