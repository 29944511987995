<nav class="navbar navbar-expand-lg navbar-light bg-light nav-custom sticky-top" *ngIf="isDisplay">
    <div class="container-fluid">
      <a class="navbar-brand" href="#"><img
        src="../assets/images/MainLogo.svg"
        class="img-fluid rounded-top"
        alt="app"
      />
      </a>
      
      <a class="navbar-brand app-name" href="#">Annual Purchasing Policy </a>
      
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav" style="z-index: 1000 !important; margin-bottom: -10px !important;">
          <li class="nav-item1 ">
            <a routerLink="/home" routerLinkActive="active" class="nav-link app-title  "
            > Home  </a>
          </li>
          <li class="nav-item1" *ngIf="adminAcess">
            <a routerLink="/admin" routerLinkActive="active"  class="nav-link app-title"
            > Admin </a>
          </li> 
          <li class="nav-item1">
            <a routerLink="/packetSent" routerLinkActive="active" class="nav-link app-title"
            >Packets Sent </a>
          </li>
          <li class="nav-item1">
            <a routerLink="/reports" routerLinkActive="active" class="nav-link app-title"
            >Reports </a>
          </li>
        </ul>
        <ul class="nav navbar-nav ms-auto ">
          <img
            src="../assets/images/person-circle.svg"
            class="img-fluid rounded-top p-3" style="height: fit-content; width: 50px;"
            alt=""
          />
          
          <li class=" nav-item cursorptr" data-bs-toggle="dropdown">
            <div class="loginname">
             <div> <strong>{{loginUserName}}</strong> <br /></div>
             <div> <span class="role">{{loginUserRole}}</span></div>
             <div class="limitChar"> <span *ngIf="assumed" > <b>Assumed As: {{selectedAssume}} </b></span></div>
            </div>
          </li>
          <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle cursorptr" data-bs-toggle="dropdown">
                <div class="dropdown-menu dropdown-menu-end" style="height: fit-content;">
                  

                  <div class="dropdown-item firstDiv inl-paddingL-nil">
                    <span class="name">
                        <span class="inl-paddingL-val"><img src="assets/images/user-solid.svg"
                                alt="download" /></span>
                        <span class="inl-padding-txt"><strong>{{loginUserName}}</strong>
                        </span>
                    </span>
                    <span class="role">
                    <span class="role-type">{{loginUserRole}}</span>
                    </span>
                    <div class="assume-identity cursorptr">
                        <a data-bs-toggle="modal" data-bs-target="#newgroupingModal"
                        data-bs-backdrop="static"  (click)="callAssumeIdentity()"
                        >
                                Assume Identity
                        </a>
                    </div>
                    <div *ngIf="assumed" class="cancel-identity cursorptr" (click)="cancelassume()" (keyup)="cancelassume()">
                      <a>
                              Cancel Assume
                      </a>
                      </div>
                     <div *ngIf="assumed" class="assume-identity-text">
                         <span>Assumed Identity: {{selectedAssume}}</span>
                      </div>
                </div>

                  <a style="padding-top:12px;" (click)="reportIssue()" class="dropdown-item cursorptr" [attr.href]="reportIssueUrl" target="_blank" rel="noopener">REPORT AN ISSUE</a>
                  <div class="dropdown-divider"></div>
                  <a  class="dropdown-item cursorptr" (click)="logout()">LOGOUT</a>
              </div>
              </a>
              
          </li>
      </ul>
      </div>
    </div>
  </nav>

  <div id="newgroupingModal" class="modal right col-sm-6" style="height: auto !important;" tabindex="-1" 
aria-labelledby="exampleModalPreviewLabel" aria-hidden="true">
<div class="modal-dialog modal-lg modal-dialog-centered inl-modal-width" >
    <div class="modal-content" style="width: 70% !important;">
        <button type="button" class="close" data-bs-dismiss="modal" (click)="closeDialog()" > 
          <img src="\assets\images\close-icon.png" alt="Close Modal" height="15" width="15" >
        </button>

        <div class="modal-header" id="addModalHeader"
            style=" display: flex; justify-content: space-between;align-items:end  ">
            
            <h4 class="modal-title inl-modal-padding modalHeading" id="update_supplier_modal">Assume Identity
            </h4>

        </div>
        
    

        <div class="modal-body3" id="addModalBody" style="overflow-y: hidden;">

            <div class="assumecontainer mt-4 col-sm-14 ">
                <br>
                <input placeholder="Type here to search" #searchBox [(ngModel)]="inputAssume" id="assumeSearch" (keyup)="search(inputAssume)" />
                <div class="assumediv cursorptr">
                 <option *ngFor="let assume of assumeIdentity"
                 [class.assume-selection]="assume.pdkusername===assumeSelect"
                 (click)="selectAssume(assume.pdkusername,assume.employeeids,assume)"
                 (keyup)="selectAssume(assume.pdkusername,assume.employeeids,assume)"
                 style="margin-top:8px;margin-bottom:8px;"
                 >
                  <span class="badge" >{{assume.pdkusername}} 
                   </span>
                  </option>
                </div>
            </div>
        </div>
        <div class="modal-footer" style="height:60px;padding-left: 0%; padding-right:0%;padding-bottom: 0%;width: 50%,  !important;margin-top: 10px;">
            <div class="col-xl-12 d-flex"  >
              <div class="cancelbtn" data-bs-dismiss="modal" (click)="closeDialog()" 
              (keyup)="closeDialog()">CANCEL</div>
                <button id="next_poping"  class="btn mb-2 mr-sm-2"
                       (click)="assume()" data-bs-dismiss="modal"
                    data-bs-backdrop="static" [disabled]="!assumeSelect" >ASSUME</button>

            </div>
        </div>

    </div>
</div>
</div>