import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrl: './breadcrumb.component.css'
})
export class BreadcrumbComponent implements OnInit {
  @Input() breadcrumbitems!:string[];

  menuHeader :any=[];
  constructor(private readonly router:Router, private readonly ngxSpinner: NgxSpinnerService){

  }
  ngOnInit(): void {
    this.menuHeader=this.breadcrumbitems;
  }
 
  breadCrumb(menu:any,index:any){
    this.ngxSpinner.show();
    if(menu[index]?.link !==null){
      this.router.navigate([menu[index]?.link]);
    }
    setTimeout(()=> {
      this.ngxSpinner.hide();
    },1000);

  }

}
