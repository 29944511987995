import { Component } from '@angular/core';
import { DisplayNotificationService } from 'src/app/services/display-notification.service';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrl: './message.component.css'
})
export class MessageComponent {
  message = ''
  inprogressMsg = '';

  constructor(private readonly displayNotification:DisplayNotificationService) {}

  ngOnInit() {
    this.displayNotification.message$.subscribe(message => {
      this.message = message;
    })
    this.displayNotification.inprogress$.subscribe((status)=>{
      this.inprogressMsg = status;

      if(status === 'completed' || status === 'failure') {
        window.scroll(0,0);
      }
    })
  }
}
