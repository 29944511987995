import { Component, HostListener } from '@angular/core';
import { GridApi } from 'ag-grid-community';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { LookupService } from '../services/lookup.service';
import { ManagementService } from '../services/management.service';
import { Router } from '@angular/router';
import { BusinessEntityService } from '../services/business-entity.service';
import { Location } from '@angular/common';
import { distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'app-supplier-details',
  templateUrl: './supplier-details.component.html',
  styleUrl: './supplier-details.component.css'
})
export class SupplierDetailsComponent {
  commodityForm:FormGroup;
  isOpen1=true;
  isOpen2=true;
  gridApi:GridApi;
  gridColumnApi:any;
  domLayout='autoHeight';
  disableform:any;
  isManager = false;
  isAdmin = false;
  isBuyer = false;
  disableSaveButton: boolean;
  appParentId: any;
  isSelectedSupplierInOpenStatus: boolean;
  approvalStatusRequestBody: any;

  constructor(private readonly ngxSpinner:NgxSpinnerService,private readonly formBuilder:FormBuilder
    ,private readonly lookup:LookupService, private readonly management:ManagementService,
    private readonly router: Router, private readonly bService: BusinessEntityService,
    private readonly location: Location
    ){}
 ngOnInit():void{
  this.ngxSpinner.show();
  this.commodityForm=this.formBuilder.group({
    targetOE:new FormControl(''),
    targetService:new FormControl(''),
    goalPrototype:new FormControl(''),
    deliveryKPM:new FormControl(''),
    warrantyTarget:new FormControl(''),
  })
  this.disableform=false;
  this.disableSaveButton = false;

  this.lookup.localStorageData.pipe(distinctUntilChanged()).subscribe({
    next: (data)=>{
      if(data?.includes('success')){
        if(this.bService.loggedUserRole==='PurappsAPPAdmin'){
          this.isAdmin = true;
        }else if(this.bService.loggedUserRole==='PurappsBuyer'){
          this.isBuyer = true;
        }else if(this.bService.loggedUserRole==='PurappsManager'){
          this.isManager = true;
        }
        this.getCommodities();
        this.disableCommodity();
        this.appParentId = this.bService.appParentId;
      }
    }
  })
  this.isSelectedSupplierInOpenStatus = localStorage.getItem("isSupplierInOpenStatus") === "true";

  setTimeout(()=>{
    this.ngxSpinner.hide();
  },2000);
 }

 disableCommodity(){
  if(sessionStorage.getItem("supplierapproved")==="true" && this.isAdmin) {
    this.disableform=true;
    this.disableSaveButton = true;
    this.commodityForm.controls["targetOE"].disable();
    this.commodityForm.controls["targetService"].disable();
    this.commodityForm.controls["goalPrototype"].disable();
  } else if (sessionStorage.getItem("supplierapproved")==="true" && !this.isAdmin) {
    this.disableform=true;
    this.disableSaveButton = true;
    this.commodityForm.controls["targetOE"].disable();
    this.commodityForm.controls["targetService"].disable();
    this.commodityForm.controls["goalPrototype"].disable();
    this.commodityForm.controls["deliveryKPM"].disable();
    this.commodityForm.controls["warrantyTarget"].disable();
  } else if(!this.isAdmin){
    this.disableform=true;
    this.commodityForm.controls["targetOE"].disable();
    this.commodityForm.controls["targetService"].disable();
    this.commodityForm.controls["goalPrototype"].disable();
    this.commodityForm.controls["deliveryKPM"].disable();
    this.commodityForm.controls["warrantyTarget"].disable();
  }
 }
  
  breadcrumbItems=[
    {
      label: 'Home',
      link: '/home',
      icon: '>'
    },
    {
      label: 'View/edit ISE',
      link: '/ise',
      icon: '>'
    },
    {
      label: sessionStorage.getItem("suppliercode"),
      link: null,
      icon: ''
    },
  ];

  defaultColDef={ 
    suppressNavigable: true,
    cellClass: 'no-border',
    suppressMovable: true,
    sortable: false,
    unSortIcon: true,
    wrapText: true,
    autoHeight: true,
    resizable: true,
    suppressHeaderMenuButton: true,
    suppressFloatingFilterButton: true,
   };

   columnDefCommodities=[
    {
      headerName: 'Code',
      field: 'commoditycode',
      flex: 1
    },
    {
      headerName: 'Description',
      field: 'commoditydescription',
      flex: 1
    },
    {
      headerName: 'Exclude',
      field: 'inactive',
      flex: 1,
      cellRenderer: (params) => {
        const checkbox = document.createElement('input');
        checkbox.type = 'checkbox';
        checkbox.checked = params.value;
        checkbox.addEventListener('change', (event) => {
          const isChecked = (event.target as HTMLInputElement).checked;
          params.setValue(isChecked);
        });
        for(const element of this.commodities){
          if(element.code===params.data.commoditycode){
            element.exclude=params.value;
          }
        }
        return checkbox;
      }
    }
  ];

  rowDataCommodities=[];
  qualityCommodityBody:any;
  commodities:any=[];
  
  getCommodities(){
    this.lookup.getQualityCommodity(sessionStorage.getItem("appId"),sessionStorage.getItem("appParentId"),
    localStorage.getItem("fiscalId"), this.bService.loggedUserEmployeeId).subscribe(data=>{
      this.commodityForm.controls['targetOE'].setValue(data.body.qualityTarget[0].oedefecttarget);
      this.commodityForm.controls['targetService'].setValue(data.body.qualityTarget[0].servicedefecttarget);
      this.commodityForm.controls['goalPrototype'].setValue(data.body.qualityTarget[0].prototypedefecttarget);
      this.commodityForm.controls['deliveryKPM'].setValue(data.body.qualityTarget[0].deliverykpm);
      this.commodityForm.controls['warrantyTarget'].setValue(this.formatWarrantyValue(data.body.qualityTarget[0].warrantytarget));
      this.rowDataCommodities=data.body.commodity;
      for(const element of this.rowDataCommodities){
        const commodity = {}
        commodity['commodityId'] = element.appxcommodityid;
        commodity['code'] = element.commoditycode;
        commodity['description']=element.commoditydescription;
        commodity['exclude']=element.inactive;
        this.commodities.push(commodity)
      } 
    })
  }

  formatWarrantyValue(value): string | number {
    if(!isNaN(Number(value))) {
      return Math.round(Number(value)).toString()
    }
    return value
  }

  saveCommodity(){
    this.ngxSpinner.show();
    this.qualityCommodityBody={
      "appId": sessionStorage.getItem("appId"),
      "appParentId": sessionStorage.getItem("appParentId"),
      "fiscalId": localStorage.getItem("fiscalId"),
      "empId":  this.bService.loggedUserEmployeeId,
      "oeDefect": this.commodityForm.controls['targetOE'].value,
      "serviceDefect": this.commodityForm.controls['targetService'].value,
      "prototypeDefect": this.commodityForm.controls['goalPrototype'].value,
      "deliveryKpm": this.commodityForm.controls['deliveryKPM'].value,
      "warrantyTarget": this.formatWarrantyValue(this.commodityForm.controls['warrantyTarget'].value),
      "commodities": this.commodities
    }
    this.management.qualityCommodity(this.qualityCommodityBody).subscribe(_data=>{
      if(this.isSelectedSupplierInOpenStatus) {
        this.approvalStatusRequestBody = {
          appParentId: sessionStorage.getItem("appParentId"),
          fiscalId: localStorage.getItem("fiscalId"),
          saveFlag: true,
          readyForReviewFlag: false,
          backToBuyerFlag: false,
          comment: '',
          empId: this.bService.loggedUserEmployeeId
        }
        this.management.approvalStatus(this.approvalStatusRequestBody).subscribe(_data => {
          this.router.navigate(['/home']);
        });
      } else {
        this.router.navigate(['/home']);
      }
    })
  }

  cancelCommodity(){
    this.ngxSpinner.show();
    this.location.back();
  }
  onGridReady(params:any){
    this.gridApi = params?.api;
    this.gridColumnApi = params?.columnApi;
    this.gridApi?.sizeColumnsToFit(); 
  }
  @HostListener("window:resize", ['$event'])
  onResize(_event) {

    if (this.gridApi != null) {

      this.gridApi?.sizeColumnsToFit();
      this.gridApi.refreshCells();
    }

  }

  
}
