
export const environment = {
    envName: 'prod',
    production: true,
    hostname: 'app.toyota.com',
    apiBaseUrl: 'https://app.toyota.com',
    logger: {
      level: 'INFO'
    },
    xApiKey: '',
    TENANT_ID: 'https://login.microsoftonline.com/8c642d1d-d709-47b0-ab10-080af10798fb',
    CLIENT_ID: '359510b2-bd5e-4e41-a1e4-ea4ec304aabd',
    REDIRECT_URL: 'https://app.toyota.com',
    apiUrl: {
        lookupUrl: 'https://t5ik2lttal.execute-api.us-east-1.amazonaws.com/prod/lookup/',
        adminUrl:  'https://t5ik2lttal.execute-api.us-east-1.amazonaws.com/prod/admin/',
        suppliermanagUrl: 'https://t5ik2lttal.execute-api.us-east-1.amazonaws.com/prod/management/',
        acknowledgementUrl: 'https://t5ik2lttal.execute-api.us-east-1.amazonaws.com/prod/acknowledgement/'
    }
  };
  