import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-supplier-code-renderer',
  templateUrl: './supplier-code-renderer.component.html',
  styleUrl: './supplier-code-renderer.component.css'
})
export class SupplierCodeRendererComponent implements ICellRendererAngularComp{
  
  refresh():boolean{
    return false;
  }

  constructor(private readonly router: Router, private readonly ngxSpinner: NgxSpinnerService){}

  public params: any;

  agInit(params: any): void {
    this.params = params;
  }

  onClick(_event) {
    this.ngxSpinner.show();
    sessionStorage.setItem("fiscalId",this.params.data.fiscalId);
    sessionStorage.setItem("appId",this.params.data.appId);
    sessionStorage.setItem("appParentId",this.params.data.appParentId);
    sessionStorage.setItem("suppliercode",this.params.data.suppliercode);
    this.router.navigate(['/supplierDetails']);
  }
}
