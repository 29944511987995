<div class="alert-failure" *ngIf="showPacketErrorMsg">
    <img style="margin-right: 1em;" src="../assets/images/errCircle.png" alt="failureMsg">
    
    <span>{{showErrorMessage}}</span>
</div>

<div class="container-fluid">
    <div class="m-3">
        <label class="label-tmna mt-1 me-4">Current Fiscal Year :
            <select class="dropdown-tmna ms-1" (change)="changefy($event.target.value,1)">
                <option class="displayTextcss" *ngFor="let fyear of fiscalYear" [value]="fyear.fiscalyearid" >
                    {{fyear.year}}
                </option>
            </select></label>
    
        <ag-grid-angular #agGrid class="ag-theme-balham " style="margin-top:15px;padding-bottom:50px;"
            [rowData]="rowData" [columnDefs]="columnDef"
            (gridReady)="onGridReady($event)" headerHeight="50" [defaultColDef]="defaultColDef"
            [domLayout]="domLayout" [icons]="icons" [rowGroupPanelShow]="'always'"
            [suppressDragLeaveHidesColumns]="true">
    
        </ag-grid-angular>
    </div>
</div>