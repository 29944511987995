<div class="row mt-3 mb-2">
    <div class="d-flex ms-2">
        <label class="label-tmna mt-1 me-4" style="font-weight: bold;">Current Fiscal Year :
            <input type="text" disabled class="input-tmna ms-2" placeholder="{{this.currentFiscalYear}}"
                style="width: 70px;"></label>
    </div>
    
    <a class="ms-2 cursorptr" (click)="showFiscalYearStatusModel()" style="font-size:small" [ngClass]="{'checkStatusLink': !enableCheckStatus}">Check Status</a>
    <p *ngIf="!executeFiscalYearBtn" class="label-tmna mt-1 me-4 ms-3">Please navigate to <span style="font-weight:bold;">"Copy IETs from Previous Year"</span> tab for the next steps.</p>
</div>
<div class="row mb-3"
    style="height: fit-content;">
    <p class="subHeading ms-2 text-wrap"style="font-weight: 600; color: #212529;">Please follow the below steps to create and copy current Fiscal year data to new Fiscal year: </p>
    <p class="label-tmna ms-2 text-wrap steps"> Step 1: <button [disabled]="createNewFiscalYearBtn" class="closeButton ms-3 fiscalYearBtn" style="width: fit-content;float: none " (click)="changeFiscalYear()">Create New Fiscal Year</button></p>
    
  <div class="ms-5">

    
    <p class="label-tmna ms-4 text-wrap">This action will change the current APP fiscal
        year to a new year({{this.newFiscalYear}}).</p>
  </div>
  <p class="label-tmna ms-2 text-wrap steps"> Step 2: <button [disabled]="populateSuppFiscalYearBtn" class="closeButton ms-3 fiscalYearBtn" disabled style="width: fit-content;float: none " (click)="fiscalYearCopyData('POPULATE')">POPULATE SUPPLIER LIST</button></p>
  
  <div class="ms-5">

    
    <p class="label-tmna ms-4 text-wrap">This can be ran after the APP fiscal
        year is switched to a new year. It populates the supplier
        list for the new year. This will also copy the APP flags
        from the previous year. The standard IETs will also be
        copied</p>
  </div>
  <p class="label-tmna ms-2 text-wrap steps"> Step 3: <span class="label-tmna ms-3">
    This is the process that will
    copy over IETs and IET Bullets from last year.</span></p>
  <div class="row flex-row mb-3 ms-5">
    <div class="col-md-6 d-flex">
        <label class="label-tmna ms-3 pe-5"> Previous Year:
            <input type="text" disabled class="input-tmna ms-2" placeholder="{{this.currentFiscalYear-1}}"
                style="width: 70px;">
        </label>

        <label class="label-tmna me-1"> Current Year: 
            <input type="text" disabled class="input-tmna ms-2" placeholder="{{this.currentFiscalYear}}"
                style="width: 70px;">
        </label>

    </div>
    
</div>
<div class="col-md-6 d-flex ms-5">
    <div style="margin-left: 7px;">
        <button [disabled]="executeFiscalYearBtn" class="closeButton ms-3 fiscalYearBtn" disabled style="width: fit-content;float: none " (click)="fiscalYearCopyData('EXECUTE')">COPY IETs</button>
    </div>
    
</div>
<div class="row subHeading m-3 ms-5" style="font-style: italic;padding-left: 3%;">
    Note: IETs include Open, closed, Pending and In-Progress
</div>

  <p class="label-tmna ms-2 text-wrap steps"> Step 4: <button [disabled]="updateLeadBuyerFiscalYearBtn" class="closeButton ms-3  fiscalYearBtn" disabled style="width: fit-content;float: none " (click)="fiscalYearCopyData('UPDATELEADBUYER')">UPDATE APP LEAD BUYER</button></p>
  
  <p class="label-tmna ms-2 text-wrap ms-5"><span class="ms-4">This will import and  update the lead buyers from previous year.</span></p>

  <p class="label-tmna ms-2 text-wrap steps"> Step 5: <input type="text" class="input-tmna ms-3" placeholder="Update APP Reference Document Path" #appreferencelink style="width: 20%;"> <button class="closeButton ms-4  fiscalYearBtn" style="width: fit-content;float: none " (click)="updateAppRefLink(appreferencelink.value)">UPDATE</button></p>
  
  <p class="label-tmna ms-2 text-wrap ms-5"><span class="ms-4">This will upate the APP Refernce document for the current fiscal year({{this.currentFiscalYear}}).</span></p>
  
    <div class="ms-4 mb-2 "><a class="homePageLinks cursorptr ms-5 " [attr.href]="appRefDocLink"
    id="appRefDoc" target="_blank" style="font-size: 12px; text-decoration: none;">APP Reference Documents</a></div>

  <p class="label-tmna ms-2 text-wrap steps"> Step 6: <input class="input-tmna ms-3" value={{this.currentVACostTarget}} placeholder="Update VA Cost Reduction Target" #vaconstreductiontarget style="width: 20%;" min="0" max="100" step="0.1"  id="myInput" type="number" ><button class="closeButton ms-4  fiscalYearBtn" style="width: fit-content;float: none " (click)="updateVACostTarget(vaconstreductiontarget.value)">UPDATE</button></p>
  <p class="label-tmna ms-2 text-wrap ms-5 mb-5"><span class="ms-4">This will upate the APP VA Cost Reduction Target for the current fiscal year({{this.currentFiscalYear}}).</span></p>
  
</div>

<div class="modal" tabindex="-1" *ngIf="showModel" [ngStyle]="{'display': showModel ? 'block':'none'}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <button type="button" class="close" data-bs-dismiss="modal" (click)="closeFiscalYearStatusModel()"> 
                <img src="\assets\images\close-icon.png" alt="Close Modal" height="15" width="15" >
            </button>
      
            <div class="modal-header" id="addModalHeader"
                style=" display: flex; justify-content: space-between;align-items:end  ">
                
                <h4 class="modal-title inl-modal-padding modalHeading">Fiscal Year Status:
                </h4>
    
            </div>
            <div class="modal-body">
                <div class="d-flex m-1 pb-1">
                    <div class="">
                        <p for="text" class="label-tmna" > New fiscal year created by <strong>{{fiscalyearCreatedby}}</strong> on <strong>{{fiscalyearCreatedon}}</strong>.</p>
                        <p for="text" class="label-tmna" > Current Status: <strong>{{fiscalyearStatus}}</strong></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>