import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { lastValueFrom } from 'rxjs';
import { DisplayNotificationService } from 'src/app/services/display-notification.service';
import { DownloadTemplateService } from 'src/app/services/download-template.service';
import { LookupService } from 'src/app/services/lookup.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-uploader',
  templateUrl: './uploader.component.html',
  styleUrl: './uploader.component.css'
})
export class UploaderComponent implements OnInit{

  filenameone:any;
  filenametwo:any;
  inputone:any;
  inputtwo:any;
  filename: string;
  base64String: string;
  appparentid: number;
  fiscalyearid: number;
  OEJsonData: any[] = [];
  Tier2JsonData: any[] = [];
  tier2RequestBody: {};
  oeRequestBody: {};
  uploadOEReportRequest: {};
  preSignedUrl: any;
  showSuccessMsgTier2Results = false;
  showSuccessMsgOEReport = false;
  showErrorMsgTier2Results = false;
  showErrorMsgOEReport = false;
  showFileFormatError = false;
  employeeid: any;
  showInprogressMsgOEReport = false;
  uploadOEReportFile: File;
  tierIIReportFile: File;
  uuid: string;
  lastOEUploadUUID: string;
  inprogressMsg: string;

  constructor(private readonly downloadTemplate: DownloadTemplateService, private readonly lookup: LookupService, private readonly http:HttpClient, private readonly ngxSpinner: NgxSpinnerService,
    private readonly displayNotification:DisplayNotificationService){}

  ngOnInit(): void {
    this.ngxSpinner.show()
    this.appparentid = Number(sessionStorage.getItem("appParentId"))
    this.fiscalyearid = Number(localStorage.getItem("fiscalId"))
    const assumedUserPresent=JSON.parse(localStorage.getItem('userAssumed'));
    this.employeeid=(assumedUserPresent!=="undefined"&&assumedUserPresent!==null)?assumedUserPresent.employeeids:sessionStorage.getItem("loginAssumeEmployeeid");
    this.lastOEUploadUUID = localStorage.getItem("uuid")
    this.displayNotification.inprogress$.subscribe((status)=>{
      this.inprogressMsg = status
    })
    this.displayNotification.restoreTracking()
    setTimeout(()=> {
      this.ngxSpinner.hide()
    },1000)
  }

  async downloadTemplateFile(fileName) {
    this.ngxSpinner.show();
    try {
      this.base64String = this.filename = '';
      if(fileName === 'tier2') {
        const data = await lastValueFrom(this.lookup.downloadTierIITemplate(this.employeeid,this.fiscalyearid))
        this.base64String = data.body.body;
        this.filename = 'TierII_Template.xlsx';
      } else if(fileName === 'oeTarget') {
        const data = await lastValueFrom(this.lookup.downloadOEUploadTemplate(this.employeeid,this.fiscalyearid))
        this.base64String = data.body.body;
        this.filename = 'OETarget_Template.xlsx';
      }
      if(this.base64String !== '') {
        this.downloadTemplate.saveBase64File(this.base64String, this.filename);
      }
    } finally {
      this.ngxSpinner.hide();
    }
  }

  onFileSelectedOne(event: any){
    this.ngxSpinner.show()
    this.showSuccessMsgTier2Results = false
    this.showErrorMsgTier2Results = false
    this.showFileFormatError = false
    this.inputone=true;
    const input = event.target.files[0];
    this.tierIIReportFile = input;
    this.filenameone=input.name;
    event.target.value = null; //Reseting the input fields to upload file again, if required
    
    const lastDotIndex = this.tierIIReportFile.name.lastIndexOf(".");
    const textAfterLastDot = this.tierIIReportFile.name.substring(lastDotIndex + 1);
    if(textAfterLastDot !== 'xlsx') {
      this.ngxSpinner.hide()
      this.showFileFormatError = true;
      setTimeout(()=> {
        this.showFileFormatError = false;
        this.inputone = false;
      },5000)
    } else {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, {type: 'array'})
        const sheetName = workbook.SheetNames[0]
        const sheet = workbook.Sheets[sheetName]
  
        this.Tier2JsonData = XLSX.utils.sheet_to_json(sheet, {header: 1})
  
        const headers = this.Tier2JsonData[0]
        this.Tier2JsonData = this.Tier2JsonData.slice(1).map(row => {
          return headers.reduce((obj, key, i) => {
            obj[key] = row[i] ?? '';
            return obj;
          }, {} as any)
        })
  
        const formattedTierIIData = this.Tier2JsonData.filter(item => item["Supplier Code"]).map(item => ({
          SupplierDivisionCode : item["Supplier Code"],
          fiscalyearid: this.fiscalyearid,
          springminority: this.formatTier2Value(item["Spring Minority"]),
          springwomen: this.formatTier2Value(item["Spring Women"]),
          fallminority: this.formatTier2Value(item["Fall Minority"]),
          fallwomen: this.formatTier2Value(item["Fall Women"])
        }))
  
        this.tier2RequestBody = {formattedTierIIData}
        
        this.updateTierIIResults();
      }
      reader.readAsArrayBuffer(input);
    }
  }

  formatTier2Value(value: any): string | number {
    if(typeof value === 'number') {
      this.formatTier2NumberValue(value)  //Reducing complexity - sonar issue fix
    }
    if(!isNaN(parseFloat(value))) {
      if(value.toString().includes('.')) {
        const floatValue = parseFloat(value)
        if(floatValue < 1) {
          return `${((floatValue * 100)/100).toFixed(2)}%`
        }
        return floatValue.toString().includes('.') && floatValue.toString().split('.')[1].length > 2
          ? `${(Math.ceil(floatValue * 100) / 100).toFixed(2)}%` : `${floatValue}%`
      } else {
        return `${value}%`
      }
    }
    return value
  }

  formatTier2NumberValue(value) {
    if(value < 1) {
      return `${(value * 100).toFixed(2)}%`
    }
    return value.toString().includes('.') && value.toString().split('.')[1].length > 2
      ? `${(Math.ceil(value * 100) / 100).toFixed(2)}%` : `${value}%`
  }

  async updateTierIIResults() {
    try {
      await this.ngxSpinner.show()
      const data = await lastValueFrom(this.lookup.updateTierIIResults(this.tier2RequestBody))
      if(data['statusCode'] === 201 && data?.body?.response?.message?.includes("successfully") && !data?.body?.errorResponse?.message)  {
        this.showSuccessMsgTier2Results = true
        setTimeout(()=>{
          this.showSuccessMsgTier2Results = false
          this.inputone = false;
        },10000)
      } else {
        this.showErrorMsgTier2Results = true;
        setTimeout(()=>{
          this.showErrorMsgTier2Results = false
          this.inputone = false;
        },10000)
	    }
    } catch(error) {
		  this.ngxSpinner.hide()
      this.showErrorMsgTier2Results = true;
        setTimeout(()=>{
          this.showErrorMsgTier2Results = false
          this.inputone = false;
        },10000)
    } finally {
		  this.ngxSpinner.hide()
	  }
  }

  onFileSelectedTwo(event: any){
    this.ngxSpinner.show()
    this.showSuccessMsgOEReport = false
    this.showFileFormatError = false
    this.inputtwo=true;
    const input = event.target.files[0];
    this.uploadOEReportFile = input;
    this.filenametwo=input.name;
    event.target.value = null; //Reseting the input fields to upload file again, if required
    const lastDotIndex = this.uploadOEReportFile.name.lastIndexOf(".");
    const textAfterLastDot = this.uploadOEReportFile.name.substring(lastDotIndex + 1);
    if(textAfterLastDot !== 'xlsx') {
      this.ngxSpinner.hide()
      this.showFileFormatError = true;
      setTimeout(()=> {
        this.showFileFormatError = false;
        this.inputtwo = false;
      },5000)
    } else {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, {type: 'array'})
        const sheetName = workbook.SheetNames[0]
        const sheet = workbook.Sheets[sheetName]
  
        this.OEJsonData = XLSX.utils.sheet_to_json(sheet, {header: 1})
  
        const headers = this.OEJsonData[0]
        this.OEJsonData = this.OEJsonData.slice(1).map(row => {
          return headers.reduce((obj, key, i) => {
            obj[key] = row[i] ?? '';
            return obj;
          }, {} as any)
        })
  
        const originalOEData = this.OEJsonData.filter(item => item.PlantCode).map(item => ({
          ...item,
          PlantCode: this.formatPlantCode(item.PlantCode)
        }))
  
        const formattedOEData = originalOEData.map(item => ({
          oe: item.OE,
          service: item.SERVICE,
          prototype: item.Prototype,
          sRankQPR: item['S-RankQPR(Cannot be changed)'],
          deliverykpm: item.DeliveryKPM,
          warrantyppm: this.formatWarrantyValue(item.WarrantyPPM),
          plantcode: item.PlantCode
        }))
        this.oeRequestBody = {
          formattedOEData,
          fiscalyearid: this.fiscalyearid,
          employeeid: this.employeeid
        }
        
        this.updateOEReports();
      }
      reader.readAsArrayBuffer(input);
    }
  }

  formatWarrantyValue(value): string | number {
    if(typeof value !== 'string') {
      const warrantyVal = parseFloat(value);
      if (!isNaN(warrantyVal)) {
        return Math.round(warrantyVal);
      } else {
        return warrantyVal;
      }
    }
    return value;
  }

  async updateOEReports() {
    try {
      await this.ngxSpinner.show()
      this.uploadOEReportRequest = {
        filename: this.filenametwo,
        filetype: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      }
      const data = await lastValueFrom(this.lookup.updateOEReports(this.oeRequestBody))
      if(data.body.response.uuid)  {
        this.uuid = data.body.response.uuid
        localStorage.setItem("uuid", this.uuid)
        this.lastOEUploadUUID = this.uuid
        this.showInprogressMsgOEReport = true;
        setTimeout(()=>{
          this.showInprogressMsgOEReport = false
          this.inputtwo = false;
        },10000)
        this.displayNotification.startTracking(this.lastOEUploadUUID)
      } else {
        this.showErrorMsgOEReport = true;
        setTimeout(()=>{
          this.showErrorMsgOEReport = false
          this.inputtwo = false;
        },10000)
      }
    } catch(error) {
		  this.ngxSpinner.hide()
      this.showErrorMsgOEReport = true
      setTimeout(()=>{
        this.showErrorMsgOEReport = false
        this.inputtwo = false;
      },10000)
    } finally {
      this.ngxSpinner.hide()
    }
  }

  formatPlantCode(plantCode) {
    // Ensure PlantCode is in the correct format (e.g., 2500-0)
    let pCode = plantCode.toString().trim();
    if (pCode && !pCode.includes('-')) {
        // Add dash if it's missing
        pCode = `${pCode.slice(0, 4)}-${pCode.slice(4)}`
    }
    return pCode;
  }

  removeAttachmentOne(){
    this.inputone=false;
  }

  removeAttachmentTwo(){
    this.inputtwo=false;
  }
}
