import { Component, OnInit, ViewChild, model } from '@angular/core';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { LookupService } from '../services/lookup.service';
import { AdminService } from '../services/admin.service';
import 'ag-grid-enterprise';
import { BusinessEntityService } from '../services/business-entity.service';
import { distinctUntilChanged, lastValueFrom } from 'rxjs';
import { ErrorMessagesService } from '../services/error-messages.service';
import { AgGridSortingService } from '../services/ag-grid-sorting.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrl: './admin.component.css'
})
export class AdminComponent implements OnInit {
  @ViewChild('tabGroup') tabGroup: MatTabGroup;
  isOpen=true; isOpen1=true;
  showTab='first';
  selectedValue=false;
  selectedTabIndex=0; selected = model<Date | null>(null);
  adminForm:FormGroup;
  selectedThemeOwner: any; themeOwnerfirstname: any[]; themeOwnerlastname: any[]; themeOwneremail: any[]; themeOwnerfullname: any;
  textareaValue: any; searchName = ''; selectedThemeOwnerEmail: string;
  successMessage='The Response should come from Backend';
  failureMessage='Some Error occured ! Please try after sometime';
  isAuthenticUser=false; isManager=false;
  applyPermissionsRequestbody: any; updatesThemePermissionsRequestBody: any = [];
  standardIETTitleList = [];  generalPrioritiesTitleList = [];  standardIETsAccordianValueList = [];  generalPrioritiesAccordianValueList = [];
  clickedAddStandardIETBullet: boolean;  clickedAddGeneralIETBullet: boolean;
  somethingWentWrong = 'Something went wrong'; validation: any; loginUserRole: string;
  errorMsg: string;  showErrorMsg: boolean;
  generalPrioritiesSubbulletValueList = [];  generalPrioritiesSubbulletValue = [];
  noSubbulletResponse = 'No Subbullets available for this Bullet id';
  addIETSubbulletBody: any;  selectedBulletid: any;  generalIETSubBulletId: any;  updateSubbulletbody: any;
  generalPrioritiesCount = 1; clickedGeneralPrioritySubBullet: boolean;  clickedAddGeneralIETSubbullet: boolean;
  callEmailerComponent: boolean;  callUploaderComponent: boolean;  callApplyStandardIetSupplierComponent: boolean;  callFiscalYearComponent: boolean;
  callSupplierCategoryComponent: boolean;  callSupplierFlagComponent: boolean;  
  firstCallTriggered: boolean; standardIETsAccordianValue:any=[];  standardIETId:any;  standardIETBulletId:any; 
  generalPrioritiesAccordianValue:any=[]; generalIETId: any;  generalIETBulletId: any;  
  generalPrioritiesTitle = [];  standardIETTitle = [];
  inputSearchGeneralPriorities = '';  inputSearchStandardIET = '';  inputSearchISE:any='';
  supplierList:any=[];  supplierListISE:any=[];
  descrip: string;  apparentid: any;  inactive: any;
  fiscalYear = Number(localStorage.getItem("fiscalId"));
  disableISEReportsLink = false;  disableButtons = false;
  addIETBody:any;  updateIETBody:any;  deleteIETBody:any;  addIETBulletBody:any;
  updateIETBulletBody:any;  deleteIETBulletBody:any;
  themeOwnersList = [];  selectAllThemeOwners = true;  themeOwnerEligible = false;
  iseScreenError = "No Supplier exist for This fiscal Year";
  standardGeneralIETScreenError = "No Details available for this fiscal year"
  getManagerDetailsError = "No Details available for manager and senior manager"
  pageFailureMsg: string; showPageErrorMsg: boolean;
  disableThemeOwnerButtons = true;
  
  onTabChanged(event: MatTabChangeEvent, page?: string) {
    //Tabs based on Logged in role
    if(this.isAuthenticUser && this.themeOwnerEligible){  //Admin
      this.selectTabAdminRole(event.index, page)
    } else if(!this.isAuthenticUser && this.themeOwnerEligible) {  //Manager with Themeowner access
      if(event.index === 0 && page !== 'themeOwnerTabToggled'){
        this.showIET();
      } else if(event.index === 1 && page !== 'themeOwnerTabToggled'){
        this.showGeneralPriorities();
      }
      this.closeAccordionOnTabChange()
    } else { this.selectedTabIndex = 0 }
  }

  constructor(private readonly router: Router, private readonly ngxSpinner:NgxSpinnerService,private readonly formBuilder:FormBuilder,private readonly errorService: ErrorMessagesService
    ,private readonly lookup:LookupService, private readonly admin:AdminService,private readonly bservice:BusinessEntityService,private readonly sortService:AgGridSortingService){ }
  ngOnInit():void{
    this.ngxSpinner.show()
    this.validation=this.lookup.localStorageData.pipe(distinctUntilChanged()).subscribe({
      next: (data)=>{
        const localStorageAssumeUser = JSON.parse(localStorage.getItem('userAssumed'));
        const redirectFlag = localStorage.getItem("redirectedAssume");
        if(data?.includes('success')){
          this.loginUserRole = this.bservice.loggedUserRole;
          if (localStorageAssumeUser) {
            this.assumeRoleByLocalStorage(localStorageAssumeUser)
          } else {
            this.assumeRoleByBService()
          }
        }else if((data?.includes('failure') || data === null) && localStorageAssumeUser){
          if(redirectFlag === "false"){
            this.router.navigate(['home'])
          }
        }
      },error:(_error)=>{ this.ngxSpinner.hide(); }
    })
    this.adminForm=this.formBuilder.group({
      iseTabNodeValueName:new FormControl(''),
      iseTabNodeValue:new FormControl(''),
      isetabNodeExplanation:new FormControl(''),
      iseTabSubNodeValue:new FormControl(''),
      iseTabNodeGeneralPrtyValueName:new FormControl(''),
      iseTabNodeGeneralPrtyValue:new FormControl(''),
      isetabNodeGeneralPrtyExplanation:new FormControl(''),
      iseTabSubNodeGeneralPrtyValue:new FormControl(''),
      iseTabNodeGeneralPrtyBulletTitle: new FormControl(''),
      iseTabNodeGeneralPrtyOrderValue: new FormControl('')
    })
  }
  selectTabAdminRole(index, page?: string) {
    if(index === 0 && !this.firstCallTriggered){
      this.showISE();
    } else if(index === 1 && page !== 'themeOwnerTabToggled'){
      this.showIET();
      this.closeAccordionOnTabChange();
    } else if(index === 2){
      this.showGeneralPriorities();
      this.closeAccordionOnTabChange();
    } else if(index === 3){
      this.callSupplierCategoryComponent = true;
    } else if(index === 4){
      this.callSupplierFlagComponent = true;
    } else if(index === 5){
      this.callApplyStandardIetSupplierComponent = true;
    } else if(index === 6){
      this.callEmailerComponent = true;
    } else if(index === 7){
      this.callFiscalYearComponent = true;
    } else if(index === 8){
      this.callUploaderComponent = true;
    }
  }
   assumeRoleByLocalStorage(localStorageAssumeUser) {
    if (localStorageAssumeUser.role === 'PurappsAPPAdmin') {
      this.loginUserRole='Admin';
      this.isAuthenticUser=true;
      this.themeOwnerEligible = true;
      this.showISE()  //Calling the first Tab for Admin Role
      this.firstCallTriggered = true;
    } else if (localStorageAssumeUser.role === 'PurappsManager' && localStorageAssumeUser.is_theme_owner) {
      this.loginUserRole='Manager';
      this.isAuthenticUser=false;
      this.themeOwnerEligible = true;
      this.showIET()  //Calling the first Tab for Manager Role with themeowner access
      this.firstCallTriggered = true;
    }
   }
   assumeRoleByBService() {
    if(this.bservice?.loggedUserRole==='PurappsAPPAdmin'){
      this.loginUserRole='Admin';  this.isAuthenticUser=true;  this.themeOwnerEligible = true;
      this.showISE()  //Calling the first Tab for Admin Role
      this.firstCallTriggered = true;
    } else if(this.bservice?.loggedUserRole ==='PurappsManager' && this.bservice?.themeOwner){
      this.loginUserRole='Manager';  this.isAuthenticUser=false;  this.themeOwnerEligible = true;
      this.showIET() //Calling the first Tab for Manager Role with themeowner access
      this.firstCallTriggered = true;
    }
   }
  onItemSelection(descrip: string,apparentid: any,inactive: any) {
    this.descrip = descrip; this.apparentid=apparentid;
    if(inactive===true){
      this.inactive=false;
    }
    else if(inactive===false){
      this.inactive=true;
    }
    this.showtoggle();
  }
  showtoggle(){
    if(this.apparentid==='' || !this.apparentid) {
      this.disableISEReportsLink = this.disableButtons = true; 
    }else {
      this.disableISEReportsLink = this.disableButtons =  false;
    }
  }
  async showISE(){
    this.ngxSpinner.show()
    try {
      const data = await lastValueFrom(this.lookup.getISESuppliers(this.fiscalYear))
      if(data['statusCode'] === 200 && data?.body?.response !== this.iseScreenError && !data?.body?.errorResponse) {
        this.supplierList = data.body.response;
        this.supplierListISE = data.body.response;
        this.descrip = ''; this.apparentid = ''; this.inactive = '';
        if(this.apparentid==='' || !this.apparentid) {
          this.disableISEReportsLink = this.disableButtons = true; 
        }else {
          this.disableISEReportsLink = this.disableButtons =  false;
        } 
      } else if (data?.body?.response === this.iseScreenError){
        this.pageFailureMsg = this.errorService.noSupplierErrMsg
        this.showPageErrorMsg = true
        setTimeout(()=> { this.showPageErrorMsg = false },5000)
      } else {
        this.pageFailureMsg = this.errorService.serverErrMsg
        this.showPageErrorMsg = true
        setTimeout(()=> { this.showPageErrorMsg = false },5000)
      }
    } catch { this.ngxSpinner.hide() } 
    finally { this.ngxSpinner.hide() }
  }
  toggle(){
    this.ngxSpinner.show();
    const togglebody={
      "parentId": this.apparentid,
      "inactive": this.inactive,
      "modifiedBy": this.bservice.loggedUserName
    }
    this.lookup.toggleISESuppliers(togglebody).subscribe(_data=>{
      this.showISE();
    })
    setTimeout(()=> { this.ngxSpinner.hide(); }, 3000);
  }
  async showIET(){
    this.ngxSpinner.show()
    this.standardIETsAccordianValue = [];
    this.standardIETTitle = [];
    try {
      const data = await lastValueFrom(this.lookup.getStandardIETList(this.fiscalYear))
      if(data['statusCode'] === 200 && data?.body?.response !== this.standardGeneralIETScreenError && !data?.body?.errorResponse) {
        this.standardIETsAccordianValueList=data.body.response;
        for(const element of this.standardIETsAccordianValueList){
          if(element.title !== ""){
            this.standardIETsAccordianValue.push(element);
          }
        }
        this.standardIETTitleList = data.body.response.map((value)=> value.title);
        for(const element of this.standardIETTitleList){
          if(element !== ""){
            this.standardIETTitle.push(element);
          }
        }
      } else if(data?.body?.response === this.standardGeneralIETScreenError) {
        this.pageFailureMsg = this.errorService.noStandardIETErrMsg
        this.showPageErrorMsg = true
        setTimeout(()=> { this.showPageErrorMsg = false },5000)
      } else {
        this.pageFailureMsg = this.errorService.serverErrMsg
        this.showPageErrorMsg = true
        setTimeout(()=> { this.showPageErrorMsg = false },5000)
      }
    } catch { this.ngxSpinner.hide() }
    finally { this.ngxSpinner.hide() }
  }

  async showGeneralPriorities(){
    this.ngxSpinner.show()
    this.generalPrioritiesAccordianValue = [];
    this.generalPrioritiesTitle = [];
    if(this.isAuthenticUser) {
      this.showThemeOwners();
    }
    try {
      const data = await lastValueFrom(this.lookup.getGeneralIETList(this.fiscalYear))
      if(data['statusCode'] === 200 && data?.body?.response !== this.standardGeneralIETScreenError && !data?.body?.errorResponse) {
        this.generalPrioritiesListDetails(data.body.response)
      } else if(data?.body?.response === this.standardGeneralIETScreenError) {
        this.pageFailureMsg = this.errorService.noGeneralPrioritiesErrMsg
        this.showPageErrorMsg = true
        setTimeout(()=> { this.showPageErrorMsg = false },5000)
      } else {
        this.pageFailureMsg = this.errorService.serverErrMsg
        this.showPageErrorMsg = true
        setTimeout(()=> { this.showPageErrorMsg = false },5000)
      }
    } catch { this.ngxSpinner.hide() } 
    finally { this.ngxSpinner.hide() }
  }

  generalPrioritiesListDetails(value) {
    this.generalPrioritiesAccordianValueList=value;
    for(const element of this.generalPrioritiesAccordianValueList){
      if(element.title !== ""){
        this.generalPrioritiesAccordianValue.push(element);
      }
    }
    this.generalPrioritiesTitleList = value.map((value)=> value.title);
    for(const element of this.generalPrioritiesTitleList){
      if(element !== ""){
        this.generalPrioritiesTitle.push(element);
        this.generalPrioritiesCount = this.generalPrioritiesTitle.length;
      }
    }
  }

  filteredISE(){
    if(this.inputSearchISE===''){
      this.supplierList=this.supplierListISE;
    } else{
      this.supplierList=this.supplierListISE.filter((value) => value.description.toLowerCase().includes(this.inputSearchISE.toLocaleLowerCase()));
    }
  }

  get filteredGeneralPrioritiesTitle(): string[] {
    return this.generalPrioritiesTitle.filter((value) => value.toLowerCase().includes(this.inputSearchGeneralPriorities.toLocaleLowerCase()));
  }

  get filteredStandardIETTitle(): string[] {
    return this.standardIETTitle.filter((value) => value.toLowerCase().includes(this.inputSearchStandardIET.toLocaleLowerCase()));
  }

  showBullet(standardiet,category){
    this.clickedAddStandardIETBullet = false;
    this.adminForm.controls['iseTabNodeValue'].setValue(category.title);
    this.adminForm.controls['isetabNodeExplanation'].setValue(category.body);
    this.standardIETId=standardiet;
    this.lookup.getStandardIETBulletDesc(standardiet).subscribe(data=>{
      if(data.body.response!==this.somethingWentWrong){
        for(const element of this.standardIETsAccordianValue){
          if(element.standardietid===standardiet){
            element.content=data.body.response;
          }
        }
      }
    })
  }

  showBulletValue(standardiet,_category){
    this.clickedAddStandardIETBullet = false;
    this.lookup.getStandardIETBulletDesc(standardiet).subscribe((data)=>{
      const targetElement = this.standardIETsAccordianValue.find((element) => element.standardietid === standardiet);
      if((targetElement)&&(data.body.response!==this.somethingWentWrong)) {
        targetElement.content = data.body.response;
      }
    });
  }

  showGeneralIETBullet(generaliet, category) {
    this.clickedAddGeneralIETBullet = false;
    this.adminForm.controls['iseTabNodeGeneralPrtyValue'].setValue(category.title);
    this.adminForm.controls['isetabNodeGeneralPrtyExplanation'].setValue(category.body);
    this.adminForm.controls['iseTabNodeGeneralPrtyOrderValue'].setValue(category.reorder);
    this.generalIETId=generaliet;
    this.lookup.getGeneralIETBulletDesc(generaliet).subscribe(data=>{
      if(data.body.response!==this.somethingWentWrong){
        for(const element of this.generalPrioritiesAccordianValue){
          if(element.generalpriorityietid===generaliet && element.title !== null){
            element.content=data.body.response;
          }
        }
      }
    })
  }
  showGeneralIETBulletValue(generaliet,_category){
    this.generalIETId=generaliet;
    this.clickedAddGeneralIETBullet = false;
    this.lookup.getGeneralIETBulletDesc(generaliet).subscribe(data=>{
      const targetElement = this.generalPrioritiesAccordianValue.find((element) => element.generalpriorityietid === generaliet && element.title !== null);
      if((targetElement)&&(data.body.response!==this.somethingWentWrong)) {
        targetElement.content = data.body.response;
      }
    });
  }
  showGeneralIETSubbullet(bulletid) {
    this.lookup.getGeneralPrioritySubBulletsByBulletid(bulletid).subscribe((data) => {
      if(data.body.response !== this.somethingWentWrong && data.body.response !== this.noSubbulletResponse) {
        this.generalPrioritiesSubbulletValueList = data.body.response;
        for(const element of this.generalPrioritiesSubbulletValueList){
          if(element.bullet !== "" && element.bulletid === bulletid && !this.generalPrioritiesSubbulletValue.some((value)=>value.generalpriorityietbulletid === element.generalpriorityietbulletid)) {
            this.generalPrioritiesSubbulletValue.push(element);
          }
        }
      }
    })
    this.selectedBulletid = bulletid;
  }
  selectedSubnode(_value,block){
     this.showTab=block
  }
  addIET(value){
    if(value === 'standardIET') {
      setTimeout(() => {
      this.adminForm.controls['iseTabNodeValue'].setValue(this.adminForm.controls['iseTabNodeValueName'].value)
      }, 1000);
      this.showTab='second';
    } else if(value === 'generalPriority') {
      setTimeout(() => {
        this.adminForm.controls['iseTabNodeGeneralPrtyValue'].setValue(this.adminForm.controls['iseTabNodeGeneralPrtyValueName'].value)
        }, 1000);
        this.showTab='second';
    }
  }
  saveIET(){
    if(!this.standardIETId){
    this.addIETBody={
      "fiscalYear": this.fiscalYear,
      "title": this.adminForm.controls['iseTabNodeValue'].value,
      "ietDescription": this.adminForm.controls['isetabNodeExplanation'].value,
      "inactive": false,
      "createdBy": this.bservice.loggedUserName,
      "iet_type": "STANDARD"
    }
    Object.keys(this.addIETBody).forEach((key)=> {    //Converting null values to empty string
      if(this.addIETBody[key]=== null){
        this.addIETBody[key] = "";
      }
    });
    if((this.addIETBody.title.trim() !== "") && (this.parseHTMLContent(this.addIETBody.ietDescription).trim() !== "")) {
      this.lookup.addIET(this.addIETBody).subscribe(_data=>{
        this.showIET();
        this.adminForm.reset();
        this.showTab='first';
        this.standardIETId=false;
      })
    }else {
      this.showErrorMsg = true;
      this.errorMsg = this.errorService.titleBodyErrMsg;
      setTimeout(()=>{
        this.showErrorMsg = false;
      },3000)
    }
    }
    else{ 
    this.updateIETBody={
      "title": this.adminForm.controls['iseTabNodeValue'].value,
      "body": this.adminForm.controls['isetabNodeExplanation'].value,
      "modifiedBy": this.bservice.loggedUserName,
      "iet_Id": this.standardIETId,
      "iet_type": "STANDARD"
    }
    Object.keys(this.updateIETBody).forEach((key)=> {    //Converting null values to empty string
      if(this.updateIETBody[key]=== null){
        this.updateIETBody[key] = "";
      }
    });
    if((this.updateIETBody.title.trim() !== "") && (this.parseHTMLContent(this.updateIETBody.body).trim() !== "")) {
      this.lookup.updateIET(this.updateIETBody).subscribe(_data=>{
        this.showIET();
        this.adminForm.reset();
        this.showTab='first';
        this.standardIETId=false;
      })
    } else {
      this.showErrorMsg = true;
      this.errorMsg = this.errorService.titleBodyErrMsg;
      setTimeout(()=>{
        this.showErrorMsg = false;
      },3000)
    }
    }
  }
  deleteIET(){
    if((this.adminForm.controls['iseTabNodeValue'].value?.length > 0) && (this.adminForm.controls['isetabNodeExplanation'].value?.length > 0) &&
    this.parseHTMLContent(this.adminForm.controls['iseTabNodeValue'].value).trim()!=="" && this.parseHTMLContent(this.adminForm.controls['isetabNodeExplanation'].value).trim()!==""){
      this.deleteIETBody={
        "iet_Id": this.standardIETId,
        "iet_type": "STANDARD"
      }
      this.lookup.deleteIET(this.deleteIETBody).subscribe(_data=>{
        this.showIET();
        this.adminForm.reset();
        this.showTab='first';
        this.standardIETId=false;
      })
    }else {
      this.showErrorMsg = true;
      this.errorMsg = this.errorService.titleBodyErrMsg;
      setTimeout(()=>{
        this.showErrorMsg = false;
      },3000)
    }
  }

  saveGeneralIET(){
    if(!this.generalIETId){
    this.addIETBody={
      "fiscalYear": this.fiscalYear,
      "title": this.adminForm.controls['iseTabNodeGeneralPrtyValue'].value,
      "ietDescription": this.adminForm.controls['isetabNodeGeneralPrtyExplanation'].value,
      "inactive": true,
      "createdBy": this.bservice.loggedUserName,
      "iet_type": "GENERAL",
      "position": this.adminForm.controls['iseTabNodeGeneralPrtyOrderValue'].value ? this.adminForm.controls['iseTabNodeGeneralPrtyOrderValue'].value : this.generalPrioritiesCount+1
    }
    Object.keys(this.addIETBody).forEach((key)=> {    //Converting null values to empty string
      if(this.addIETBody[key]=== null){
        this.addIETBody[key] = "";
      }
    });
    if((this.addIETBody.title.trim() !== "") && (this.parseHTMLContent(this.addIETBody.ietDescription).trim() !== "")) {
      this.lookup.addIET(this.addIETBody).subscribe(_data=>{
        this.showGeneralPriorities();
        this.adminForm.reset();
        this.showTab='first';
        this.generalIETId=false;
      })
      }else {
        this.showErrorMsg = true;
        this.errorMsg = this.errorService.titleBodyErrMsg;
        setTimeout(()=>{
          this.showErrorMsg = false;
        },3000)
      }
    }
    else{ 
    this.updateIETBody={
      "title": this.adminForm.controls['iseTabNodeGeneralPrtyValue'].value,
      "body": this.adminForm.controls['isetabNodeGeneralPrtyExplanation'].value,
      "modifiedBy": this.bservice.loggedUserName,
      "iet_Id": this.generalIETId,
      "iet_type": "GENERAL",
      "position": this.adminForm.controls['iseTabNodeGeneralPrtyOrderValue'].value ? this.adminForm.controls['iseTabNodeGeneralPrtyOrderValue'].value : this.generalPrioritiesCount
    }
    Object.keys(this.updateIETBody).forEach((key)=> {    //Converting null values to empty string
      if(this.updateIETBody[key]=== null){
        this.updateIETBody[key] = "";
      }
    });
    if((this.updateIETBody.title.trim() !== "") && (this.parseHTMLContent(this.updateIETBody.body).trim() !== "")) {
      this.lookup.updateIET(this.updateIETBody).subscribe(_data=>{
        this.showGeneralPriorities();
        this.adminForm.reset();
        this.showTab='first';
        this.generalIETId=false;
      })
    }else {
      this.showErrorMsg = true;
      this.errorMsg = this.errorService.titleBodyErrMsg;
      setTimeout(()=>{
        this.showErrorMsg = false;
      },3000)
    }
    }
  }

  deleteGeneralIET(){
    if((this.adminForm.controls['iseTabNodeGeneralPrtyValue'].value?.length > 0) && (this.adminForm.controls['isetabNodeGeneralPrtyExplanation'].value?.length > 0) &&
    this.parseHTMLContent(this.adminForm.controls['iseTabNodeGeneralPrtyValue'].value).trim()!=="" && this.parseHTMLContent(this.adminForm.controls['isetabNodeGeneralPrtyExplanation'].value).trim()!==""){
      this.deleteIETBody={
        "iet_Id": this.generalIETId,
        "iet_type": "GENERAL"
      }
      this.lookup.deleteIET(this.deleteIETBody).subscribe(_data=>{
        this.showGeneralPriorities();
        this.adminForm.reset();
        this.showTab='first';
        this.generalIETId=false;
      })
    }else {
      this.showErrorMsg = true;
      this.errorMsg = this.errorService.titleBodyErrMsg;
      setTimeout(()=>{
        this.showErrorMsg = false;
      },3000)
    }
  }

  addBullet(value){
    if(value === 'standardIET') {
      this.addbulletstandardIET();
    } else if(value === 'generalPriority') {
      this.addbulletgeneralPriority();
    }
  }

  addbulletstandardIET(){
    if(!this.standardIETId && !this.standardIETBulletId){
      this.addIETBody={
        "fiscalYear": this.fiscalYear,
        "title": this.adminForm.controls['iseTabNodeValue'].value,
        "ietDescription": this.adminForm.controls['isetabNodeExplanation'].value,
        "inactive": true,
        "createdBy": this.bservice.loggedUserName,
        "iet_type": "STANDARD"
      }
      if((this.adminForm.controls['iseTabNodeValue'].value?.length > 0) && (this.adminForm.controls['isetabNodeExplanation'].value?.length > 0)
      && this.parseHTMLContent(this.addIETBody.title).trim()!=="" && this.parseHTMLContent(this.addIETBody.ietDescription).trim()!==""
      ){
        this.lookup.addIET(this.addIETBody).subscribe((data)=>{
          this.showIET();
          this.standardIETId = data.body.response.standardietid;
          this.standardIETBulletId = '';
          this.showTab='third';
          this.adminForm.controls['iseTabSubNodeValue'].setValue("");
          this.clickedAddStandardIETBullet = true;
        });
      }else {
        this.showErrorMsg = true;
        this.errorMsg = this.errorService.titleBodyErrMsg;
        setTimeout(()=>{
          this.showErrorMsg = false;
        },3000)
      }
    } else if((this.adminForm.controls['iseTabNodeValue'].value?.length > 0) && (this.adminForm.controls['isetabNodeExplanation'].value?.length > 0) &&
    this.parseHTMLContent(this.adminForm.controls['iseTabNodeValue'].value).trim()!=="" && this.parseHTMLContent(this.adminForm.controls['isetabNodeExplanation'].value).trim()!==""
    ){
      this.standardIETBulletId = '';
      this.showTab='third';
      this.adminForm.controls['iseTabSubNodeValue'].setValue("");
      this.clickedAddStandardIETBullet = true;
    } else {
      this.showErrorMsg = true;
      this.errorMsg = this.errorService.titleBodyErrMsg;
      setTimeout(()=>{ this.showErrorMsg = false; },3000)
    }
  }

  addbulletgeneralPriority(){
    if(!this.generalIETId && !this.generalIETBulletId){
      this.addIETBody={
        "fiscalYear": this.fiscalYear,
        "title": this.adminForm.controls['iseTabNodeGeneralPrtyValue'].value,
        "ietDescription": this.adminForm.controls['isetabNodeGeneralPrtyExplanation'].value,
        "inactive": true,
        "createdBy": this.bservice.loggedUserName,
        "iet_type": "GENERAL"
      }
      if((this.adminForm.controls['iseTabNodeGeneralPrtyValue'].value?.length > 0) && (this.adminForm.controls['isetabNodeGeneralPrtyExplanation'].value?.length > 0) &&
      this.parseHTMLContent(this.adminForm.controls['iseTabNodeGeneralPrtyValue'].value).trim()!=="" && this.parseHTMLContent(this.adminForm.controls['isetabNodeGeneralPrtyExplanation'].value).trim()!==""){
        this.lookup.addIET(this.addIETBody).subscribe((data)=>{
          this.showGeneralPriorities();
          this.generalIETId = data.body.response.generalpriorityietid;
          this.generalIETBulletId = '';
          this.showTab='third';
          this.adminForm.controls['iseTabNodeGeneralPrtyBulletTitle'].setValue("");
          this.adminForm.controls['iseTabSubNodeGeneralPrtyValue'].setValue("");
          this.clickedAddGeneralIETBullet = true;
        });
      }else {
        this.showErrorMsg = true;
        this.errorMsg = this.errorService.titleBodyErrMsg;
        setTimeout(()=>{ this.showErrorMsg = false; },3000)
      }
    } else if((this.adminForm.controls['iseTabNodeGeneralPrtyValue'].value?.length > 0) && (this.adminForm.controls['isetabNodeGeneralPrtyExplanation'].value?.length > 0) &&
    this.parseHTMLContent(this.adminForm.controls['iseTabNodeGeneralPrtyValue'].value).trim()!=="" && this.parseHTMLContent(this.adminForm.controls['isetabNodeGeneralPrtyExplanation'].value).trim()!==""){
      this.generalIETBulletId = '';
      this.showTab='third';
      this.adminForm.controls['iseTabNodeGeneralPrtyBulletTitle'].setValue("");
      this.adminForm.controls['iseTabSubNodeGeneralPrtyValue'].setValue("");
      this.clickedAddGeneralIETBullet = true;
    } else {
      this.showErrorMsg = true;
      this.errorMsg = this.errorService.titleBodyErrMsg;
      setTimeout(()=>{ this.showErrorMsg = false; },3000)
    }
  }

  addGeneralPrioritySubBullet() {
    if(this.parseHTMLContent(this.adminForm.controls['iseTabNodeGeneralPrtyBulletTitle'].value).trim()!=="" && this.adminForm.controls['iseTabNodeGeneralPrtyBulletTitle'].value!==null ){
    this.showTab='fourth';
    this.adminForm.controls['iseTabSubNodeGeneralPrtyValue'].setValue("");
    this.clickedAddGeneralIETSubbullet = true;
    this.generalIETSubBulletId = false;
  }else {
    this.showErrorMsg = true;
    this.errorMsg = this.errorService.bulletBodyErrMsg;
    setTimeout(()=>{ this.showErrorMsg = false; },3000)
  }}

  saveSubBullet() {
    if(!this.generalIETSubBulletId) {
      this.addIETSubbulletBody = {
        "bullet": this.adminForm.controls['iseTabSubNodeGeneralPrtyValue'].value,
        "generalpriorityietid": this.generalIETId,
        "inactive": false,
        "createdby": this.bservice.loggedUserName,
        "bulletid": this.selectedBulletid
      }
      if(this.parseHTMLContent(this.addIETSubbulletBody.bullet).trim()!=="" && this.addIETSubbulletBody.bullet!==null){this.lookup.addSubBullet(this.addIETSubbulletBody).subscribe(_data=> {
        this.showGeneralPriorities();
        this.adminForm.reset();
        this.showTab='first';
        this.generalIETSubBulletId=false;
      });}
      else {
        this.showErrorMsg = true;
        this.errorMsg = this.errorService.subbulletBodyErrMsg;
        setTimeout(()=>{ this.showErrorMsg = false; },3000)
      }
    } else {
      this.updateSubbulletbody = {
        "bullet": this.adminForm.controls['iseTabSubNodeGeneralPrtyValue'].value,
        "generalpriorityietid": this.generalIETId,
        "modifiedBy": this.bservice.loggedUserName,
        "generalpriorityietbulletid": this.generalIETSubBulletId
      }
      if(this.parseHTMLContent(this.updateSubbulletbody.bullet).trim()!=="" && this.updateSubbulletbody.bullet!==null){
      this.lookup.updateSubBullet(this.updateSubbulletbody).subscribe(_data=> {
        this.showGeneralPriorities();
        this.adminForm.reset();
        this.showTab='first';
        this.generalIETSubBulletId=false;
      });}
      else {
        this.showErrorMsg = true;
        this.errorMsg = this.errorService.subbulletBodyErrMsg;
        setTimeout(()=>{ this.showErrorMsg = false; },3000)
      }
    }
  }

  showIETBullet(item){
    this.standardIETBulletId=item.standardietbulletid;
    this.adminForm.controls['iseTabSubNodeValue'].setValue(item.bullet);
  }

  showGeneralIETBulletList(item) {
    this.generalIETBulletId = item.generalpriorityietbulletid;
    this.adminForm.controls['iseTabNodeGeneralPrtyBulletTitle'].setValue(item.title); //Title is the bullet value
  }

  showGeneralIETSubbulletValue(item) {
    this.clickedGeneralPrioritySubBullet = true;
    this.generalIETSubBulletId = item.generalpriorityietbulletid;
    this.adminForm.controls['iseTabSubNodeGeneralPrtyValue'].setValue(item.bullet);   //Bullet is the sub-bullet value
  }

  saveIETBullet(){
    if(!this.standardIETBulletId){
      this.addIETBulletBody={
        "iet_Id": this.standardIETId,
        "bulletDescription": this.adminForm.controls['iseTabSubNodeValue'].value,
        "createdBy": this.bservice.loggedUserName,
        "iet_type": "STANDARD",
        "title": this.adminForm.controls['iseTabNodeValue'].value
      }
      Object.keys(this.addIETBulletBody).forEach((key)=> {    //Converting null values to empty string
        if(this.addIETBulletBody[key]=== null){
          this.addIETBulletBody[key] = "";
        }
      });
      if(this.parseHTMLContent(this.addIETBulletBody.bulletDescription).trim() !== "") {
        this.lookup.addIETBullet(this.addIETBulletBody).subscribe(_data=>{
          this.showIET();
          this.adminForm.reset();
          this.showTab='first';
          this.standardIETBulletId=false;
        })
      } else {
        this.showErrorMsg = true;
        this.errorMsg = this.errorService.bulletBodyErrMsg;
        setTimeout(()=>{ this.showErrorMsg = false; },3000)
      }
      }
      else{ 
      this.updateIETBulletBody={
        "bullet": this.adminForm.controls['iseTabSubNodeValue'].value,
        "modifiedBy": this.bservice.loggedUserName,
        "ietbullet_id": this.standardIETBulletId,
        "title": this.adminForm.controls['iseTabNodeValue'].value,
        "iet_type": "STANDARD"
      }
      Object.keys(this.updateIETBulletBody).forEach((key)=> {    //Converting null values to empty string
        if(this.updateIETBulletBody[key]=== null){
          this.updateIETBulletBody[key] = "";
        }
      });
      if(this.parseHTMLContent(this.updateIETBulletBody.bullet).trim() !== "") {
        this.lookup.updateIETBullet(this.updateIETBulletBody).subscribe(_data=>{
          this.showIET();
          this.adminForm.reset();
          this.showTab='first';
          this.standardIETBulletId=false;
        })
      } else {
        this.showErrorMsg = true;
        this.errorMsg = this.errorService.bulletBodyErrMsg;
        setTimeout(()=>{ this.showErrorMsg = false; },3000)
      }
      }
  }

  deleteIETBullet(){
    if(this.adminForm.controls['iseTabSubNodeValue'].value?.length > 0 &&
    this.parseHTMLContent(this.adminForm.controls['iseTabSubNodeValue'].value).trim()!=="") {
      this.deleteIETBulletBody={
        "ietbullet_id": this.standardIETBulletId,
        "iet_type": "STANDARD"
      }
      this.lookup.deleteIETBullet(this.deleteIETBulletBody).subscribe(_data=>{
        this.showIET();
        this.adminForm.reset();
        this.showTab='first';
        this.standardIETBulletId=false;
      })
    } else {
      this.showErrorMsg = true;
      this.errorMsg = this.errorService.bulletBodyErrMsg;
      setTimeout(()=>{ this.showErrorMsg = false; },3000)
    }
  }

  saveGeneralIETBullet(){
    if(!this.generalIETBulletId){
      this.addIETBulletBody={
        "iet_Id": this.generalIETId,
        "bulletDescription": '',
        "createdBy": this.bservice.loggedUserName,
        "iet_type": "GENERAL",
        "title": this.adminForm.controls['iseTabNodeGeneralPrtyBulletTitle'].value
      }
      Object.keys(this.addIETBulletBody).forEach((key)=> {    //Converting null values to empty string
        if(this.addIETBulletBody[key]=== null){
          this.addIETBulletBody[key] = "";
        }
      });
      if(this.parseHTMLContent(this.addIETBulletBody.title).trim() !== "") {
        this.lookup.addIETBullet(this.addIETBulletBody).subscribe(_data=>{
          this.showGeneralPriorities();
          this.adminForm.reset();
          this.showTab='first';
          this.generalIETBulletId=false;
        })
      } else {
        this.showErrorMsg = true;
        this.errorMsg = this.errorService.bulletBodyErrMsg;
        setTimeout(()=>{ this.showErrorMsg = false; },3000)
      }
      }
      else{ 
      this.updateIETBulletBody={
        "bullet": '',
        "modifiedBy": this.bservice.loggedUserName,
        "ietbullet_id": this.generalIETBulletId,
        "title": this.adminForm.controls['iseTabNodeGeneralPrtyBulletTitle'].value,
        "iet_type": "GENERAL"
      }
      Object.keys(this.updateIETBulletBody).forEach((key)=> {    //Converting null values to empty string
        if(this.updateIETBulletBody[key]=== null){
          this.updateIETBulletBody[key] = "";
        }
      });
      if(this.parseHTMLContent(this.updateIETBulletBody.title).trim() !== "") {
        this.lookup.updateIETBullet(this.updateIETBulletBody).subscribe(_data=>{
          this.showGeneralPriorities();
          this.adminForm.reset();
          this.showTab='first';
          this.generalIETBulletId=false;
        })
      } else {
        this.showErrorMsg = true;
        this.errorMsg = this.errorService.bulletBodyErrMsg;
        setTimeout(()=>{ this.showErrorMsg = false; },3000)
      }
    }
  }

  closeAccordionOnTabChange() {
    this.adminForm.reset();
    this.selectedSubnode('', 'first');
    const titleElement = document.querySelectorAll('.collapsesearchfilterBtn.active');
    titleElement.forEach(element=>{
      element.classList.remove('active');
    })
    const bulletElement = document.querySelectorAll('.collapse.subBulletList')
    bulletElement.forEach(element=>{
      element.classList.remove('show');
    })
    const subbulletElement = document.querySelectorAll('.collapse.subbullets')
    subbulletElement.forEach(element=>{
      element.classList.remove('show')
    })
  }

  deleteGeneralIETBullet(){
    if(this.adminForm.controls['iseTabNodeGeneralPrtyBulletTitle'].value?.length > 0 && 
    this.parseHTMLContent(this.adminForm.controls['iseTabNodeGeneralPrtyBulletTitle'].value).trim()!=="") {
      this.deleteIETBulletBody={
        "ietbullet_id": this.generalIETBulletId,
        "iet_type": "GENERAL"
      }
      this.lookup.deleteIETBullet(this.deleteIETBulletBody).subscribe(_data=>{
        this.showGeneralPriorities();
        this.adminForm.reset();
        this.showTab='first';
        this.generalIETBulletId=false;
      })
    } else {
      this.showErrorMsg = true;
      this.errorMsg = this.errorService.bulletBodyErrMsg;
      setTimeout(()=>{ this.showErrorMsg = false; },3000)
    }
  }

  cancelIET(){
    this.adminForm.reset();
    this.selectedSubnode('', 'first');
    this.standardIETId = false;
  }

  cancelGeneralIET() {
    this.adminForm.reset();
    this.selectedSubnode('', 'first');
    this.generalIETId = false;
  }

  cancelIETBullet(){
    if (this.clickedAddStandardIETBullet) {
      this.clickedAddStandardIETBullet = false;
      this.selectedSubnode('', 'second');
    } else {
      this.adminForm.reset();
      this.selectedSubnode('', 'first');
    }
  }

  cancelGeneralIETBullet() {
    if (this.clickedAddGeneralIETBullet) {
      this.clickedAddGeneralIETBullet = false;
      this.selectedSubnode('', 'second');
    } else {
      this.adminForm.reset();
      this.selectedSubnode('', 'first');
    }
  }

  deleteGeneralIETSubbullet() {
    if(this.parseHTMLContent(this.adminForm.controls['iseTabSubNodeGeneralPrtyValue'].value).trim()!=="" && this.adminForm.controls['iseTabSubNodeGeneralPrtyValue'].value!==null){
    this.deleteIETBulletBody={
      "ietbullet_id": this.generalIETSubBulletId,
      "iet_type": "GENERAL"
    }
    this.lookup.deleteIETBullet(this.deleteIETBulletBody).subscribe(_data=>{
      this.showGeneralPriorities();
      this.adminForm.reset();
      this.showTab='first';
      this.generalIETSubBulletId=false;
    })}
    else {
      this.showErrorMsg = true;
      this.errorMsg = this.errorService.subbulletBodyErrMsg;
      setTimeout(()=>{ this.showErrorMsg = false; },3000)
    }
  }

  cancelGeneralIETSubbullet() {
     if(this.clickedAddGeneralIETSubbullet === true) {
      this.clickedAddGeneralIETSubbullet = false;
      this.selectedSubnode('', 'third');
    } else {
      this.adminForm.reset();
      this.selectedSubnode('', 'first');
    }
  }

  showThemeOwners() {
    this.ngxSpinner.show()
    this.searchName = '';
    this.updatesThemePermissionsRequestBody = [];
    this.lookup.getManagerNames().subscribe({
      next: (data)=>{
        if(data['statusCode']===200 && data?.body?.response !== this.getManagerDetailsError && !data?.body?.errorResponse) {
          this.themeOwnersList=data.body.response;
          this.disableThemeOwnerButtons = false;
          this.themeOwnerfullname = this.themeOwnersList.map(value=> `${value.firstname} ${value.lastname}`);
        } else {
          this.themeOwnersList = this.themeOwnerfullname = []
          this.disableThemeOwnerButtons = true;
        }
      }, error: (_error) => {
        this.ngxSpinner.hide();
        this.disableThemeOwnerButtons = true;
      }
    });
    setTimeout(()=> { this.ngxSpinner.hide() },1000)
  }

  allNoneThemeOwners() {
    this.ngxSpinner.show()
    this.updatesThemePermissionsRequestBody = [];
    if(this.selectAllThemeOwners){
      for(const element of this.themeOwnersList){
        element.is_theme_owner=true;
      }
      this.tickThemeOwnerCheckbox(true);
    } else if (!this.selectAllThemeOwners){
      for(const element of this.themeOwnersList){
        element.is_theme_owner=false;
      }
      this.tickThemeOwnerCheckbox(false);
    }
    this.selectAllThemeOwners = !this.selectAllThemeOwners;
    setTimeout(()=>{ this.ngxSpinner.hide() },1000)
  }

  tickThemeOwnerCheckbox(check) {
    for (const element of this.themeOwnersList) {
      const themeOwnersChecked = {};
      themeOwnersChecked['upn'] = element.email;
      themeOwnersChecked['is_theme_owner'] = check;
      this.updatesThemePermissionsRequestBody.push(themeOwnersChecked);
    }
  }

  onCheckboxUpdate(email, check) {
    for(const element of this.themeOwnersList){
      let copy=false;
      if(element.email===email){
        element.is_theme_owner=check;
        const themeOwnersChecked = {};
        themeOwnersChecked['upn'] = element.email;
        themeOwnersChecked['is_theme_owner'] = element.is_theme_owner;
        for(const element of this.updatesThemePermissionsRequestBody){
          if(element.email===themeOwnersChecked['upn']){
            copy=true;
            element.is_theme_owner=check;
          }
        }
        if(copy!==true){
          this.updatesThemePermissionsRequestBody.push(themeOwnersChecked);
        }
      }
    }
  }
    
  applyPermissions() {
    this.ngxSpinner.show();
    this.applyPermissionsRequestbody={ "updates":this.updatesThemePermissionsRequestBody }
    this.admin.getapplyPermission(this.applyPermissionsRequestbody).subscribe((_data) =>{})
    setTimeout(()=> { this.ngxSpinner.hide(); },1000);
  }

  get filteredFullName(): string[] {
    return this.themeOwnerfullname.filter((val) => val.toLowerCase().includes(this.searchName.toLocaleLowerCase()))
  }

  parseHTMLContent(value: string) {
    const parser = new DOMParser();
    const parsedHTML = parser.parseFromString(value, 'text/html');
    return parsedHTML.body.textContent || '';
  }
}