
export const environment = {
    envName: 'qa',
    production: false,
    hostname: 'qa.app.toyota.com',
    apiBaseUrl: 'https://qa.app.toyota.com',
    logger: {
      level: 'DEBUG'
    },
    xApiKey: '',
    TENANT_ID: 'https://login.microsoftonline.com/9107b728-2166-4e5d-8d13-d1ffdf0351ef',
    CLIENT_ID: 'a71f3e2e-d297-45ea-9b99-0acc544385a7',
    REDIRECT_URL: 'https://qa.app.toyota.com',
    apiUrl: {
        lookupUrl: 'https://ymxs1e25ef.execute-api.us-west-2.amazonaws.com/qa/lookup/',
        adminUrl:  'https://ymxs1e25ef.execute-api.us-west-2.amazonaws.com/qa/admin/',
        suppliermanagUrl: 'https://ymxs1e25ef.execute-api.us-west-2.amazonaws.com/qa/management/',
        acknowledgementUrl: 'https://ymxs1e25ef.execute-api.us-west-2.amazonaws.com/qa/acknowledgement/'
    }
  };
  
