import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { GridApi } from 'ag-grid-community';
import { NgxSpinnerService } from 'ngx-spinner';
import { BusinessEntityService } from 'src/app/services/business-entity.service';
import { LookupService } from 'src/app/services/lookup.service';
import { ManagementService } from 'src/app/services/management.service';

@Component({
  selector: 'app-newiet',
  templateUrl: './newiet.component.html',
  styleUrl: './newiet.component.css'
})

export class NewietComponent implements OnInit{
  failureMessage:any;
  failureMessageAlert:any;
  ietForm:FormGroup;
  isOpen1=true;
  gridApi:GridApi;
  gridColumnApi:any;
  domLayout='autoHeight';
  breadcrumbItems=[
    {
      label: 'Home',
      link: '/home',
      icon: '>'
    },
    {
      label: 'View/Edit ISE',
      link: '/ise',
      icon: '>'
    },
    {
      label: 'Individual Expected Tasks',
      link: null,
      icon: ''
    },
  ];
  bullets:any= [];
  subBullets: string[] = [];
  selectedIndex: number | null = 0;
  selectedSubBulletIndex: number | null = null;
  showBulletModal = false;
  showSubBulletModal = false;
  bulletText = '';
  subBulletText = '';
  subBulletsMap: { [key: number]: string[] } = {};
  isEditMode = false;
  isEditSubBulletMode = false;
  isBulletReorder = false;
  isSubBulletReorder = false;
  isdeleteBullet = false;
  isdeleteSubBullet = false;
  ietid:any;
  addBulletBody:any=[];
  editBulletBody:any=[];
  addSubBulletBody:any=[];
  editSubBulletBody:any=[];
  appNewIETBody:any=[];
  standardIET:any=[];
  allowAddBullet = false;
  isSelectedSupplierInOpenStatus: boolean;
  approvalStatusRequestBody: any;

  constructor(private readonly ngxSpinner:NgxSpinnerService,private readonly lookup:LookupService,private readonly management:ManagementService,
    private readonly formBuilder:FormBuilder,private readonly router: Router ,public bService:BusinessEntityService){}

  ngOnInit(): void {
    this.ngxSpinner.show();
    this.ietForm=this.formBuilder.group({
      heading:new FormControl(''),
      body:new FormControl(''),
      status:new FormControl(''),
      open:new FormControl('')
    })
    this.isSelectedSupplierInOpenStatus = localStorage.getItem("isSupplierInOpenStatus") === "true";
    this.showStandardIET();
    // this.showIET();
    // this.showIETBulletsByIETId();
    setTimeout(()=> {
      this.ngxSpinner.hide();
    },1000);
  }

  showStandardIET(){
    this.lookup.getStandardIETNamesInCurrentFY().subscribe(data=>{
      this.standardIET=data.body.response;
    })
  }

  submit(){
    
    let open:boolean;
    if(this.ietForm.controls['open'].value==="true"){
      open=true
    }
    else{
      open=false;
    }
    this.appNewIETBody = {
      "appparentid": sessionStorage.getItem("appParentId"),
      "statusid": 1,
      "description": this.ietForm.controls['body'].value,
      "name": this.ietForm.controls['heading'].value,
      "ietorder": 1,
      "inactive": false,
      "openfrompreviousyear": open
    }
    if(this.ietForm.controls['heading'].value && this.ietForm.controls['body'].value &&
    (this.ietForm.controls['heading'].value).trim()!=="" && (this.ietForm.controls['body'].value).trim()!==""){
      this.ngxSpinner.show();
    this.lookup.appNewIET(this.appNewIETBody).subscribe(data=>{
      this.ietid=data.body.response.ietid;
      let i=0;
      for(const element of this.newIETs){
        element['individualExpectedTaskId']=this.ietid;
        element['ietBulletOrder']=i;
        this.lookup.insertAPPIetBullet(element).subscribe(_data=>{
        })
        i++;
      }
      setTimeout(()=> {
        this.ngxSpinner.hide();
        this.router.navigate(['/ise']);
      },4000);
      if(this.isSelectedSupplierInOpenStatus) {
        this.approvalStatusRequestBody = {
          appParentId: sessionStorage.getItem("appParentId"),
          fiscalId: localStorage.getItem("fiscalId"),
          saveFlag: true,
          readyForReviewFlag: false,
          backToBuyerFlag: false,
          comment: '',
          empId: this.bService.loggedUserEmployeeId
        }
        this.management.approvalStatus(this.approvalStatusRequestBody).subscribe(_data => {
        });
      }
    })}
    else{
      window.scroll(0,0);
      this.failureMessage="Please fill the heading & body of new IET";
      this.failureMessageAlert=true;
      setTimeout(() => {
        this.failureMessageAlert=false;
      }, 10000);
    }
    
  }

  delete(){
    this.ngxSpinner.show();
    this.lookup.appIETDelete(this.ietid).subscribe(_data=>{
      this.router.navigate(['/ise']);
    })
  }

  cancel(){
    this.ngxSpinner.show();
    this.router.navigate(['/ise']);
  }

  showIET(){
    this.lookup.getIET(this.ietid).subscribe(data=>{
      this.ietForm.controls['heading'].setValue(data.body.response[0].name);
      this.ietForm.controls['body'].setValue(this.parseHTMLContent(data.body.response[0].description));
      this.ietForm.controls['status'].setValue(data.body.response[0].statusid);
      this.ietForm.controls['open'].setValue((data.body.response[0].openfrompreviousyear).toString());
    })
  }

  parseHTMLContent(value: string) {
    const parser = new DOMParser();
    const parsedHTML = parser.parseFromString(value, 'text/html');
    return parsedHTML.body.textContent || '';
  }

  showIETBulletsByIETId(){
    this.lookup.getIETBulletsByIETId(this.ietid).subscribe(data=>{
      if(data.body.response!=='No Bullets available for this IETID' && data.body.response!=="Something went wrong"){
      this.bullets=data.body.response; 
      }
    })
  }

  showIETSubBulletsByIETId(){
    this.lookup.getIETSubBulletsByIETId(this.bullets[this.selectedIndex]['ietbulletid']).subscribe(data=>{
      if(data.body.response!=='No Subbullets available for this IETID'){
      this.subBulletsMap[this.selectedIndex]=data.body.response; }
    })
  }

  openAddBulletModal(){
    this.isEditMode = false;
    this.bulletText = '';
    this.showBulletModal = true;
    this.isdeleteBullet = false;
    if(this.bulletText !== '') {
      this.allowAddBullet = true;
    } else {
      this.allowAddBullet = false;
    }
  }

  onInputChange(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    if(inputElement.value !== '') {
      this.allowAddBullet = true;
    } else {
      this.allowAddBullet = false;
    }
  }

  openEditBulletModal() {
    if(this.selectedIndex !== null) {
      this.isEditMode = true;
      this.bulletText = this.parseHTMLContent(this.bullets[this.selectedIndex]['bullet']);
      this.showBulletModal = true;
      this.isdeleteBullet = true;
    }
  }

  toggleBulletReorder(){
    this.isBulletReorder = !this.isBulletReorder;
  }

  toggleSubBulletReorder(){
    this.isSubBulletReorder = !this.isSubBulletReorder;
  }

  addBullet(){
    if(this.bulletText.trim()) {
      this.bullets.push({'bullet':this.bulletText});
      this.subBulletsMap[this.bullets.length - 1] = [];
    }
    // this.addBulletBody={
    //   "individualExpectedTaskId": sessionStorage.getItem("ietid"),
    //   "bullet": this.bulletText,
    //   "ietBulletOrder": -1,
    //   "inactive": false,
    //   "user": sessionStorage.getItem("assumeEmployeeid")
    // }
    // this.lookup.insertAPPIetBullet(this.addBulletBody).subscribe(_data=>{
    //   this.showIETBulletsByIETId();
    // })
    const iselist = {};
          iselist['individualExpectedTaskId'] = '';
          iselist['bullet'] = this.bulletText;
          iselist['ietBulletOrder'] = -1;
          iselist['inactive'] = false;
          iselist['user'] =  this.bService.loggedUserEmployeeId;
    this.newIETs.push(iselist)
    this.closeBulletModal();
  }

  editBullet() {
    if(this.selectedIndex !== null && this.bulletText.trim()){
      this.bullets[this.selectedIndex]['bullet'] = this.bulletText;
      this.newIETs[this.selectedIndex]['bullet'] = this.bulletText;
    }
    // this.editBulletBody={
    //   "ietBulletId": this.bullets[this.selectedIndex]['ietbulletid'],
    //   "bullet": this.bulletText,
    //   "ietBulletOrder": this.bullets[this.selectedIndex]['ietbulletorder'],
    //   "user": sessionStorage.getItem("assumeEmployeeid")
    // }
    // this.lookup.editAPPIetBullet(this.editBulletBody).subscribe(_data=>{
    //   this.showIETBulletsByIETId();
    // })
    this.closeBulletModal();
  }

  deleteBullet() {
    if(this.selectedIndex !== null){
      // this.lookup.appDeleteIETBullet(this.bullets[this.selectedIndex]['ietbulletid']).subscribe(_data=>{
      // })
      this.bullets.splice(this.selectedIndex, 1);
      this.newIETs.splice(this.selectedIndex, 1);
      delete this.subBulletsMap[this.selectedIndex];
      this.selectedIndex = null;
    }
    this.closeBulletModal();
  }

  closeBulletModal() {
    this.showBulletModal = false;
    this.bulletText = '';
  }

  selectBullet(index: number, event: Event) {
    if(event.type === 'keyup' || event.type === 'click') {
      this.selectedIndex = index;
      this.selectedSubBulletIndex = null;
      this.subBulletText = '';
      // this.showIETSubBulletsByIETId();
    }
  }

  openAddSubBulletModal(){
    if(this.selectedIndex !== null) {
      this.isEditSubBulletMode = false;
      this.subBulletText = '';
      this.showSubBulletModal = true;
      this.isdeleteSubBullet = false;
    }
  }

  openEditSubBulletModal(index: number | null) {
    if(this.selectedIndex !== null && index !== null && this.subBulletsMap[this.selectedIndex]) {
      this.isEditSubBulletMode = true;
      this.selectedSubBulletIndex = index;
      this.subBulletText = this.parseHTMLContent(this.subBulletsMap[this.selectedIndex][index]['subbullet']);
      this.showSubBulletModal = true;
      this.isdeleteSubBullet = true;
    }
  }

  addSubBullet(){
    if(this.selectedIndex !== null && this.subBulletText.trim()){
      if(!this.subBulletsMap[this.selectedIndex]) {
        this.subBulletsMap[this.selectedIndex] = [];
      }
      this.subBulletsMap[this.selectedIndex].push(this.subBulletText);
    }
    this.addSubBulletBody={
      "ietBulletId": this.bullets[this.selectedIndex]['ietbulletid'],
      "subBullet": this.subBulletText,
      "subBulletOrder": -1,
      "user": this.bService.loggedUserEmployeeId
    }
    this.lookup.insertAPPIetSubBullet(this.addSubBulletBody).subscribe(_data=>{
      this.showIETSubBulletsByIETId();
    })
    this.closeSubBulletModal();
  }

  editSubBullet() {
    if(this.selectedIndex !== null && this.selectedSubBulletIndex !== null && this.subBulletText.trim()){
      this.subBulletsMap[this.selectedIndex][this.selectedSubBulletIndex]['subbullet'] = this.subBulletText;
    } 
    this.editSubBulletBody={
      "subBulletId": this.subBulletsMap[this.selectedIndex][this.selectedSubBulletIndex]['subbulletid'],
      "ietBulletId": this.bullets[this.selectedIndex]['ietbulletid'],
      "subBullet": this.subBulletText,
      "ietBulletOrder": this.subBulletsMap[this.selectedIndex][this.selectedSubBulletIndex]['subbulletorder'],
      "user": this.bService.loggedUserEmployeeId
    }
    this.lookup.editAPPIetSubBullet(this.editSubBulletBody).subscribe(_data=>{
      this.showIETSubBulletsByIETId();
    })
    this.closeSubBulletModal();
  }

  deleteSubBullet() {
    if(this.selectedIndex !== null && this.selectedSubBulletIndex !== null){
      this.lookup.appDeleteIETSubBullet(this.subBulletsMap[this.selectedIndex][this.selectedSubBulletIndex]['subbulletid']).subscribe(_data=>{
      })
      this.subBulletsMap[this.selectedIndex].splice(this.selectedSubBulletIndex, 1);
      this.selectedSubBulletIndex = null;
    }
    this.closeSubBulletModal();
  }

  closeSubBulletModal() {
    this.showSubBulletModal = false;
    this.subBulletText = '';
    this.selectedSubBulletIndex = null;
  }

  selectSubBullet(index: number, event: Event) {
    if(event.type === 'keyup' || event.type === 'click') {
      this.selectedSubBulletIndex = index;
    }
  }

  moveUp(){
    if(this.selectedIndex !== null && this.selectedIndex > 0) {
      // this.lookup.appUpdateBulletOrder(this.bullets[this.selectedIndex]['ietbulletid'],this.bullets[this.selectedIndex]['ietbulletorder']-1).subscribe(_data=>{
      //   this.bullets[this.selectedIndex]['ietbulletorder']-=1;
      // })
      this.bullets[this.selectedIndex]['ietBulletOrder']=this.bullets[this.selectedIndex-1]['ietBulletOrder']-1;
      const currentBullet = this.bullets[this.selectedIndex];
      this.bullets[this.selectedIndex] = this.bullets[this.selectedIndex - 1];
      this.bullets[this.selectedIndex - 1] = currentBullet;

      this.newIETs[this.selectedIndex]['ietBulletOrder']=this.newIETs[this.selectedIndex-1]['ietBulletOrder']-1;
      const currentBullet2 = this.newIETs[this.selectedIndex];
      this.newIETs[this.selectedIndex] = this.newIETs[this.selectedIndex - 1];
      this.newIETs[this.selectedIndex - 1] = currentBullet2;

      const currentSubBullet = this.subBulletsMap[this.selectedIndex];
      this.subBulletsMap[this.selectedIndex] = this.subBulletsMap[this.selectedIndex - 1];
      this.subBulletsMap[this.selectedIndex - 1] = currentSubBullet;
      this.selectedIndex--;
      
    }
  }

  moveDown(){
    if(this.selectedIndex !== null && this.selectedIndex < this.bullets.length - 1) {
      // this.lookup.appUpdateBulletOrder(this.bullets[this.selectedIndex]['ietbulletid'],this.bullets[this.selectedIndex]['ietbulletorder']+1).subscribe(_data=>{
      //   this.bullets[this.selectedIndex]['ietbulletorder']+=1;
      // })
      this.bullets[this.selectedIndex]['ietBulletOrder']=this.bullets[this.selectedIndex+1]['ietBulletOrder']+1;
      const currentBullet = this.bullets[this.selectedIndex];
      this.bullets[this.selectedIndex] = this.bullets[this.selectedIndex + 1];
      this.bullets[this.selectedIndex + 1] = currentBullet;

      this.newIETs[this.selectedIndex]['ietBulletOrder']=this.newIETs[this.selectedIndex+1]['ietBulletOrder']+1;
      const currentBullet2 = this.newIETs[this.selectedIndex];
      this.newIETs[this.selectedIndex] = this.newIETs[this.selectedIndex + 1];
      this.newIETs[this.selectedIndex + 1] = currentBullet2;

      const currentSubBullet = this.subBulletsMap[this.selectedIndex];
      this.subBulletsMap[this.selectedIndex] = this.subBulletsMap[this.selectedIndex + 1];
      this.subBulletsMap[this.selectedIndex + 1] = currentSubBullet;
      this.selectedIndex++;
    }
  }

  moveSubBulletUp(){
    if(this.selectedIndex !== null && this.selectedSubBulletIndex !== null && this.selectedSubBulletIndex > 0){
      this.lookup.appUpdateSubBulletOrder(this.subBulletsMap[this.selectedIndex][this.selectedSubBulletIndex]['subbulletid'],this.subBulletsMap[this.selectedIndex][this.selectedSubBulletIndex]['subbulletorder']-1).subscribe(_data=>{
        this.subBulletsMap[this.selectedIndex][this.selectedSubBulletIndex]['subbulletorder']-=1;
      })
      const subBullet = this.subBulletsMap[this.selectedIndex];
      const currentSubBullet = subBullet[this.selectedSubBulletIndex];
      subBullet[this.selectedSubBulletIndex] = subBullet[this.selectedSubBulletIndex - 1];
      subBullet[this.selectedSubBulletIndex - 1] = currentSubBullet;
      this.selectedSubBulletIndex--;
    }
  }

  moveSubBulletDown(){
    if(this.selectedIndex !== null && this.selectedSubBulletIndex !== null && this.selectedSubBulletIndex < (this.subBulletsMap[this.selectedIndex]?.length ?? 0) - 1){
      this.lookup.appUpdateSubBulletOrder(this.subBulletsMap[this.selectedIndex][this.selectedSubBulletIndex]['subbulletid'],this.subBulletsMap[this.selectedIndex][this.selectedSubBulletIndex]['subbulletorder']+1).subscribe(_data=>{
        this.subBulletsMap[this.selectedIndex][this.selectedSubBulletIndex]['subbulletorder']+=1;
      })
      const subBullet = this.subBulletsMap[this.selectedIndex];
      const currentSubBullet = subBullet[this.selectedSubBulletIndex];
      subBullet[this.selectedSubBulletIndex] = subBullet[this.selectedSubBulletIndex + 1];
      subBullet[this.selectedSubBulletIndex + 1] = currentSubBullet;
      this.selectedSubBulletIndex++;
    }
  }

  onGridReady(params:any){
    this.gridApi = params?.api;
    this.gridColumnApi = params?.columnApi;
    this.gridApi?.sizeColumnsToFit(); 
  }
  @HostListener("window:resize", ['$event'])
  onResize() {

    if (this.gridApi != null) {

      this.gridApi?.sizeColumnsToFit();
      this.gridApi.refreshCells();
    }

  }
  selectedValue:any;
  standietid:any;
  IETs:any=[];
  newIETs:any=[];
  titleIET(e){ 
    this.bullets=[];
    this.newIETs=[];
    this.standietid=e;
    for (const element of this.standardIET) {
      if (Number(element.standardietid) === Number(e)) { 
        this.ietForm.controls['heading'].setValue(element.title);
        this.ietForm.controls['body'].setValue(this.parseHTMLContent(element.body));
      }
    }
    this.lookup.getStandardIETBulletDesc(this.standietid).subscribe(data=>{
      if(data.body.response!=="Something went wrong"){
      this.bullets=data.body.response;
      for (const element of this.bullets) {
        const iselist = {};
        iselist['individualExpectedTaskId'] = '';
        iselist['bullet'] = element.bullet;
        iselist['ietBulletOrder'] = -1;
        iselist['inactive'] = false;
        iselist['user'] = this.bService.loggedUserEmployeeId;
        this.newIETs.push(iselist)
      }
    }
    })
  }

}
