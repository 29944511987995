<div class="alert-success" *ngIf="showSuccessMsgTier2Results || showSuccessMsgOEReport">
    <img style="margin-right: 1em;" src="../assets/images/checkCircle.svg" alt="successMsg">
    <span class="alert-close" >&times;</span>
    <span class="label-tmna ms-3"> File Uploaded Successfully. </span>
</div>

<div class="alert-success" *ngIf="showInprogressMsgOEReport">
    <img style="margin-right: 1em;" src="../assets/images/checkCircle.svg" alt="successMsg">
    <span class="alert-close" >&times;</span>
    <span class="label-tmna ms-3"> OE Upload In-progress. We'll notify once it's completed. </span>
</div>

<div class="alert-failure" *ngIf="showErrorMsgTier2Results || showErrorMsgOEReport">
    <img style="margin-right: 1em;" src="../assets/images/errCircle.png" alt="failureMsg">
    <span class="alert-close" >&times;</span>
    <span class="label-tmna ms-3"> File Upload Failed. Please review and upload the file again. </span>
</div>

<div class="alert-failure" *ngIf="showFileFormatError">
    <img style="margin-right: 1em;" src="../assets/images/errCircle.png" alt="failureMsg">
    <span class="alert-close" >&times;</span>
    <span class="label-tmna ms-3"> Please upload the file with .xlsx format. </span>
</div>

<div class="d-flex filecontainer">
    <span class="subHeading" style="font-weight: 600;color: #212529">NOTE: Please download the template and upload the updated file. Kindly make sure no null values are entered in the file.</span>
    
</div>
<div class="d-flex filecontainer">
    <div class="label-tmna">
        Upload Tier II Results :</div>
    <div>
        <button class="download-template" (click)="downloadTemplateFile('tier2')">DOWNLOAD TEMPLATE</button>
    </div>
    <div class="row">
        <div class="d-flex">
            <a style="display: inline;position: relative;left:10px;bottom:3px;" (click)="fileInputOne.click()">
                <label for="uploadFileLabel">
                    <img class="imgUld uploadFile" src="../assets/images/upload-icon.svg" alt="img" />
                </label>
            </a>
            <div class="ms-2">
                <span class="label-tmna ms-3" *ngIf="!inputone"> No files chosen </span>
                <input type="file" #fileInputOne (change)="onFileSelectedOne($event)" accept=".xlsx" style="display: none;" />
                <div class="attachment" *ngIf="inputone">
                    <label for="attachedFileName" class="label-tmna labelupload">{{filenameone}}</label>
                    <button type="button" class="btn-close removeattach" (click)="removeAttachmentOne()"
                        (keyup)="removeAttachmentOne()">
                        <img src="\assets\images\close-icon.png" height="15" width="15" alt="Remove Attachment">
                    </button>
                </div>
            </div>
        </div>
        
    </div>
</div>
<div class="d-flex filecontainer mb-5">
    <div class="label-tmna" style="margin-right: 1rem;">
        Upload OE Targets :</div>
    <div>
        <button class="download-template" (click)="downloadTemplateFile('oeTarget')">DOWNLOAD TEMPLATE</button>
    </div>
    <div class="row">
        <div class="d-flex">
            <a style="display: inline;position: relative;left:10px;bottom:3px;" (click)="fileInputTwo.click()">
                <label for="uploadFileLabel">
                    <img class="imgUld uploadFile" src="../assets/images/upload-icon.svg" alt="img" />
                </label>
            </a>
            <div class="ms-2">
                <span class="label-tmna ms-3" *ngIf="!inputtwo"> No files chosen </span>
                <input type="file" #fileInputTwo (change)="onFileSelectedTwo($event)" accept=".xlsx" style="display: none;" />
                <div class="attachment" *ngIf="inputtwo">
                    <label for="attachedFileName" class="label-tmna labelupload">{{filenametwo}}</label>
                    <button type="button" class="btn-close removeattach" (click)="removeAttachmentTwo()"
                        (keyup)="removeAttachmentTwo()">
                        <img src="\assets\images\close-icon.png" height="15" width="15" alt="Remove Attachment">
                    </button>
                </div>
                <span class="label-tmna showInprogress ms-3" *ngIf="inprogressMsg === 'inprogress' && !showInprogressMsgOEReport">OE Upload In-progress</span>
            </div>
        </div>
    </div>
</div>