import { Component } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { lastValueFrom } from 'rxjs';
import { ErrorMessagesService } from 'src/app/services/error-messages.service';
import { LookupService } from 'src/app/services/lookup.service';

@Component({
  selector: 'app-apply-standardiet-supplier',
  templateUrl: './apply-standardiet-supplier.component.html',
  styleUrl: './apply-standardiet-supplier.component.css'
})
export class ApplyStandardietSupplierComponent {
  
  standardIET:any;
  updatesStandardIETBody:any=[];
  applyStandardIETBody:any;
  suppliersList = [];
  searchFieldSuppliersList = [];
  titleStandardIET:any;
  titleStandIET:any;
  toggleStandardIET:any=true;
  inputSearchSuppliersList = '';
  showNoStandardIETErrMsg: boolean;
  pageErrorMsg: string;
  disableButtons = true;

  constructor(private readonly ngxSpinner:NgxSpinnerService, private readonly lookup:LookupService, private readonly errorService:ErrorMessagesService) {}
  
  ngOnInit() {
    this.showStandardIET()
  }
  
  async showStandardIET(){
    this.ngxSpinner.show()
    try {
      const data = await lastValueFrom(this.lookup.getStandardIETNamesInCurrentFY())
      if(data['statusCode'] === 200 && data?.body?.response.length > 0 && !data?.body?.errorResponse) {
        this.disableButtons = false
        this.standardIET=data.body.response;
        this.titleIET(this.standardIET[0].title);
      } else {
        this.pageErrorMsg = this.errorService.serverErrMsg
        this.showNoStandardIETErrMsg = true
        this.disableButtons = true
        setTimeout(()=>{
          this.showNoStandardIETErrMsg = false
        }, 5000)
      }
    } catch {
      this.ngxSpinner.hide()
      this.pageErrorMsg = this.errorService.serverErrMsg
      this.showNoStandardIETErrMsg = true
      this.disableButtons = true
      setTimeout(()=>{
        this.showNoStandardIETErrMsg = false
      }, 5000)
    } finally {
      this.ngxSpinner.hide()
    }
  }

  titleIET(title){ 
    this.ngxSpinner.show();
    this.updatesStandardIETBody=[];
    this.titleStandardIET=title;
    this.lookup.getSuppliersByIETName(this.titleStandardIET).subscribe({
      next: data=>{
        this.suppliersList=data.body.response;
        this.searchFieldSuppliersList = data.body.response;
        this.inputSearchSuppliersList='';
        setTimeout(()=> {
          this.ngxSpinner.hide();
        },1000);
      }, error: (_error) => {
        this.ngxSpinner.hide()
      }
    })
    
  }

  checkIET(description,checked){
    for(const element of this.suppliersList){
      let copy=false;
      if(element.description===description){
        element.checked=checked;
          const supplist = {};
          supplist['suppliername'] = element.description;
          supplist['checked'] = element.checked;
          supplist['ietname'] = this.titleStandardIET;
          for(const element of this.updatesStandardIETBody){
            if(element.suppliername===supplist['suppliername']){
              copy=true;
              element.checked=checked;
            }
          }
          if(copy!==true){
          this.updatesStandardIETBody.push(supplist);
          }
      }
    }
  }

  allnoneIET(){
    this.updatesStandardIETBody=[];
    if(this.toggleStandardIET){
      for(const element of this.suppliersList){
        element.checked=true;
      }
      this.tickIET(true);
    }
    else{
      for(const element of this.suppliersList){
        element.checked=false;
      }
      this.tickIET(false);
    }
    this.toggleStandardIET=!this.toggleStandardIET;
  }

  tickIET(check){
    for (const element of this.suppliersList) {
      const supplist = {};
      supplist['suppliername'] = element.description;
      supplist['checked'] = check;
      supplist['ietname'] = this.titleStandardIET;
      this.updatesStandardIETBody.push(supplist);
    }
  }

  applyStandardIET(){
    this.ngxSpinner.show();
    this.applyStandardIETBody={"updates":this.updatesStandardIETBody
    }
    this.lookup.applyStandardIET(this.applyStandardIETBody).subscribe(_data=>{
      setTimeout(()=> {
        this.ngxSpinner.hide();
      },1000);
    })
    
  }

  filteredSuppliersList() {
    if(this.inputSearchSuppliersList === '') {
      this.suppliersList = this.searchFieldSuppliersList;
    } else {
      this.suppliersList = this.searchFieldSuppliersList.filter((value) => 
        value.description.toLowerCase().includes(this.inputSearchSuppliersList.toLocaleLowerCase())
      )
    }
  }
}
