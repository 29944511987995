import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AcknowledgementService } from '../services/acknowledgement.service';

@Component({
  selector: 'app-supplier-acknowledgement',
  templateUrl: './supplier-acknowledgement.component.html',
  styleUrl: './supplier-acknowledgement.component.css'
})
export class SupplierAcknowledgementComponent {

  supplierAckForm: FormGroup;
  responseId: string;
  alreadyAcknowledged = false;
  fiscalYear: number;
  responseKeyValue: string;
  noResponseKeyInUrl = false;
  supplierName = '';
  updated = '';
  dueDate = '';
  acknowledgementText = '';

  constructor(private readonly fb: FormBuilder, private readonly ngxSpinner:NgxSpinnerService,
    private readonly acknowledgement: AcknowledgementService
  ) {
    this.supplierAckForm = this.fb.group({
      greetings: ['', [Validators.required]],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      title: ['', [Validators.required]],
      email: ['',[Validators.required, Validators.email]],
      checkboxAcknowledge: [false, Validators.requiredTrue]
    })
  }

  salutations = ['Mr.', 'Mrs.', 'Ms.'];
  submitRequestBody: {
    greeting: string,
    firstname: string,
    lastname: string,
    contacttitle: string,
    email: string,
    responseId: string
  }
  supplierAckData: any;

  ngOnInit() {
    this.ngxSpinner.show();
    this.getSupplierAcknowledgementData();
    setTimeout(()=>{
      this.ngxSpinner.hide();
    },2000)
  }

  getSupplierAcknowledgementData() {
    //get response key value from url
    const key = this.getResponseKeyParameter('RKey');
    this.responseKeyValue = key;

    //getApi with the responseKeyValue as payload to get below response
    if (this.responseKeyValue && this.responseKeyValue.length === 36){
      this.acknowledgement.getSupplierAcknowledgementReceipt(this.responseKeyValue).subscribe((data)=>{
        this.supplierAckData = data.body.response;
        this.supplierAcknowledgementData();
      });
    } else {
      this.noResponseKeyInUrl = true;
    }
  }

  supplierAcknowledgementData() {
    if(this.supplierAckData === "No Details available for this supplier") {
      this.noResponseKeyInUrl = true;
    } else if(this.supplierAckData && this.supplierAckData.length > 0) {
      this.fiscalYear = this.supplierAckData[0]?.year ? (this.supplierAckData[0]?.year % 100) + 1 : null;
      this.fiscalYear = this.formatFiscalYear(this.fiscalYear);
      if(this.supplierAckData[0]?.response === true){
        this.alreadyAcknowledged = true;
      } else {
        this.alreadyAcknowledged = false;
      }
      this.supplierAckForm = this.fb.group({
        greetings: [this.supplierAckData[0].greeting ?? '', [Validators.required]],   //When response is null, setting empty string and showing error validation
        firstName: [this.supplierAckData[0].firstname ?? '', [Validators.required]],
        lastName: [this.supplierAckData[0].lastname ?? '', [Validators.required]],
        title: [this.supplierAckData[0].contacttitle ?? '', [Validators.required]],
        email: [this.supplierAckData[0].email ?? '',[Validators.required, Validators.email]],
        checkboxAcknowledge: [this.alreadyAcknowledged, Validators.requiredTrue]
      }) 
      this.supplierName = this.supplierAckData[0].suppliername;
      this.acknowledgementText = this.supplierAckData[0].acknowledgementtext;
      this.updated = this.convertToMMDDYYYY(this.supplierAckData[0].updated);
      this.dueDate = this.convertToMMDDYYYY(this.supplierAckData[0].duedate);
    }

    //Disable if alreadyAcknowledged
    this.disableSupplierAckForm();
  }

  formatFiscalYear(value){
    if(value === null) {
      return '';
    } else {
      return value.toString().padStart(2, '0');
    }
  }

  convertToMMDDYYYY(isodate) {
    return isodate ? this.formatDate(isodate) : '' ;
  }

  formatDate(dateString: string): string {
    //From backend we are receiving timestamp in MST format ending with Z, so we are using UTC to display as same in all timestamp displaying places
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getUTCFullYear();
  
    return `${month}/${day}/${year}`;
  }

  getResponseKeyParameter(param: string): string | null {
    const urlParam = new URLSearchParams(window.location.search);
    for (const [key, value] of urlParam.entries() ){
      if(key.toLowerCase() === param.toLowerCase()) {
        return value;
      }
    }
    return null;
  }

  onSubmit() {
    //If not acknowledged, need to trigger api to pass the entered values
    this.submitRequestBody = {
      greeting: this.supplierAckForm.value.greetings,
      firstname: this.supplierAckForm.value.firstName,
      lastname: this.supplierAckForm.value.lastName,
      contacttitle: this.supplierAckForm.value.title,
      email: this.supplierAckForm.value.email,
      responseId: this.responseKeyValue
    }
    if(this.supplierAckForm.valid && !this.alreadyAcknowledged) {
      const formData = this.submitRequestBody;
      this.acknowledgement.updateSupplierAcknowledgementReceipt(formData).subscribe((_data)=>{
        this.ngxSpinner.show();
        this.getSupplierAcknowledgementData(); //Calling get API To update with latest status in the screen
        setTimeout(()=> {
          this.ngxSpinner.hide();
        },2000);
      });
    } else {
      this.supplierAckForm.markAllAsTouched();
    }
  }

  disableSupplierAckForm() {
    if(this.alreadyAcknowledged === true) {
      this.supplierAckForm.controls['greetings'].disable();
      this.supplierAckForm.controls['firstName'].disable();
      this.supplierAckForm.controls['lastName'].disable();
      this.supplierAckForm.controls['email'].disable();
      this.supplierAckForm.controls['title'].disable();
      this.supplierAckForm.controls['checkboxAcknowledge'].disable();
    }
  }

  get form() {
    return this.supplierAckForm.controls;
  }

}
