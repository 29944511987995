import { Component, HostListener, ViewChild } from '@angular/core';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { GridApi, RowGroupingDisplayType } from 'ag-grid-community';
import 'ag-grid-enterprise';
import { NgxSpinnerService } from 'ngx-spinner';
import { LookupService } from '../services/lookup.service';
import { distinctUntilChanged, lastValueFrom } from 'rxjs';
import { ManagementService } from '../services/management.service';
import { BusinessEntityService } from '../services/business-entity.service';
import { AgGridSortingService } from '../services/ag-grid-sorting.service';
import { Router } from '@angular/router';
import { ErrorMessagesService } from '../services/error-messages.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrl: './reports.component.css'
})
export class ReportsComponent {
  @ViewChild('tabGroup') tabGroup: MatTabGroup;
  selectedTabIndex = 0;
  domLayout = 'autoHeight';
  currentYear = Number(localStorage.getItem("fY")) !== 0 ? Number(localStorage.getItem("fY")) : new Date().getFullYear();
  tooltipShowDelay = 100;
  gridApi: GridApi;
  gridColumnApi: any;
  showPrevYearsHistory = false;
  @ViewChild('grid1') grid1: any;
  @ViewChild('grid2') grid2: any;
  gridApiMap = new Map<string, any>();
  showModel = false;
  selectedIETName: any;
  fiscalYear: any;
  employeeid: any;
  supplierIETRequestBody: {
    fiscalYear: number,
    employeeId: number,
    page: number,
    limit: number
  }
  updateCommentsRequestBody: {
    appParentId: number,
    fiscalId: number,
    comment: string,
    empId: number

  }
  ietid: number;
  supplierIETHeading = '';
  supplierIETBody = '';
  bullets = [];
  subBulletsMap: { [key: number]: string[] } = {};
  subbullets = [];
  selectedIndex: number | null = 0;
  selectedSubBulletIndex: number | null = null;
  validation: any;
  showReportingSummaryErrMsg: boolean;
  showIETSummaryErrMsg: boolean;
  supplierIETSummaryErrorMessage: string;
  supplierReportingErrorMessage: string;
  limit = 1000;
  totalNumberOfReportingSummaryData: any;
  totalNumberOfIETSummaryData: any;

  onTabChanged(event: MatTabChangeEvent) {
    if(event.index === 0) {
      if(this.rowDataforSupplierReportingSummary.length <= 0) {
        this.getSupplierReportingSummary(1);
      }
      this.gridApiMap.get('grid1').sizeColumnsToFit();
    } else if (event.index === 1) {
      if(this.rowDataforSupplierIETSummary.length <= 0) {
        this.getSupplierListAndISE(1);
      }
      this.gridApiMap.get('grid2').sizeColumnsToFit()
    }
  }

  constructor(private readonly router: Router,private readonly ngxSpinner:NgxSpinnerService,
    private readonly lookup: LookupService, private readonly sortService: AgGridSortingService,
    private readonly management: ManagementService, private readonly bService: BusinessEntityService,
    private readonly errorService: ErrorMessagesService) { 
      this.columnDefSupplierReportingSummary = [
        {
          headerName: 'SUPPLIER',field: 'supplier',
          minWidth: 240,flex: 3,
          comparator: this.sortService.stringComparator
        },
        {
          headerName: 'LEAD BUYER CODE',field: 'leadbuyercode',
          flex: 1,comparator: this.sortService.stringComparator
        },
        {
          headerName: 'LEAD BUYER',field: 'leadbuyer',
          flex: 1,comparator: this.sortService.stringComparator
        },
        {
          headerName: 'ASSISTANT MANAGER',field: 'assistantmanager',
          flex: 2,comparator: this.sortService.stringComparator
        },
        {
          headerName: 'MANAGER',field: 'manager',
          flex: 1,comparator: this.sortService.stringComparator
        },
        {
          headerName: 'APPROVAL STATUS',field: 'status',
          flex: 1,comparator: this.sortService.stringComparator
        },
        {
          headerName: `CURRENT APR COST REDUCTION TARGET (${this.currentYear})`,field: 'aprcostreductiontarget',
          flex: 1,minWidth: 220,comparator: this.sortService.mixedValueComparator
        },
        {
          headerName: `PREVIOUS APR COST REDUCTION TARGET (${this.currentYear - 1})`,field: 'previousaprcostreductiontarget',
          flex: 1,minWidth: 220,hide: true,comparator: this.sortService.mixedValueComparator
        },
        {
          headerName: `PREVIOUS APR COST REDUCTION TARGET (${this.currentYear - 2})`,field: 'previous2ndaprcostreductiontarget',
          flex: 1, minWidth: 220,hide: true,comparator: this.sortService.mixedValueComparator
        },
        {
          headerName: `PREVIOUS APR COST REDUCTION TARGET (${this.currentYear - 3})`,field: 'previous3rdaprcostreductiontarget',
          flex: 1,minWidth: 220,hide: true,comparator: this.sortService.mixedValueComparator
        },
        {
          headerName: `PREVIOUS APR COST REDUCTION TARGET (${this.currentYear - 4})`,field: 'previous4thaprcostreductiontarget',
          flex: 1,minWidth: 220,hide: true,comparator: this.sortService.mixedValueComparator
        },
        {
          headerName: `MBE (${this.currentYear})`,
          children: [{
            headerName: 'FALL', field: `mbeoct${this.currentYear}`,
            flex: 1,minWidth: 100,comparator: this.sortService.mixedValueComparator
          }, {
            headerName: 'SPRING',field: `mbeapril${this.currentYear}`,
            flex: 1,minWidth: 100,comparator: this.sortService.mixedValueComparator
          }]
        },
        {
          headerName: `WBE (${this.currentYear})`,
          children: [{
            headerName: 'FALL',field: `wbeoct${this.currentYear}`,
            flex: 1,minWidth: 100,comparator: this.sortService.mixedValueComparator
          }, {
            headerName: 'SPRING',field: `wbeapril${this.currentYear}`,
            flex: 1,minWidth: 100,comparator: this.sortService.mixedValueComparator
          }]
        },
        {
          headerName: `MBE (${this.currentYear-1})`,
          children: [{
            headerName: 'FALL',field: `mbeoct${this.currentYear-1}`,
            flex: 1,minWidth: 100,hide: true,comparator: this.sortService.mixedValueComparator
          }, {
            headerName: 'SPRING',field: `mbeapril${this.currentYear-1}`,
            flex: 1,minWidth: 100,hide: true,comparator: this.sortService.mixedValueComparator
          }]
        },
        {
          headerName: `WBE (${this.currentYear-1})`,
          children: [{
            headerName: 'FALL',field: `wbeoct${this.currentYear-1}`,
            flex: 1,minWidth: 100,hide: true,comparator: this.sortService.mixedValueComparator
          }, {
            headerName: 'SPRING',field: `wbeapril${this.currentYear-1}`,
            flex: 1,minWidth: 100,hide: true,comparator: this.sortService.mixedValueComparator
          }]
        },
        {
          headerName: `MBE (${this.currentYear-2})`,
          children: [{
            headerName: 'FALL',field: `mbeoct${this.currentYear-2}`,
            flex: 1,minWidth: 100,hide: true,comparator: this.sortService.mixedValueComparator
          }, {
            headerName: 'SPRING',field: `mbeapril${this.currentYear-2}`,
            flex: 1,minWidth: 100,hide: true,comparator: this.sortService.mixedValueComparator
          }]
        },
        {
          headerName: `WBE (${this.currentYear-2})`,
          children: [{
            headerName: 'FALL',field: `wbeoct${this.currentYear-2}`,
            flex: 1,minWidth: 100,hide: true,comparator: this.sortService.mixedValueComparator
          }, {
            headerName: 'SPRING',field: `wbeapril${this.currentYear-2}`,
            flex: 1,minWidth: 100,hide: true,comparator: this.sortService.mixedValueComparator
          }]
        },
        {
          headerName: `MBE (${this.currentYear-3})`,
          children: [{
            headerName: 'FALL',field: `mbeoct${this.currentYear-3}`,
            flex: 1,minWidth: 100,hide: true,comparator: this.sortService.mixedValueComparator
          }, {
            headerName: 'SPRING',field: `mbeapril${this.currentYear-3}`,
            flex: 1,minWidth: 100,hide: true,comparator: this.sortService.mixedValueComparator
          }]
        },
        {
          headerName: `WBE (${this.currentYear-3})`,
          children: [{
            headerName: 'FALL',field: `wbeoct${this.currentYear-3}`,
            flex: 1,minWidth: 100,hide: true,comparator: this.sortService.mixedValueComparator
          }, {
            headerName: 'SPRING',field: `wbeapril${this.currentYear-3}`,
            flex: 1,minWidth: 100,hide: true,comparator: this.sortService.mixedValueComparator
          }]
        },
        {
          headerName: `MBE (${this.currentYear-4})`,
          children: [{
            headerName: 'FALL',field: `mbeoct${this.currentYear-4}`,
            flex: 1,minWidth: 100,hide: true,comparator: this.sortService.mixedValueComparator
          }, {
            headerName: 'SPRING',field: `mbeapril${this.currentYear-4}`,
            flex: 1,minWidth: 100,hide: true,comparator: this.sortService.mixedValueComparator
          }]
        },
        {
          headerName: `WBE (${this.currentYear-4})`,
          children: [{
            headerName: 'FALL',field: `wbeoct${this.currentYear-4}`,
            flex: 1,minWidth: 100,hide: true,comparator: this.sortService.mixedValueComparator
          }, {
            headerName: 'SPRING',field: `wbeapril${this.currentYear-4}`,
            flex: 1,minWidth: 100,hide: true,comparator: this.sortService.mixedValueComparator
          }]
        }
      ]
     }

  defaultColDef = {
    suppressNavigable: true,
    suppressMovable: false, //Suppressing column drag drop.
    cellClass: 'no-border',
    enableRowGroup: true, //To enable ag-grid enterprise Drag, Drop and grouping functionality and in .html file [rowGroupPanelShow]="'always'"
    unSortIcon: true,
    floatingFilter: true,
    filter: 'agTextColumnFilter',
    wrapText: true,
    suppressHeaderMenuButton: true,
    minWidth: 140,
    suppressFloatingFilterButton: true,
    flex: 1,
    groupUseEntireRow: true,
    sortable: true
  };

  icons = {
    menu: ' ',
    filter: ' '
  }
  columnDefSupplierReportingSummary: any[];

  groupDisplayType: RowGroupingDisplayType = "groupRows";

  rowDataforSupplierReportingSummary = []

  responseofColSupplierIETSummary = {}

  columnDefSupplierIETSummary: any[] = [];

  rowDataforSupplierIETSummary = []

  disableExportReportingSummary = true

  disableExportIETSummary = true

  ngOnInit(){
    this.ngxSpinner.show()
    this.fiscalYear = localStorage.getItem("fiscalId");
    this.validation=this.lookup.localStorageData.pipe(distinctUntilChanged()).subscribe((data)=> {
      const localStorageAssumeUser = JSON.parse(localStorage.getItem('userAssumed'));
      const redirectFlag = localStorage.getItem("redirectedAssume");
      if(data?.includes('success')){
        this.employeeid = this.bService.loggedUserEmployeeId;
        this.getSupplierReportingSummary(1);
      }else if((data?.includes('failure') || data === null) && localStorageAssumeUser){
        if(redirectFlag === "false"){
          this.router.navigate(['home'])
        }
      } else {
        this.ngxSpinner.hide();
      }
    })
  }

  async getSupplierReportingSummary(page: number){
    this.ngxSpinner.show();
    try {
      const data = await lastValueFrom(this.lookup.getSupplierReportingSummary(this.fiscalYear,this.employeeid,page,this.limit));
      if(data['statusCode']===200 && data?.body?.response && data?.body?.response !== "No Details available for this employee" && !data?.body?.errorResponse) {
        if(data?.body?.response?.totalCount && data?.body?.response?.reportSummary.length > 0) {
          this.disableExportReportingSummary = false
          this.reportingSummaryData(page, data.body.response)
        } else {
          this.supplierReportingErrorMessage = this.errorService.noSupplierReportingSummaryErrMsg
          this.showReportingSummaryErrMsg = true;
          this.disableExportReportingSummary = true
          this.ngxSpinner.hide();
          setTimeout(()=> {
            this.showReportingSummaryErrMsg = false;
          },5000)
        }
      } else {
        this.supplierReportingErrorMessage = this.errorService.noSupplierReportingSummaryErrMsg
        this.showReportingSummaryErrMsg = true;
        this.disableExportReportingSummary = true
        this.ngxSpinner.hide();
        setTimeout(()=> {
          this.showReportingSummaryErrMsg = false;
        },5000)
      }
    } catch {
      this.supplierReportingErrorMessage = this.errorService.serverErrMsg
      this.showReportingSummaryErrMsg = true;
      this.disableExportReportingSummary = true
      this.ngxSpinner.hide();
      setTimeout(()=> {
        this.showReportingSummaryErrMsg = false;
      },5000)
    }
    finally {
      this.ngxSpinner.hide();
    }
  }

  reportingSummaryData(page, value){
    //setting total number of packets
    if(page === 1) {
      this.rowDataforSupplierReportingSummary = []
      this.totalNumberOfReportingSummaryData = value.totalCount;
    }
    //If page is greater than 1, then push data to the rowData
    if(page>1) {
      this.ngxSpinner.hide();
      this.rowDataforSupplierReportingSummary = [...this.rowDataforSupplierReportingSummary, ...value.reportSummary]
    } else {
      this.rowDataforSupplierReportingSummary = value.reportSummary;
    }
    //If totalNumberOfReportingSummaryData is more than limit, call the api again to fetch date from next page
    if((this.limit - this.totalNumberOfReportingSummaryData)<0) {
      page += 1;
      this.getSupplierReportingSummary(page)
      this.totalNumberOfReportingSummaryData -= this.limit;
    }
  }

  async getSupplierListAndISE(page: number) {
    this.ngxSpinner.show();
    this.supplierIETRequestBody = {
      fiscalYear: this.fiscalYear,
      employeeId: this.employeeid,
      page: page,
      limit: this.limit
    }
    try {
      const data = await lastValueFrom(this.lookup.getSupplierListAndISE(this.supplierIETRequestBody));
      if(data['statusCode']===200 && data?.body?.response && data?.body?.response !== "No suppliers found for this employee" && !data?.body?.errorResponse) {
        if(data?.body?.response?.totalCount && data?.body?.response?.data.length>0 && data?.body?.response?.columns) {
          this.disableExportIETSummary = false
          this.ietSummaryData(page, data.body.response)
        } else {
          this.supplierReportingErrorMessage = this.errorService.noSupplierReportingSummaryErrMsg
          this.showReportingSummaryErrMsg = true;
          this.disableExportReportingSummary = true
          this.ngxSpinner.hide();
          setTimeout(()=> {
            this.showReportingSummaryErrMsg = false;
          },5000)
        }
      } else {
        this.supplierReportingErrorMessage = this.errorService.noSupplierReportingSummaryErrMsg
        this.showReportingSummaryErrMsg = true;
        this.disableExportReportingSummary = true
        this.ngxSpinner.hide();
        setTimeout(()=> {
          this.showReportingSummaryErrMsg = false;
        },5000)
      }
    } catch {
      this.showIETSummaryErrMsg = true;
      this.supplierIETSummaryErrorMessage = this.errorService.serverErrMsg
      this.disableExportIETSummary = true
      this.ngxSpinner.hide();
      setTimeout(()=> {
        this.showIETSummaryErrMsg = false;
      },5000)
    } finally {
      this.ngxSpinner.hide();
    }
  }

  ietSummaryData(page, value) {
    //setting total number of packets
    if(page === 1) {
      this.columnDefSupplierIETSummary = this.createSupplierIETSummaryColDef(value.columns);
      this.rowDataforSupplierIETSummary = value.data;
      this.totalNumberOfIETSummaryData = value.totalCount;
    }
    //If page is greater than 1, then push data to the rowDataforSupplierIETSummary
    if(page>1) {
      this.ngxSpinner.hide();
      this.rowDataforSupplierIETSummary = [...this.rowDataforSupplierIETSummary, ...value.data]
    } else {
      this.rowDataforSupplierIETSummary = value.data;
    }
    //If totalNumberOfIETSummaryData is more than limit, call the api again to fetch date from next page
    if((this.limit - this.totalNumberOfIETSummaryData)<0) {
      page += 1;
      this.getSupplierListAndISE(page)
      this.totalNumberOfIETSummaryData -= this.limit;
    }
  }

  createSupplierIETSummaryColDef(columns: any[]): any[] { //Dynamic Supplier IET Summary column rendering
    return columns.map((col)=>{
      if(col.field === "description") {
        return {
          ...col,
          minWidth: 240,
          comparator: this.sortService.stringComparator
        }
      } else if(col.field === "total") {
        return {
          ...col,
          minWidth: 100
        }
      } else if (col.field === "comments"){
        return {
          ...col,
          editable: true,
          singleClickEdit: true,
          tooltipField: 'comments',
          comparator: this.sortService.stringComparator
        }
      } else {
        return {
          ...col, field: `iets.${col.field}`,
          cellRenderer: (params: any) => {
            const ietid = params.data.ietids?.[params.colDef.field.split('.')[1]];
            if(params.value === 'X' || params.value === 'x'){
              const link = document.createElement('a');
              link.href = '#';
              link.innerText = params.value;
              link.addEventListener('click', (event) => {
                event.preventDefault()
                this.openModal(col.headerName, ietid)
              });
              return link;
            }
            return params.value;
          },
          headerTooltip: col.headerName
        };
      }
    });
  }

  onCellEditingStopped(event: any) {
    if(event.colDef.field === 'comments') {
      const updatedComments = event.value;
      const appParentId = event.data.appparentid
      this.updateCommentsRequestBody = {
        fiscalId: this.fiscalYear,
        empId: this.employeeid,
        comment: updatedComments,
        appParentId: appParentId
      }
      this.management.updateComment(this.updateCommentsRequestBody).subscribe((_data)=>{});
    }
  }

  openModal(headerName,ietid) {
    this.ngxSpinner.show();
    this.selectedIETName = headerName;
    this.showModel = true;
    this.ietid = ietid;
    this.showIET()
    this.getbulletAndSubBullet()
    setTimeout(()=>{
      this.ngxSpinner.hide();
    },1000);
    return this.selectedIETName;
  }
  
  showIET(){
    this.lookup.getIET(this.ietid).subscribe(data=>{
      this.supplierIETHeading = data.body.response[0]?.name;
      this.supplierIETBody = data.body.response[0]?.description;
    })
  }

  getbulletAndSubBullet(){
    this.lookup.getbulletAndSubBullet(this.ietid).subscribe(data=>{
      if(data.body.response !== 'No bullet and sub bullet is there'){
        this.bullets = data.body.response;
      }
    })
  }

  parseHTMLContent(value: string) {
    const parser = new DOMParser();
    const parsedHTML = parser.parseFromString(value, 'text/html');
    return parsedHTML.body.textContent || '';
  }

  closeApprovedIETModel(): void {
    this.supplierIETHeading = '';
    this.supplierIETBody = '';
    this.bullets = [];
    this.subbullets = [];
    this.selectedIndex = 0;
    this.showModel = false;
  }
  
  onGridReady(params: any, gridId: string): void {
    this.gridApi = params?.api;
    this.gridColumnApi = params?.columnApi;
    this.gridApiMap.set(gridId, params.api);
    params.api?.sizeColumnsToFit();
  }

  @HostListener("window:resize", ['$event'])
  onResize() {
    if (this.gridApi != null) {
      this.gridApi?.sizeColumnsToFit();
      this.gridApi.refreshCells();
    }
  }

  toggleColumnVisibility(): void {
    this.ngxSpinner.show()
    if (this.gridApiMap.has('grid1')) {
      const gridApi = this.gridApiMap.get('grid1');
      const previousYearHistoryColumns = [
        'previousaprcostreductiontarget',
        'previous2ndaprcostreductiontarget',
        'previous3rdaprcostreductiontarget',
        'previous4thaprcostreductiontarget',
        `mbeoct${this.currentYear-1}`,
        `mbeapril${this.currentYear-1}`,
        `wbeoct${this.currentYear-1}`,
        `wbeapril${this.currentYear-1}`,
        `mbeoct${this.currentYear-2}`,
        `mbeapril${this.currentYear-2}`,
        `wbeoct${this.currentYear-2}`,
        `wbeapril${this.currentYear-2}`,
        `mbeoct${this.currentYear-3}`,
        `mbeapril${this.currentYear-3}`,
        `wbeoct${this.currentYear-3}`,
        `wbeapril${this.currentYear-3}`,
        `mbeoct${this.currentYear-4}`,
        `mbeapril${this.currentYear-4}`,
        `wbeoct${this.currentYear-4}`,
        `wbeapril${this.currentYear-4}`
      ]
      gridApi.setColumnsVisible(previousYearHistoryColumns, this.showPrevYearsHistory);
    }
    setTimeout(()=>{
      this.ngxSpinner.hide()
    },1000)
  }

  async exportFile(gridId: string) {
    this.ngxSpinner.show();
    if (this.gridApiMap.has(gridId)) {
      const fileName = gridId === 'grid1' ? 'APP Supplier Reporting Summary' : 'APP IET Matrix';
      await new Promise((resolve)=> {
        this.gridApiMap.get(gridId).exportDataAsExcel({
          fileName: fileName,
          processCellCallback: (params) => {
            if (params.column.getColId() === 'leadbuyercode') {
              return '\u200B' + params.value; // Prefix with zero-width space to fix leading zero getting removed why exporting
            }
            return params.value;
          }
        });
        setTimeout(resolve, this.estimateExportTime(gridId));
      });
    }
    this.ngxSpinner.hide();
  }

  estimateExportTime(gridId): number {
    return this.gridApiMap.has(gridId) ? this.calculateTime(gridId) : 0; 
  }

  calculateTime(gridId) {
    const rows = this.gridApiMap.get(gridId).getDisplayedRowCount();
    return Math.min(5000, rows * 10);
  }

}
