import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-ise-iet-title-renderer',
  templateUrl: './ise-iet-title-renderer.component.html',
  styleUrl: './ise-iet-title-renderer.component.css'
})
export class IseIetTitleRendererComponent implements ICellRendererAngularComp {

  refresh(): boolean {
    return false;
  }

  constructor(private readonly router: Router, private readonly ngxSpinner: NgxSpinnerService){}

  public params: any;

  agInit(params: any): void {
    this.params = params;
  }

  onClick(_event) {
    this.ngxSpinner.show();
    sessionStorage.setItem("ietid",this.params.data.individualexpectedtaskid);
    this.router.navigate(['/iet']);
  }

}
