import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { BusinessEntityService } from 'src/app/services/business-entity.service';
import { LookupService } from 'src/app/services/lookup.service';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrl: './navigation-bar.component.css'
})
export class NavigationBarComponent implements OnInit{
  
  title = 'app-ui';
  isCloseClicked=false;
  reportIssueUrl = "https://tmna.service-now.com/1ts?id=1ts_cat_item&sys_id=0f627bb7db3db240ad1d7eb6bf96197b";
  newClickLink=false;
  selectedAllfunctionComp=false;
  activeLink ='active-link';
  loginUserName;
  loginUserRole;
  validation;
  isDisplay=false;
  adminAcess=false;
  selectedAssume;
  
  constructor(private readonly router: Router,private readonly lookup:LookupService,
    public bService :BusinessEntityService,public authentService:AuthenticationService,private readonly ngxSpinner:NgxSpinnerService) {}
  employeeId:any;
  ngOnInit(): void {
    /**Checking the sucessful user after login and setting then up */
    this.validation=this.lookup.localStorageData.subscribe(data=>{
      if(data?.includes('success')){
        this.employeeId=this.bService.loggedUserEmployeeId;
        this.isDisplay=true;
        this.loginUserName = this.bService.loggedUserEmployeenameOriginal;
        const localStorageAssumeUser= JSON.parse(localStorage.getItem('userAssumed'));
        if(localStorageAssumeUser){
          this.assumeRoleByLocalStorage(localStorageAssumeUser)
        }else{
          this.assumeRoleByBService()
        }
        if(localStorageAssumeUser!=="undefined" &&localStorageAssumeUser!==null ){
          this.selectedAssume=localStorageAssumeUser.pdkusername
          this.assumed=true;
        }

      }else if(data?.includes('failure')){
        this.redirect('home');

      }
    })
  }


  callAssumeIdentity(){
    this.ngxSpinner.show();
    this.lookup.getAssumeList(this.bService.loggedUserEmployeeId,this.loginUserRole).subscribe(data=>{
      if(data['statusCode'] === 200){
        this.assumeIdentity=data.body.response;
        this.mainAssumeIdentity=data.body.response;
        this.ngxSpinner.hide();
      }else{
        this.ngxSpinner.hide();
      }
    })
  }

  assumeRoleByLocalStorage(localStorageAssumeUser) {
    if(localStorageAssumeUser.role==='PurappsAPPAdmin'){
      this.loginUserRole='Admin';this.adminAcess=true
    }else if(localStorageAssumeUser.role==='PurappsBuyer'){
      this.loginUserRole='Buyer';
    }else if(localStorageAssumeUser.role==='PurappsManager'){
      this.loginUserRole='Manager';
      if(localStorageAssumeUser.is_theme_owner){
        this.adminAcess=true
      }
    }
  }

  assumeRoleByBService() {
    if(this.bService.loggedUserRole==='PurappsAPPAdmin'){
      this.loginUserRole='Admin';this.adminAcess=true
    }else if(this.bService.loggedUserRole==='PurappsBuyer'){
      this.loginUserRole='Buyer';
    }else if(this.bService.loggedUserRole==='PurappsManager'){
      this.loginUserRole='Manager';
      if(this.bService?.themeOwner){
        this.adminAcess=true
      }

    }
  }

  getAssumeList(){
    if(window.location.pathname !=='/home'){
      this.redirect('home');
    }
  }
  assumeIdentity:any;
  mainAssumeIdentity:any;
  inputAssume:any;
  assumeSelect=null;
  assumeEmployeeId:any;
  assumed:any=false;
  assumeStringify:any;
  search(inputAssume:any){
    this.assumeSelect='';
    if(inputAssume.length===0){
      this.assumeIdentity=this.mainAssumeIdentity;
    }
    else {
    const dummyAssumeIdentity=[];
    for(const element of this.mainAssumeIdentity){
      if((element.pdkusername.toLowerCase()).includes(inputAssume.toLowerCase())){
        dummyAssumeIdentity.push(element)
      }
    }
    this.assumeIdentity=dummyAssumeIdentity;
    }
  }

  selectAssume(assume:any,employeeid:any,assumeStringify:any){
    this.assumeSelect=assume;
    this.assumeEmployeeId=employeeid;
    this.assumeStringify=assumeStringify;
  }

  assume(){
    localStorage.setItem("assumeClicked", "true");
    localStorage.setItem("userAssumed",JSON.stringify(this.assumeStringify));
    localStorage.setItem("assumedPath",this.router.url);
    localStorage.setItem("redirectedAssume",'false');
    location.reload();
    // this.lookup.getAssumeInfo(this.assumeEmployeeId).subscribe(_data=>{
    //   this.assumed=true;
    //   this.selectedAssume=this.assumeSelect;
    //   this.assumeSelect='';
    // })
  }

  cancelassume(){
    this.assumed=false;
    
    this.assumeSelect=null;
    this.selectedAssume=''
     localStorage.clear();
     this.bService.loggedUserEmployeeId=this.bService.loggedUserEmployeeIdOriginal;
    sessionStorage.removeItem("assumeUsername");
    sessionStorage.removeItem("assumeIsmanagement");
    sessionStorage.removeItem("assumePermissions");
    sessionStorage.removeItem("assumePurchasinggroupid");
    if(window.location.pathname ==='/home'){
      this.redirect('home'); }
    else{
      this.reloadPage();
    }  
  }
  closeDialog(){
   
    this.assumeSelect=null;
    this.inputAssume='';
    this.assumeIdentity=this.mainAssumeIdentity;

   
  }

  reloadPage() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([this.router.url]);
    });
  }

  redirect(uri:string){
    this.router.navigateByUrl('/admin', {skipLocationChange: true}).then(()=>
    this.router.navigate([uri]));
 }

 reportIssue(){
  window.open(this.reportIssueUrl, '_blank')
 }

 logout(){
  localStorage.clear();
  window.history.pushState({},document.title,'');
  sessionStorage.clear();
  this.authentService.logout();
}
 ngOnDestroy(){
  this.validation.unsubscribe();
 }
}
