import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { MsalService } from './msal.service';
import { Injectable, Inject } from '@angular/core';
import { Location } from "@angular/common";
import { InteractionType,AuthenticationResult} from "@azure/msal-browser";
import { MsalGuardConfiguration } from './msal.guard.config';
import { MSAL_GUARD_CONFIG, MSAL_INTERCEPTOR_CONFIG } from './constant';
import { concatMap, catchError, map,switchMap } from 'rxjs/operators';
import { Observable, of , EMPTY} from 'rxjs';
import { Minimatch } from "minimatch";  //NOSONAR
import { MsalInterceptorConfig } from './msal-interceptor.config';
import { UserAccountStoreService } from 'src/app/services/storage/user-account-store.service';
import { LookupService } from 'src/app/services/lookup.service';
import { BusinessEntityService } from 'src/app/services/business-entity.service';



@Injectable()
export class MsalGuard implements CanActivate {
    constructor(
        @Inject(MSAL_GUARD_CONFIG) private readonly msalGuardConfig: MsalGuardConfiguration,
        @Inject(MSAL_INTERCEPTOR_CONFIG) private readonly msalInterceptorConfig: MsalInterceptorConfig,
        private readonly authService: MsalService,
        private readonly userAccountStore: UserAccountStoreService,
        private readonly location: Location,private readonly router: Router,
        public lookupService: LookupService,
        public bService :BusinessEntityService
    ) {
    }

    /**
     * Builds the absolute url for the destination page
     * @param path Relative path of requested page
     * @returns Full destination url
     */
    getDestinationUrl(path: string): string {
        // Absolute base url for the application (default to origin if base element not present)
        const baseElements = document.getElementsByTagName("base");
        const baseUrl = this.location.normalize(baseElements.length ? baseElements[0].href : window.location.origin);

        // Path of page (including hash, if using hash routing)
        const pathUrl = this.location.prepareExternalUrl(path);

        // Hash location strategy
        if (pathUrl.startsWith("#")) {
            return `${baseUrl}/${pathUrl}`;
        }

        // If using path location strategy, pathUrl will include the relative portion of the base path (e.g. /base/page).
        // Since baseUrl also includes /base, can just concatentate baseUrl + path
        return `${baseUrl}${path}`;
    }

    private loginInteractively(url: string): Observable<boolean> {
        if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
            return this.authService.loginPopup({...this.msalGuardConfig.authRequest})
                .pipe(
                    map(() => true),
                    catchError(() => of(false))
                );
        }

        const redirectStartPage = this.getDestinationUrl(url);
        this.authService.loginRedirect({
            redirectStartPage,
            scopes: [],
            ...this.msalGuardConfig.authRequest,
        });
        return of(false);
    }

    canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
        return this.authService.handleRedirectObservable()
            .pipe(
                concatMap(() => {  
                    //NOSONAR
                    if (!this.authService.getAllAccounts().length) {

                      
                        return this.loginInteractively(state.url);
                    }
                    else{ 
            
                     this.userAccountStore.userAccountDetails.subscribe(data => {
                            if(data){
                                if(data.account?.idTokenClaims?.roles[0]){
                                        this.bService.loggedUserGUID = data.account.idTokenClaims.ToyotaGUID;
                                        
                                        this.bService.loggedUserEmail = data.account.idTokenClaims.preferred_username;
                                        this.bService.userToken = data.idToken;
                                    if(this.bService.loggedUserRole === undefined || this.bService.loggedUserRole === null){
                                        this.lookupService?.getUserInfo(data.account.idTokenClaims.ToyotaGUID).subscribe(data => {
                                            
                                            const roleRank=data.body[0].rolerank;
                                            
                                            this.bService.loggedUserRole = data.body[0].role;
                                            sessionStorage.setItem('role',data.body[0].role)
                                            this.bService.loggedUserName = `${data.body[0].firstname} ${data.body[0].lastname}`;
                                            this.bService.loggedUserEmployeenameOriginal= `${data.body[0].firstname} ${data.body[0].lastname}`;
                                            this.bService.loggedUserEmployeeId = data.body[0].employeeid;
                                            this.bService.loggedUserEmployeeIdOriginal=data.body[0].employeeid;
                                            this.bService.loggedUserBuyerCode = data.body[0].buyercode;
                                            this.bService.loggedUserManagerCode = data.body[0].managercode;
                                            this.bService.themeOwner=data.body[0].is_theme_owner;
                                            this.bService.roleRank = roleRank;
                                            sessionStorage.setItem("loginAssumeEmployeeid",data.body[0].employeeid);

                                            this.setEmployeeDetails(roleRank);
                                           
                                           
                                            
                                           
                                        })
                                    }else{
                                        const roleRank = this.bService.roleRank;
                                        this.setEmployeeDetails(roleRank);
                                    }
                                    

                                }
                              
                            }
                        })
                    }
                    return this.setUserInfo();
                }),
                catchError(() => {
                    this.router.navigate(['./unauthorized']);
                    return of(false)
                })
            );
    }

    setEmployeeDetails(roleRank) {
        if(roleRank===4||roleRank===3||roleRank===6){
            const localStorageAssumeUser = JSON.parse(localStorage.getItem('userAssumed'));
            if(localStorageAssumeUser !== undefined && localStorageAssumeUser !== null){
                if(localStorageAssumeUser.externalid === null || localStorageAssumeUser.rolerank=== 5|| localStorageAssumeUser.rolerank=== 1 || localStorageAssumeUser.rolerank=== 2 ){
                    localStorage.setItem("userAssumedData",'noData');
                    this.lookupService.localStorageChanges('failure');
                  
                }else{
                    this.bService.loggedUserEmployeeId=localStorageAssumeUser.employeeids;
                    this.bService.loggedUserName=localStorageAssumeUser.pdkusername;
                    this.lookupService.localStorageChanges('success');
                   
                }

            }else{
                this.lookupService.localStorageChanges('success');
            }
           
           

        }else{
            this.lookupService.localStorageChanges('failure');
        }
    }

    setUserInfo(){
        
        const graphurl='https://graph.microsoft.com/v1.0/me'
        const scopes = this.getScopesForEndpoint(graphurl);
        const account = this.authService.getAllAccounts()[0];

        
        
        return this.authService.acquireTokenSilent({scopes, account})
            .pipe(
                catchError(() => {
                    if (this.msalInterceptorConfig.interactionType === InteractionType.Popup) {
                        return this.authService.acquireTokenPopup({...this.msalInterceptorConfig.authRequest, scopes});
                    }
                    const redirectStartPage = window.location.href;
                    this.authService.acquireTokenRedirect({...this.msalInterceptorConfig.authRequest, scopes, redirectStartPage});
                    return EMPTY;
                }),
                switchMap((result: AuthenticationResult) => {
                    if(('roles' in result.idTokenClaims)){
                        this.userAccountStore.setAccountDetails(result)                                            
                        return of(true);
                    }
                    else{               
                        this.router.navigate(['./unauthorized']);
                        return of(false);
                    }
                    
                })
            );
    }

    private getScopesForEndpoint(endpoint: string): Array<string>|null {
        const protectedResourcesArray = Array.from(this.msalInterceptorConfig.protectedResourceMap.keys());
        const keyMatchesEndpointArray = protectedResourcesArray.filter(key => {
            const minimatch = new Minimatch(key);
            return minimatch.match(endpoint) || endpoint.indexOf(key) > -1;
        });

        // process all protected resources and send the first matched resource
        if (keyMatchesEndpointArray.length > 0) {
            const keyForEndpoint = keyMatchesEndpointArray[0];
            if (keyForEndpoint) {
                return this.msalInterceptorConfig.protectedResourceMap.get(keyForEndpoint);
            }
        }

        return null;
    }

    
}
