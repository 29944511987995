import { Component, HostListener, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { LookupService } from 'src/app/services/lookup.service';
import { SupplierFlagEditComponent } from './supplier-flag-edit/supplier-flag-edit.component';
import { SupplierFlagTitleComponent } from './supplier-flag-title/supplier-flag-title.component';
import { SupplierFlagOrderComponent } from './supplier-flag-order/supplier-flag-order.component';
import { SupplierFlagActiveComponent } from './supplier-flag-active/supplier-flag-active.component';
import { DropdownFloatingFilterComponent } from 'src/app/core/dropdown-floating-filter/dropdown-floating-filter.component';
import { AgGridAngular } from 'ag-grid-angular';
import { BusinessEntityService } from 'src/app/services/business-entity.service';
import { AgGridSortingService } from 'src/app/services/ag-grid-sorting.service';
import { GridApi, GridOptions, RowGroupingDisplayType } from 'ag-grid-community';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-supplier-flag',
  templateUrl: './supplier-flag.component.html',
  styleUrl: './supplier-flag.component.css'
})
export class SupplierFlagComponent {
  @ViewChild('agGrid1') agGrid1: AgGridAngular;
  rowDataForSupplierFlag = [];
  columnDefForSupplierFlag = [];
  successMssg:any;
  successMessageAlert:any;
  failureMssg:any;
  failureMessageAlert:any;
  fiscalYear = Number(localStorage.getItem("fiscalId"));
  postSupplierFlagbody:any;
  putSupplierFlagbody:any;
  defaultColDef={ 
    suppressNavigable: true,
    suppressMovable: false,
    cellClass: 'no-border',
    enableRowGroup: true,
    unSortIcon: true,
    floatingFilter: true,
    filter: 'agTextColumnFilter',
    wrapText: true,
    suppressHeaderMenuButton: true,
    minWidth: 40,
    suppressFloatingFilterButton: true,
    autoHeight: true,
    sortable: true
  };
  icons = {
    menu: ' ',
    filter: ' '
  }
  paginationSupplierFlagPageSizeSelector = [10, 20, 50, 100];
  paginationSupplierFlagScreen = 10;
  domLayout='autoHeight';
  gridApi: GridApi;
  gridColumnApi: any;
  groupDisplayType: RowGroupingDisplayType = "groupRows";
  gridOptions: GridOptions = {
    onGridReady: (params) => this.onGridReady(params),
    onColumnResized: () => this.autoSizeAllColumns(),
    onColumnMoved: () => this.autoSizeAllColumns()
  };

  constructor(private readonly ngxSpinner:NgxSpinnerService, private readonly lookup:LookupService, 
    private readonly bservice:BusinessEntityService,private readonly sortService:AgGridSortingService
    ){
    this.columnDefForSupplierFlag=[{
      headerName: '#',
      field: '',
      filter: false,
      cellRenderer: SupplierFlagEditComponent,
      cellRendererParams: {
        update: this.updateSupplierFlag.bind(this),
        cancel: this.cancelSupplierFlag.bind(this),
      },
      flex: 1,
      enableRowGroup: false,
      lockPosition: true,
      sortable: false
      },
      {
        headerName: 'Title',
        field: 'title',
        cellRenderer:SupplierFlagTitleComponent,
        flex: 4,
        minWidth: 400,
        comparator: this.sortService.stringComparator
      },
      {
        headerName: 'Display Order',
        field: 'displayorder',
        cellRenderer:SupplierFlagOrderComponent,
        flex: 1,
        comparator: false
      },
      {
        headerName: 'Active?',
        field: 'active',
        cellRenderer:SupplierFlagActiveComponent,
        flex: 1,
        floatingFilterComponent: DropdownFloatingFilterComponent,
        floatingFilter: true,
        floatingFilterComponentParams: {
          associatedList: ['All','Yes','No']
        },
        valueGetter: (params) => {
          return params.data?.active === true ? 'Yes' : 'No';
        },
        comparator: false
      }
    ];
  }

  ngOnInit() {
    this.ngxSpinner.show()
    this.showSupplierFlagDetails(this.fiscalYear);
    setTimeout(()=>{
      this.ngxSpinner.hide()
    },2000)
  }

  async showSupplierFlagDetails(fiscalYear: number){
    this.ngxSpinner.show()
    try {
      const data = await lastValueFrom(this.lookup.getSupplierFlags(fiscalYear))
      if(data['statusCode'] === 200 && !data?.body?.errorResponse) {
        this.rowDataForSupplierFlag=data.body.supplierFlag;
      }
    } catch {
      this.ngxSpinner.hide()
    } finally {
      this.ngxSpinner.hide()
    }
  }

  addSupplierFlag(){
    this.clearSupplierFlagFilter();
    const newRow=  this.agGrid1?.api.applyTransaction({
      add: [{editable:true, title: '', displayorder: '', active: false }]
    });
    const rowdata=[]
    rowdata[0]=newRow;
    for(let i=1;i<=this.rowDataForSupplierFlag.length;i++){
      rowdata[i]=this.rowDataForSupplierFlag[i-1];
    }
    this.rowDataForSupplierFlag=rowdata;
    this.rowDataForSupplierFlag[0].editable=true;
    this.agGrid1?.api.paginationGoToPage(0);
  }

  clearSupplierFlagFilter() {
    this.agGrid1?.api.setFilterModel(null);
    this.agGrid1?.api.getColumns().forEach((col) => {
      this.agGrid1?.api.applyColumnState({
        state: [{ colId: col.getId(), sort: null }]
      });
    });
    this.agGrid1?.api.setRowGroupColumns(null);
  }

  updateSupplierFlag(params){  //NOSONAR
    if(params.data.title!==null && params.data.title!==undefined && params.data.title!=="" &&
      params.data.displayorder!==null && params.data.displayorder!==undefined && params.data.displayorder!==""){
    if(params.data?.appparentflagtemplateid){
      const assumedUserPresent=JSON.parse(localStorage.getItem('userAssumed'));
      const employeeid=(assumedUserPresent!=="undefined"&&assumedUserPresent!==null)?assumedUserPresent.employeeids:sessionStorage.getItem("loginAssumeEmployeeid");
      this.putSupplierFlagbody={
        "title": params.data?.title,
        "displayOrder": params.data?.displayorder,
        "active": params.data?.active,
        "updatedByEmployeeID": employeeid,
        "aPPParentFlagTemplateID": params.data?.appparentflagtemplateid,
        "fiscalYearID": this.fiscalYear
      }
      this.lookup.appparentflagtemplateupdate(this.putSupplierFlagbody).subscribe(_data=>{
        this.showSupplierFlagDetails(this.fiscalYear);
      })
    }
    else{
      let active=false;
      if(params.data?.active===true){
        active=true;
      }
      const assumedUserPresent=JSON.parse(localStorage.getItem('userAssumed'));
      const employeeid=(assumedUserPresent!=="undefined"&&assumedUserPresent!==null)?assumedUserPresent.employeeids:sessionStorage.getItem("loginAssumeEmployeeid");
      this.postSupplierFlagbody={
        "title": params.data?.title,
        "displayOrder": params.data?.displayorder,
        "active": active,
        "createdByEmployeeID": employeeid,
        "updatedByEmployeeID": employeeid,
        "fiscalYearID": this.fiscalYear
      }
      this.lookup.appparentflagtemplateinsert(this.postSupplierFlagbody).subscribe(_data=>{
        this.showSupplierFlagDetails(this.fiscalYear);
      })
    }}
    else{
      this.failureMssg="Title and Order are required"
      this.failureMessageAlert=true;
      setTimeout(() => {
        this.failureMessageAlert=false;
      }, 3000);
    }
  }

  cancelSupplierFlag(_params){
    this.showSupplierFlagDetails(this.fiscalYear);
  }

  tabToNextCell(){ //tab to next cell in ag-grid table
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.autoSizeAllColumns();
  }

  @HostListener('window:resize', ['$event'])
  onResize(_event: any) {
    if (this.gridApi) {
      this.debounce(() => {
        this.autoSizeAllColumns();
      }, 100);
    }
  }

  autoSizeAllColumns() {
    const allColumnIds: string[] = [];
    this.gridColumnApi.getAllColumns().forEach((column: any) => {
      allColumnIds.push(column.getColId());
    });
    this.gridColumnApi.autoSizeColumns(allColumnIds);
  }

  debounce(func: Function, wait: number) {
    let timeout: any;
    return (...args: any[]) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }
}
