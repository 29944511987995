import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorMessagesService {

  noPacketDetails = 'No Packet details available'
  titleBodyErrMsg = 'Title and Body are required'
  bulletBodyErrMsg = 'Bullet is required'
  subbulletBodyErrMsg = 'Sub bullet cannot be empty'
  noSupplierErrMsg = 'No Supplier data present for current fiscal year.'
  serverErrMsg = 'Unable To Connect To The Server.'
  noStandardIETErrMsg = 'No Standard IET details present for current fiscal year.'
  noGeneralPrioritiesErrMsg = 'No General Priorities details present for current fiscal year.'
  noSupplierReportingSummaryErrMsg = 'No Supplier Reporting Summary details available'
  noSupplierIETSummaryErrMsg = 'No Supplier IET Summary details available'
  noPacketSentDataErrMsg = 'No Packet sent details available for the selected fiscal year'
  
  constructor() { }
}
