<!-- Top Blue Box  -->
<div class="alert-failure" *ngIf="showNoSupplierErrMsg">
  <img style="margin-right: 1em;" src="../assets/images/errCircle.png" alt="failureMsg">
  
  <span>{{pageErrorMsg}}</span>
</div>
<div *ngIf="isDisplay">
  <div>
    <a class="homePageLinks cursorptr ms-3" [attr.href]="appRefDocLink"
    id="appRefDoc" target="_blank" style="font-size: 12px; text-decoration: none;">APP Reference Documents</a>
  </div>
  <div class="topBox">
    <span class="ms-3" style="color:#FBFBFC; font-size: small">APP Packet Approval Information  </span>
  </div>
  <!-- Main Content -->
  <div class="container-fluid">
    <!-- Space for packet information in form of tab -->
    <div class="row pe-2">
      <div class="col-12 col-md-12 m-1">
        <div class="customBox d-flex" >
          <div class="col-md-2">
            <div class="innerCustomBox-before" (click)="applyActiveClass(1, $event)" (keyup)="applyActiveClass(1, $event)" [ngClass]="{innerCustomBox:tab1==true}">
              <div class="d-flex">
                <div class="commonFont">{{totalPacket}}</div>
                <div class="percentageBox label-tmna mt-2" style="visibility: hidden;">{{totalPacketPercentage}}%</div>
              </div>
              <div class="label-tmna">Total number of packets</div>
            </div>
          </div>
          <div class="col-md-2">
            <div class="innerCustomBox-before" (click)="applyActiveClass(2, $event)" (keyup)="applyActiveClass(2, $event)" [ngClass]="{innerCustomBox:tab2==true}">
              <div class="d-flex">
                <div class="commonFont">{{approvedpacket}}</div>
                <div class="percentageBox label-tmna mt-2">{{approvedpacketPercentage}}%</div>
              </div>
              <div class="label-tmna">Approved packets</div>
            </div>
          </div>
          <div class="col-md-2">
            <div class="innerCustomBox-before" (click)="applyActiveClass(3, $event)" (keyup)="applyActiveClass(3, $event)" [ngClass]="{innerCustomBox:tab3==true}" >
              <div class="d-flex">
                <div class="commonFont">{{packetNotSent}}</div>
                <div class="percentageBox label-tmna mt-2">{{packetNotSentPercentage}}%</div>
              </div>
              <div class="label-tmna">Packets Not Sent</div>
            </div>
          </div>
          <div class="col-md-2">
            <div class="innerCustomBox-before" (click)="applyActiveClass(4, $event)" (keyup)="applyActiveClass(4, $event)" [ngClass]="{innerCustomBox:tab4==true}">
              <div class="d-flex">
                <div class="commonFont">{{openPacket}}</div>
                <div class="percentageBox label-tmna mt-2">{{openPacketPercentage}}%</div>
              </div>
              <div class="label-tmna">Open packets</div>
            </div>
          </div>
          <div class="col-md-2">
            <div class="innerCustomBox-before" (click)="applyActiveClass(5, $event)" (keyup)="applyActiveClass(5, $event)" [ngClass]="{innerCustomBox:tab5==true}">
              <div class="d-flex">
                <div class="commonFont">{{inprogressPacket}}</div>
                <div class="percentageBox label-tmna mt-2">{{inprogressPacketPercentage}}%</div>
              </div>
              <div class="label-tmna">In progress packets</div>
            </div>
          </div>
          <div class="col-md-2">
            <div class="innerCustomBox-before" (click)="applyActiveClass(6, $event)" (keyup)="applyActiveClass(6, $event)" [ngClass]="{innerCustomBox:tab6==true}">
              <div class="d-flex">
                <div class="commonFont">{{readyforreviewPacket}}</div>
                <div class="percentageBox label-tmna mt-2">{{readyforreviewPacketPercentage}}%</div>
              </div>
              <div class="label-tmna">Packets ready for manager review</div>
            </div>
          </div>
  
        </div>
       
      </div>
    </div>
    <!-- Main content of the page -->
    <div class="flex-row d-flex">
      <!-- Supplier list details div start -->
      <div class="col-4 col-md-4 me-1 p-1  ">
        <div class="supplierBox commonFont">
          <span class="ms-2 pt-2">{{fiscalyearlabel}}</span>
        </div>
        <div class="cursorptr searchBar p-1" style=" background-color: #E4ECF0;" >
          <input type="text" #searchBox [(ngModel)]="searchSupplier" (ngModelChange)="onSearchChange()" class="form-control addsearch search-tmna" placeholder="Search here">
          <img class="ms-1" src="\assets\images\search.svg" alt="img">
        </div>
        <div #listBox class="supplierContainer">
          <option class="supplierList displayTextcss list-item" *ngFor="let suppList of filteredSupplier; let i = index"
          (keyup)="selectSupplier(i)" (click)="selectSupplier(i)" [class.selected] = "i === selectedFilterIndex">
            {{suppList}}
          </option>
        </div>
        <div class="mt-2 mb-2">
          <span class="label-tmna mt-2 mb-2" style="font-style: italic;">* denotes you as the lead buyer</span>
        </div>
      </div>
      <!-- Supplier list  ends here  -->
      <div class="col-8 col-md-8 ms-1 p-1">
        <!-- Heading and link on right side  -->
        <div class="row">
          <div class="d-flex justify-content-between p-2">
            <div class="d-flex flex-row ms-1">
              <span class="heading pe-2">{{selectedSupplierBuyerInfo}}</span>
            </div>
            <div class="d-flex flex-row me-2 pe-1">
              <a class="homePageLinks me-2" [ngClass]="{'noSuppliers': disableISEReportsLink}" routerLink="/ise">View/Edit ISE</a>
              <a class="homePageLinks ms-2 showhand" [ngClass]="{'noSuppliers': disableISEReportsLink}" (click)="generatePDF()">View Report</a>
            </div>
  
          </div>
        </div>
        <!-- Heading and link on right side ENDS HERE -->
        <!-- Accordian 1 starts with a row -->
        <div class="">
          <div class="accordion-wrapper ">
            <div class="accordion mb-2 me-2" id="homeAccordianOne">
              <div class="card">
                <div class="card-header">
                  <div class="d-flex flex-row justify-content-between">
                    <span class="commonFont pt-2">SUPPLIER DETAILS</span>
                    <button class="btn headButton" type="button" data-bs-toggle="collapse" id="accordion3Btn"
                      accordianActive="true" data-bs-target="#collapsesearchfilter" aria-expanded="true"
                      aria-controls="collapsesearchfilter" (click)="isOpen1=!isOpen1">
          
                      <img class="clpseBtn collapsesearchfilterBtn" [ngClass]="{'active':isOpen1}" alt="img">
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div id="collapsesearchfilter" class="collapse show me-2" aria-labelledby="headingOne" data-bs-parent="#proposalHist">
              <div class="cardBody">
                <div class="accordion-main">
                  <div class="d-flex flex-row">
                    <div class="col-6 col-md-6 mt-2 ms-3">
                      <div class="d-flex flex-row mt-3">
                        <label for="imprTargetTitle" class="label-tmna me-1 tmnaAprLabel"> TMNA Value Supplier Category</label>
                        <select class="dropdown-tmna ms-2 mt-1" id="imprTargetTitle" (change)="onSelectingImprvtTargetTitle()" [(ngModel)]="selectedImprTargetTitle" [ngModelOptions]="{standalone: true}">
                          <option class="displayTextcss" *ngFor="let item of improvementTargetData; trackBy: trackByFn" [value]="item.title">
                            {{item.title}}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-6 col-md-6 ms-3 mt-1 mb-3 me-2">
                      <div class="d-flex flex-row mt-1">
                        <span class="label-tmna mt-3 tmnaAprLabel"> APR Cost Reduction Target(Kadai)%</span>
                        <div class="d-flex flex-column">
                          <div class="d-flex aprValueFlex ms-2">
                            <div class="d-flex flex-column aprValueField">
                              <span for="vipmanuimpraprtarget" class="label-tmna">FY{{tmnaYearLabel}}</span>
                              <input class="input-tmna" type="text" id="vipmanuimpraprtarget" [value]="currentAPRtarget" (input)="onInputChange($event)"
                              (keypress)="allowOnlyValidChar($event)" (focusout)="onFocusOut()">
                            </div>
                            
                            <div class="d-flex flex-column aprValueField">
                              <span for="previoustarget" class="label-tmna">FY{{currentFiscalYear}}</span>
                              <input class="input-tmna" type="text" id="previoustarget" [value]="prevAPRtarget" disabled>
                            </div>
                          </div>
                          <div class="d-flex ms-2">
                            <span class="label-tmna resizeStyle" style="font-style: italic;">Only Positive Values Allowed</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row pb-3">
                    <textarea class="label-tmna-big ms-4" id="imprTargetDesc" [value]="selectedImprTargetDesc" rows="5" readonly></textarea>
                  </div>
                </div>
          
              </div>
            </div>
          </div>
        </div>
        <!-- Accordian 1 ends here -->
        <div class="d-flex flex-row mt-3 mb-2 me-1">
          <div class="accordianwidth1 me-1" style="min-width: 50%;">
            <div class="accordion-wrapper">
              <div class="accordion" id="homeAccordianTwo">
                <div class="card" style="border-bottom: none;">
                  <div class="card-header">
                    <div class="d-flex flex-row justify-content-between">
                      <span class="commonFont pt-2">SAFETY RISK RANKING</span>
                      <button class="btn headButton" type="button" data-bs-toggle="collapse" id="accordion3Btn"
                        accordianActive="true" data-bs-target="#riskRanking" aria-expanded="true"
                        aria-controls="collapsesearchfilterTwo" (click)="isOpen2=!isOpen2">
  
                        <img class="clpseBtn collapsesearchfilterBtn" [ngClass]="{'active':isOpen2}" alt="img">
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div id="riskRanking" class="collapse show" aria-labelledby="headingOne"
                data-bs-parent="#proposalHist">
                <ag-grid-angular class="ag-theme-balham " [rowData]="rowDataSafetyRisk" [columnDefs]="columnDefsSafetyRisk"
                  (gridReady)="onGridReady($event, 'grid1')" [pagination]="true" headerHeight="50" [defaultColDef]="defaultColDef"
                  [paginationPageSize]="paginationPageSize" [paginationPageSizeSelector]="paginationPageSizeSelector" [domLayout]="domLayout">
                
                </ag-grid-angular>
              </div>
              
  
            </div>
          </div>
          <div class="flex-column" style="width: 50%;">
            <div class="accordianwidth ms-1">
              <div class="accordion-wrapper">
                <div class="accordion mb-2 me-2" id="homeAccordianTwo">
                  <div class="card">
                    <div class="card-header">
                      <div class="d-flex flex-row justify-content-between">
                        <span class="commonFont pt-2">TIER II SOURCING SECTION</span>
                        <button class="btn headButton" type="button" data-bs-toggle="collapse" id="accordion3Btn"
                          accordianActive="true" data-bs-target="#tierTwoSourcing" aria-expanded="true"
                          aria-controls="collapsesearchfilterTwo" (click)="isOpen3=!isOpen3">
    
                          <img class="clpseBtn collapsesearchfilterBtn" [ngClass]="{'active':isOpen3}" alt="img">
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="tierTwoSourcing" class="collapse show me-2" aria-labelledby="headingOne"
                  data-bs-parent="#proposalHist">
                  <div class="cardBody">
                    <div class="accordion-main pt-3 pb-1 ps-1 pe-1 d-flex flex-column">
                      <div class="d-flex flex-row tieriiResult">
                        <div class="ms-2 d-flex targetValue" style="min-height: 45px;">
                          <label for="tieriiminoritysourcingtarget" class="label-tmna">Minority Target % (See Tier 2 Manual)</label>
                          <input class="input-tmna" type="text" id="tieriiminoritysourcingtarget" [value]="minoritytarget" disabled>
                        </div>
                        <div class="ms-2 me-2 d-flex targetValue">
                          <label for="tieriiwbetarget" class="label-tmna" style="min-height: 45px;">Women Target %</label>
                          <input class="input-tmna" type="text" id="tieriiwbetarget" [value]="wbetarget" disabled>
                        </div>
                      </div>
                      <div class="d-flex flex-row tieriiResult">
                        <div class="ms-2 d-flex targetValue">
                          <label for="prevMBEachievement" class="label-tmna">Fall {{fallLabelYear}} %</label>
                          <input class="input-tmna" type="text" id="prevMBEachievement" [value]="prevMBEachievement" disabled>
                        </div>
                        <div class="ms-2 me-2 d-flex targetValue">
                          <label for="prevWBEachievement" class="label-tmna">Fall {{fallLabelYear}} %</label>
                          <input class="input-tmna" type="text" id="prevWBEachievement" [value]="prevWBEachievement" disabled>
                        </div>
                      </div>
                      <div class="d-flex flex-row tieriiResult">
                        <div class="ms-2 d-flex targetValue">
                          <label for="currentMBEachievement" class="label-tmna">Spring {{springLabelYear}} %</label>
                          <input class="input-tmna" type="text" id="currentMBEachievement" [value]="currentMBEachievement" disabled>
                        </div>
                        <div class="ms-2 me-2 d-flex targetValue">
                          <label for="currentWBEachievement" class="label-tmna">Spring {{springLabelYear}} %</label>
                          <input class="input-tmna" type="text" id="currentWBEachievement" [value]="currentWBEachievement" disabled>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="me-2">
          <div class="removeDetailsBox p-3">
            <div class="commonFont">Need to Remove a supplier from your list ?</div>
            <div class="label-tmna">- If the plant(s) still supply for other buyers or need to remain active for service, please inactivate all of the (OE, Prod) part records assigned to your code in Part Master </div>
            <div class="label-tmna">- If plant is not longer supplying to TMNA at all, please submit the vendor packet to inactivate the plant code in WARP</div>          
          </div>
        </div>
        <span *ngIf="this.dateApproved" class="label-tmna" style="font-style: italic;">Approved at {{this.dateApproved}}</span>
        <div class="d-flex mt-2 ">
          <div class="accordion-wrapper" style="margin-bottom: 7em;">
            <div class="accordion me-2" id="homeAccordianTwo">
              <div class="card" style="border-bottom: none;">
                <div class="card-header">
                  <div class="d-flex flex-row justify-content-between">
                    <span class="commonFont pt-2">COMMODITY DETAILS</span>
                    <button class="btn headButton" type="button" data-bs-toggle="collapse" id="accordion3Btn"
                      accordianActive="true" data-bs-target="#commodityDetails" aria-expanded="true"
                      aria-controls="collapsesearchfilterTwo" (click)="isOpen4=!isOpen4">
  
                      <img class="clpseBtn collapsesearchfilterBtn" [ngClass]="{'active':isOpen4}" alt="img">
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div id="commodityDetails" class="collapse show me-2" aria-labelledby="headingOne"
              data-bs-parent="#proposalHist">
              <ag-grid-angular class="ag-theme-balham " [rowData]="rowDataCommodityDetails" [columnDefs]="columDefCommodityDetails"
                (gridReady)="onGridReady($event, 'grid2')" [pagination]="true" [defaultColDef]="defaultColDef"
                [paginationPageSize]="paginationPageSize" [paginationPageSizeSelector]="paginationPageSizeSelector" [domLayout]="domLayout">
              
              </ag-grid-angular>
            </div>
            
  
          </div>
         
        </div>
  
      </div>
    </div>
  </div>
  
  
  
  <nav class="navbar navbar-expand-lg justify-content-end footer-tab fixed-bottom mt-2">
    <div class="saveFooter mb-3 me-2 pe-1">
        <button *ngIf="isManager" class="addButton ms-3" (click)="approveDisapproveSupplierDetails()" [ngClass]="{'noSuppliers': disableButtons}">
          <span *ngIf="approved">DISAPPROVE</span>
          <span *ngIf="!approved">APPROVE</span>
        </button>
        <button *ngIf="isManager && readyForReview" class="closeButton ms-3" [ngClass]="{'noSuppliers': disableButtons}" (click)="openModal()">
          SEND THIS SUPPLIER BACK TO BUYER
        </button>
        <button *ngIf="isManager" class="closeButton ms-3" (click)="doNotSendSupplierDetails()" [ngClass]="{'noSuppliers': disableButtons}">
          <span *ngIf="donotsend">SEND</span>
          <span *ngIf="!donotsend">DO NOT SEND</span>
        </button>
        <button  *ngIf="isManager || isAdmin || isBuyer" class="closeButton ms-3" (click)="saveSupplierDetails()" [ngClass]="{'noSuppliers': disableButtons, 'supplierAprvd': approved, 'supplierNoSend': (donotsend && approved)}">
          SAVE
        </button>
        <button *ngIf="isBuyer && inProgress" class="closeButton ms-3" [ngClass]="{'noSuppliers': disableButtons}" (click)="openModal()">
          READY FOR REVIEW
        </button>
    </div>
  </nav>
</div>
<div *ngIf="!isDisplay">
  <div class=" container unauthorized" *ngIf="displayUnauthorised" >
    <p></p>
    <p style="color: red;">You do not have a role assigned to access this Application.</p>
    <p>Please raise a request using 
      <a style="color: #2688Da; text-decoration:underline" href="https://ep.idm.toyota.com/identityiq/home.jsf" >SailPoint</a>.
      Refer to the <span style="color: #2688Da; text-decoration:underline; cursor:pointer">User Guide</span>
      for more details.
    </p>
    <p style="color: #2688Da; text-decoration:underline; cursor: pointer;" 
    (click)="cancelNullGuidAssume()" 
    (keydown)="cancelNullGuidAssume()" 
    *ngIf="cancelAssumedUser" >Cancel Assume</p>
  </div>
</div>

<div class="modal" tabindex="-1" [ngClass]="{'show': showModal}" [ngStyle]="{'display': showModal ? 'block':'none'}">
  <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content" *ngIf="isBuyer">
          <button type="button" class="close" data-bs-dismiss="modal" (click)="closeModal()"> 
            <img src="\assets\images\close-icon.png" alt="Close Modal" height="15" width="15" >
          </button>
  
          <div class="modal-header" id="addModalHeader"
              style=" display: flex; justify-content: space-between;align-items:end  ">
              
              <h4 class="modal-title inl-modal-padding modalHeading">Confirm Dialog
              </h4>
  
          </div>
          <div class="modal-body">
            <span class="label-tmna">Please confirm if supplier report is ready for manager review.</span>
          </div>
          <div class="modal-footer">               
              <button class="closeButton me-2" (click)="closeModal()"> Cancel </button>
              <button class="addButton me-2" (click)="approvalStatusUpdate()"> Confirm </button>
          </div>
      </div>

      <div class="modal-content" *ngIf="isManager">
        <button type="button" class="close" data-bs-dismiss="modal" (click)="closeModal()"> 
          <img src="\assets\images\close-icon.png" alt="Close Modal" height="15" width="15" >
        </button>

        <div class="modal-header" id="addModalHeader"
            style=" display: flex; justify-content: space-between;align-items:end  ">
            
            <h4 class="modal-title inl-modal-padding modalHeading">Please provide comments/feedback to buyer
            </h4>

        </div>
        <div class="modal-body">
          <textarea class="label-tmna-big" [(ngModel)]="commentsFromManager" type="text" rows="3" style="width: 100%;"></textarea>
        </div>
        <div class="modal-footer">
          <button class="closeButton me-2" (click)="closeModal()"> Cancel </button> 
          <button class="addButton me-2" (click)="approvalStatusUpdate()"> Send to buyer </button>    
        </div>
    </div>
  </div>
</div>