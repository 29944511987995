import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BusinessEntityService {
  public userToken : any;
  public loggedUserGUID = "";
  public loggedUserName = "";
  public loggedUserEmail = "";
  public loggedUserRole:string;
  public loggedUserEmployeeId:number;
  public loggedUserBuyerCode:string;
  public loggedUserManagerCode:string;
  public loggedUserRoleRank: number;
  public themeOwner:any;
  public assumedUserGUID = "";
  public assumedUserName = "";
  public assumedUserEmail = "";
  public assumedUserRole:string;
  public assumedUserEmployeeId:number;
  public assumedUserBuyerCode:string;
  public assumedUserManagerCode:string;
  public assumedUserRoleRank: number;
  public appParentId:any;
  public loggedUserEmployeeIdOriginal:number;
  public loggedUserEmployeenameOriginal:string;
  public roleRank:number;


  constructor() { }
}
