import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { EnvironmentConfiguration } from '../environment/services/environment.configuration';
import { BusinessEntityService } from './business-entity.service';

@Injectable({
  providedIn: 'root'
})
export class LookupService {
  apiBaseUrl = 'http://localhost:3000';
  apiVersion = 'api/v1';
  serviceUrl = this.apiBaseUrl;
  contentType =  'application/json' // Sonar fix
  
  // constructor(private readonly http:HttpClient) {
  constructor(private readonly http:HttpClient,private readonly environment: EnvironmentConfiguration, private readonly bservice : BusinessEntityService) {
    this.apiBaseUrl = this.environment['apiUrl'].lookupUrl;
    this.serviceUrl = this.apiBaseUrl;
  }
    /** observable to verify user credential */
    private readonly localStorageObservable = new BehaviorSubject<any>(null);
    localStorageData = this.localStorageObservable.asObservable();
  
    localStorageChanges(data: any) {
      if(data !== null){
        this.localStorageObservable.next(data);
      }
    }

  /** api to fetch user credentials */
  public getUserInfo(guid): Observable<any> {
    const httpCustomOptions = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `Bearer ${this.bservice?.userToken}`,
        'params': `{ "guid":"${guid}"}`
       })
    };
    return this.http?.get<any>(this.environment['apiUrl'].lookupUrl + `api/v1/userInfo?guid=${guid}`, httpCustomOptions);
  }

  public getSupplierList(body): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
       
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/supplierList`;
    return this.http.post<any>(url, body, header);
  }

  public getSftyRiskCommodity(appParentId, fiscalId, empId): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
        
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/getSftyRiskCommodity?appParentId=${appParentId}&fiscalId=${fiscalId}&empId=${empId}`;
    return this.http.get<any>(url, header);
  }

  public getSupplierTierResult(appParentId, fiscalId, empId): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
       
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/getSupplierTierResult?appParentId=${appParentId}&fiscalId=${fiscalId}&empId=${empId}`
    return this.http.get<any>(url, header);
  }

  public getISESuppliers(fiscalYear): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
        
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/getISEList?fiscalYear=${fiscalYear}`;
    return this.http.get<any>(url, header);
  }

  public toggleISESuppliers(body): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/toggleIseSupplier`;
    return this.http.put<any>(url, body, header);
  }

  public getAssumeList(employeeId,role): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`,
      
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/getAssumeIdentityList?employeeId=${employeeId}&role=${role}`;
    return this.http.get<any>(url, header);
  }

  public getAssumeInfo(employeeId): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`,
       
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/getAssumeIdentityInfo?employeeId=${employeeId}`;
    return this.http.get<any>(url, header);
  }

  public getStandardIETList(fiscalYear): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
       
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/getStandardIETList?fiscalYear=${fiscalYear}`;
    return this.http.get<any>(url, header);
  }

  public getStandardIETBulletDesc(standardiet): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
       
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/getStandardIETBulletDesc?standardiet=${standardiet}`;
    return this.http.get<any>(url, header);
  }

  public addIET(body): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
       
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/addIET`;
    return this.http.post<any>(url, body, header);
  }

  public updateIET(body): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
        
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/updateIET`;
    return this.http.put<any>(url, body, header);
  }

  public deleteIET(body): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/deleteIET`;
    return this.http.post<any>(url, body, header);
  }

  public addIETBullet(body): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/addIETBullet`;
    return this.http.post<any>(url, body, header);
  }

  public updateIETBullet(body): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/updateIETBullet`;
    return this.http.put<any>(url, body, header);
  }

  public deleteIETBullet(body): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/deleteIETBullet`;
    return this.http.post<any>(url, body, header);
  }

  public getQualityCommodity(appId,appParentId,fiscalId,empId): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/getQualityCommodity?appId=${appId}&appParentId=${appParentId}&fiscalId=${fiscalId}&empId=${empId}`;
    return this.http.get<any>(url, header);
  }

  public getGeneralIETList(fiscalYear): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/getGeneralIETList?fiscalYear=${fiscalYear}`;
    return this.http.get<any>(url, header);
  }

  public getGeneralIETBulletDesc(generaliet): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/getGeneralIETBulletDesc?generaliet=${generaliet}`;
    return this.http.get<any>(url, header);
  }

  public getManagerNames(): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/getManagerNames`;
    return this.http.get<any>(url, header);
  }
  
  public getImprovementTarget(): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/getImprovementTarget`;
    return this.http.get<any>(url, header);
  }

  public addNewImprovementTarget(body): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/addNewImprovementTarget`;
    return this.http.post<any>(url, body, header);
  }

  public updateImprovementTarget(body): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/updateImprovementTarget`;
    return this.http.put<any>(url, body, header);
  }

  public getStandardIETNamesInCurrentFY(): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/getStandardIETNamesInCurrentFY`;
    return this.http.get<any>(url, header);
  }

  public getSuppliersByIETName(ietname): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/getSuppliersByIETName?ietname=${ietname}`;
    return this.http.get<any>(url, header);
  }
  
  public applyStandardIET(body): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/applyStandardIET`;
    return this.http.post<any>(url, body, header);
  }

  public getFiscalYearListPacketSent(): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/getFiscalYearListPacketSent`;
    return this.http.get<any>(url, header);
  }

  public apphistoryget(fiscalyearid,page,limit): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/apphistoryget?fiscalyearid=${fiscalyearid}&page=${page}&limit=${limit}`;
    return this.http.get<any>(url, header);
  }

  public getApprovedAPPPackets(employeeId): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`,
      
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/getApprovedAPPPackets?employeeId=${employeeId}`;
    return this.http.get<any>(url, header);
  }

  public getNotSentAPPPackets(employeeId): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`,
      
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/getNotSentAPPPackets?employeeId=${employeeId}`;
    return this.http.get<any>(url, header);
  }

  public getOpenAPPPackets(employeeId): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`,
      
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/getOpenAPPPackets?employeeId=${employeeId}`;
    return this.http.get<any>(url, header);
  }

  public getSupplierFlags(fiscalId): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/getSupplierFlags?fiscalId=${fiscalId}`;
    return this.http.get<any>(url, header);
  }

  public appparentflagtemplateinsert(body): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/appparentflagtemplateinsert`;
    return this.http.post<any>(url, body, header);
  }

  public appparentflagtemplateupdate(body): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/appparentflagtemplateupdate`;
    return this.http.post<any>(url, body, header);
  }

  public getGeneratedPDF(appParentId): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`,
        'params': `{"appParentId":${appParentId}}`
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/generatePDF`;
    return this.http.get<any>(url, header);
  }

  public getIndividualExpectedTask(appparetid): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/getIndividualExpectedTask?appparetid=${appparetid}`;
    return this.http.get<any>(url, header);
  }

  public getISEDetails(appparentid, employeeid): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
       
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/getISEDetails?appparentid=${appparentid}&employeeid=${employeeid}`
    return this.http.get<any>(url, header);
  }

  public getIET(ietid): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
        
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/getIET?ietid=${ietid}`;
    return this.http.get<any>(url, header);
  }

  public getIETBulletsByIETId(ietid): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
        
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/getIETBulletsByIETId?ietid=${ietid}`;
    return this.http.get<any>(url, header);
  }

  public getIETSubBulletsByIETId(ietbulletid): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
        
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/getIETSubBulletsByIETId?ietbulletid=${ietbulletid}`;
    return this.http.get<any>(url, header);
  }

  public appDeleteIETBullet(ietbulletid): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
        
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/appDeleteIETBullet?ietbulletid=${ietbulletid}`;
    return this.http.get<any>(url, header);
  }

  public appDeleteIETSubBullet(ietsubbulletid): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
        
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/appDeleteIETSubBullet?ietsubbulletid=${ietsubbulletid}`;
    return this.http.get<any>(url, header);
  }

  public appUpdateBulletOrder(ietbulletid,ietbulletorder): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
        
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/appUpdateBulletOrder?ietbulletid=${ietbulletid}&ietbulletorder=${ietbulletorder}`;
    return this.http.get<any>(url, header);
  }

  public insertAPPIetBullet(body): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
       
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/insertAPPIetBullet`;
    return this.http.post<any>(url, body, header);
  }

  public insertAPPIetSubBullet(body): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
       
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/insertAPPIetSubBullet`;
    return this.http.post<any>(url, body, header);
  }

  public editAPPIetBullet(body): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
       
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/editAPPIetBullet`;
    return this.http.post<any>(url, body, header);
  }

  public editAPPIetSubBullet(body): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
       
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/editAPPIetSubBullet`;
    return this.http.post<any>(url, body, header);
  }

  public appUpdateSubBulletOrder(subbulletid,subbulletorder): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
        
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/appUpdateSubBulletOrder?subbulletid=${subbulletid}&subbulletorder=${subbulletorder}`;
    return this.http.get<any>(url, header);
  }

  public appIETDelete(individualexpectedtaskid): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
        
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/appIETDelete?individualexpectedtaskid=${individualexpectedtaskid}`;
    return this.http.get<any>(url, header);
  }

  public appUpdateIET(body): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
       
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/appUpdateIET`;
    return this.http.post<any>(url, body, header);
  }

  public getSupplierReportingSummary(fiscalYear, employeeid, page, limit): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
       
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/getSupplierReportingSummary?fiscalYear=${fiscalYear}&employeeId=${employeeid}&page=${page}&limit=${limit}`
    return this.http.get<any>(url, header);
  }

  public getSupplierListAndISE(body): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    }
    const url = `${this.serviceUrl+this.apiVersion}/getSupplierListAndISE`
    return this.http.post<any>(url, body, header);
  }

  public appNewIET(body): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
       
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/appNewIET`;
    return this.http.post<any>(url, body, header);
  }

  public updateIETOrder(body): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
       
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/updateIETOrder`;
    return this.http.post<any>(url, body, header);
  }

  public updateRow(body): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
       
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/updateRow`;
    return this.http.post<any>(url, body, header);
  }

  public downloadTierIITemplate(employeeid, fiscalyearid): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    }
    const url = `${this.serviceUrl+this.apiVersion}/downloadTierIITemplate?employeeid=${employeeid}&fiscalyearid=${fiscalyearid}`;
    return this.http.get<any>(url, header);
  }

  public downloadOEUploadTemplate(employeeid, fiscalyearid): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    }
    const url = `${this.serviceUrl+this.apiVersion}/downloadOEUploadTemplate?employeeid=${employeeid}&fiscalyearid=${fiscalyearid}`;
    return this.http.get<any>(url, header);
  }

  public updateTierIIResults(body) {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`,
      })
    }
    const url = `${this.serviceUrl+this.apiVersion}/updateTierIIResults`;
    return this.http.post<any>(url, body, header);
  }

  public updateOEReports(body) {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`,
      })
    }
    const url = `${this.serviceUrl+this.apiVersion}/updateOEReports`;
    return this.http.post<any>(url, body, header);
  }

  public getOESignedURL(body) {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    }
    const url = `${this.serviceUrl+this.apiVersion}/getOESignedURL`;
    return this.http.post<any>(url, body, header);
  }

  public getTierIIResults(appparentid, fiscalyearid): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
       
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/getTierIIResults?appparentid=${appparentid}&fiscalyearid=${fiscalyearid}`;
    return this.http.get<any>(url, header);
  }

  public getCommodityOEReport(appparentid): Observable<any> { //Not using this API, as we are getting updated commodity details from getSftyRiskCommodity fine
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
       
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/getCommodityOEReport?appparentid=${appparentid}`;
    return this.http.get<any>(url, header);
  }

  public getAppReferenceDoc(): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/getappreferencedoc`
    return this.http.get<any>(url, header);
  }

  public updateAppReferenceDoc(fiscalYear, link): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`,
        'params': `{"fiscalId":${fiscalYear}, "link":"${link}"}`
      })
    }
    const url = `${this.serviceUrl+this.apiVersion}/updateAPpReferenceDoc`
    return this.http.post<any>(url, '', header);
  }

  public getGeneralPrioritySubBulletsByBulletid(bulletid): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/getGeneralPrioritySubBulletsByBulletid?bulletid=${bulletid}`;
    return this.http.get<any>(url, header);
  }

  public addSubBullet(body): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
       
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/addSubBullet`;
    return this.http.post<any>(url, body, header);
  }

  public updateSubBullet(body): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
       
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/updateSubBullet`;
    return this.http.put<any>(url, body, header);
  }

  public getbulletAndSubBullet(ietid): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/getbulletAndSubBullet?ietid=${ietid}`;
    return this.http.get<any>(url, header);
  }

  public getOEUploadStatus(uuid): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/getOEUploadStatus?uuid=${uuid}`;
    return this.http.get<any>(url, header);
  }
  public getFiscalyr(): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': this.contentType,
        'Authorization': `${this.bservice?.userToken}`,
       
      })
    } 
    const url = `${this.serviceUrl+this.apiVersion}/getFiscalyr`;
    return this.http.get<any>(url, header);
  }
}